@import "../settings/setting.mixin";
@import "../settings/setting.color";

.input {
  &.full-width {
    width: 100%;
  }
  color: $txt-color--gray49;
  padding-left: 15px;
  border: 1px solid $border-color--grayd8;
  border-radius: 2px;
  box-sizing: border-box;
  font-size: 13px;
  &:focus {
    box-shadow: none;
    outline: none;
    background-color: $bg-color--white;
    border-color: $border-color--blue8;
  }
  &--fullwidth {
    width: 100%;
  }
  &--normal{
    height: 30px;
  }
  &--chat {
    width: 100%;
    height: 26px;
    border: 1px solid $border-color--grayd8;
    background-color: $bg-color--grayf22;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding-left: 10px;
    padding-right: 24px;
    &:focus {
      outline: none;
      background-color: white;
    }
    &::placeholder {
      font-size: 13px;
      font-weight: 400;
      color: #999999;
    }
    &.private {
      background-color: rgba(255, 250, 241, 1);
      &::placeholder {
        color: #CA5201;
      }
      &:focus {
        background-color: white;
      }
    }
  }
  &--price {
    height: 25px;
    padding-right: 10px;
    border-radius: 4px;
    border: 1px solid $border-color--grayd8;
    margin-left: 15px;
    &::placeholder {
      font-size: 12px;
    }
    &:focus {
      border: 1px solid $border-color--blue8;
      outline: none;
    }
  }
  &__code_bill {
    height: 25px;
    border-radius: 4px;
    border: 1px solid $border-color--grayd8;
    padding-left: 5px;
    &:focus {
      border: 1px solid $border-color--blue8;
      outline: none;
    }
  }
  &__chat_product {
    height: 25px;
    padding-left: 5px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid $border-color--grayd8;
    &:focus {
      border: 1px solid $border-color--blue8;
      outline: none;
    }
  }
  &__shipping_fee,
  &__total_discount {
    width: 55px !important;
    height: 20px;
    padding-left: 5px;
    border-radius: 4px;
    border: 1px solid $border-color--grayd8;
    margin-left: 15px;
    &::placeholder {
      font-size: 12px;
    }
    &:focus {
      border: 1px solid $border-color--blue8;
      outline: none;
    }
  }
  &__total_discount {
    width: 100px !important;
  }
  &--edit_quantity {
    width: 50px;
    border-radius: 4px;
    border: 1px solid $border-color--grayd8;
    margin-left: 15px;
    &::placeholder {
      font-size: 12px;
    }
    &:focus {
      border: 1px solid $border-color--blue8;
      outline: none;
    }
  }
  &--error {
    border: 1px solid red !important;
  }
  &--violate--filter-code {
    height: 30px !important;
    margin-top: 12px;
    border-radius: 4px;
    border: 1px solid #CCCCCC;
    padding-left: 10px;
    width: 100%;
    @include a-respond-down(md) {
      width: 100%;
      margin-top: 5px;
    }
    @include a-respond-down(sm) {
      margin-top: 0;
    }
    &::placeholder {
      font-size: 13px;
    }
    &:focus {
      outline: none;

    }
  }
  &--sort_purchase {
    padding-left: 25px;
    height: 32px;
    padding-right: 25px;
    width: 100%;
    border-radius: 3px;
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #a8a8a8;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: #a8a8a8;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: #a8a8a8;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: #a8a8a8;
    }
  }
  &--search_item {
    height: 30px;
  }
  &--insert_acount {
    height: 32px;
  }
  &--datetime {
    width: 100%;
    position: relative;
    border: 1px solid $border-color--grayd8;
    background: white;
    color: #091E42;
    cursor: pointer;
    font-size: 13px;
    padding: 9px;
    text-align: left;
    height: 30px;
    border-radius: 4px;
    &:focus {
      background: transparent;
      outline: none;
      border: 1px solid $border-color--grayd8;
    }
    &.disable {
      pointer-events: none;
      opacity: .5;
      background-color: #f2f2f2;
      border: 1px solid #c4c4c4;
      &:hover {
        cursor: default;
      }
    }
  }
  &--settings {
    width: 150px;
    height: 30px;
    padding-left: 10px;
    &::placeholder {
      font-size: 12px;
    }
    &.disable {
      pointer-events: none;
      opacity: .5;
      background-color: #f2f2f2;
      border: 1px solid #c4c4c4;
      &:hover {
        cursor: default;
      }
    }
    &.has-error {
      border: 1px solid $border-color--redf;
      &::placeholder {
        color: $txt-color--redf;
      }
      &::-moz-placeholder {
        color: $txt-color--redf;
      }
    }
  }
  &--filter-no-bill {
    height: 32px !important;
    margin-top: 12px;
    border-radius: 4px;
    border: 1px solid #a8a8a8;
    padding-left: 10px;
    width: 100%;
    @include a-respond-down(md) {
      width: 100%;
      margin-top: 5px;
    }
    @include a-respond-down(sm){
      margin-top: 0;
    }
    &::placeholder {
      font-size: 13px;
    }
    &:focus {
      outline: none;
    }
  }
  &--salefigures{
    height: 32px;
    padding-right: 10px;
    width: 258px !important;
    display: block;
    @include a-respond-down(sm) {
      width: 100% !important;
    }
  }
  &--height-30{
    height: 30px !important;
  }
  &--complaint_solution{
    height: 30px;
    padding: 5px 12px;
  }
  &--tagsinput{
    border: 1px solid $border-color--grayd8;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }
  &--default {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.select {
  color: $txt-color--gray49;
  width: 100%;
  border: 1px solid $border-color--grayd8;
  border-radius: 4px;
  height: 30px;
  box-sizing: border-box;
  background-color: white;
  padding-left: 10px;
  font-size: 13px;
  &:focus {
    box-shadow: none;
    outline: none;
    background-color: $bg-color--white;
    border-color: $border-color--blue8;
  }
  &--fullwidth {
    width: 100%;
  }
  &__month{
    background-color: white;
    border-radius: 4px;
  }
}

.react-select__control {
  background-color: white !important;
  border: 1px solid $color--border !important;
  height: 42px !important;
}

input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size:13px;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

input[type=text] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.a-textarea {
  padding-left: 10px;
  &--cfprice {
    width: 100%;
    resize: vertical;
    margin-top: 10px;
    border-radius: 4px;
    border: 1px solid $border-color--grayd8;
    &::placeholder {
      font-size: 12px;
    }
    &:focus {
      border: 1px solid $border-color--blue8;
      outline: none;
    }
  }
  &--complaint_solution{
    width: 100%;
    resize: vertical;
    margin-top: 10px;
    border-radius: 4px;
    border: 1px solid $border-color--grayd8;
    &::placeholder {
      font-size: 12px;
    }
    &:focus {
      border: 1px solid $border-color--blue8;
      outline: none;
    }
  }

}

.checkbox {
  &--service {

  }
  &--group {
    width: 16px;
    @include a-respond-down(xl) {
      width: 24px;
    }
  }
}

.a-not-active {
  pointer-events: none;
}

.a-checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 5px;
  width: 16px;
  height: 16px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: $bg-color--white;
    border: 1px solid $border-color--gray9;
    border-radius: 3px;
    transition: ease all .3s;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
    &.disable {
      background-color: $bg-color--grayf22;
      border: 1px solid $border-color--grayf2f2;
      &:hover{
        border: 1px solid $border-color--grayf2f2;
      }
    }
  }
  &:hover input ~ .checkmark {
    background-color: $bg-color--white;
    color: #00db9c;
    border: 1px solid #00875A;
    transition: ease all .3s;
  }
}

.a-checkbox input:checked ~ .checkmark {
  background-color: #00875A;
  border: 1px solid #00875A;
  transition: ease all .3s;
  &.disable {
    background-color: $bg-color--grayf22;
    border: 1px solid $border-color--grayf2f2;
  }
}

/* Show the checkmark when checked */
.a-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.a-checkbox .checkmark:after {
  left: 4px;
  top: 2px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.a-group--input {
  display: flex !important;
  .input-first {
    border-right: 1px solid #d9d9d9;
    margin-right: 0 !important;
    &:hover {
      border-right: 1px solid $color-input-primary;
    }

    &:focus {
      border-right: 1px solid $color-input-primary;
    }
  }

  .input-disable {
    width: 30px;
    pointer-events: none;
    background-color: #ffffff;
    border-left: none;
    border-right: none;
  }

  .input-second {
    border-left: 1px solid #d9d9d9;
    margin-left: 1px !important;
    &:hover {
      border-left: 1px solid $color-input-primary;
    }

    &:focus {
      border-left: 1px solid $color-input-primary;
    }
  }
}

.ant-input {
    &:focus {
        border: 1px solid $color-input-primary;
    }

    &:hover {
        border: 1px solid $color-input-primary;
    }
}

.ant-input-search {
    &:hover {
        .ant-input {
            &:focus {
                border: 1px solid $color-input-primary;
            }

            &:hover {
                border: 1px solid $color-input-primary;
            }
        }
    }
}

.ant-select-selection, .ant-select-selection--single {

    &:hover {
        border: 1px solid $color-input-primary;
    }

    &:focus {
        border: 1px solid $color-input-primary;
    }
}

.a-input {
  &--number {
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #d9d9d9;
    width: 100%;
    &:focus {
      border: 1px solid $color-input-primary;
    }

    &:hover {
      border: 1px solid $color-input-primary;
    }
  }
}

.ant-checkbox-checked::after {
  border: 1px solid $color-input-primary;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $color-input-primary;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $color-input-primary;
  border-color: $color-input-primary;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $color-input-primary;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(255, 255, 255, .6);
}
//.ant-checkbox-disabled .ant-checkbox-inner {
//  border-color: $color-input-primary !important;
//}
.ant-checkbox-inner::after {
  left: 22%;
}

.ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 58, 140, .2);
  -moz-box-shadow: 0 0 0 2px rgba(0, 58, 140, .2);
  box-shadow: 0 0 0 2px rgba(0, 58, 140, .2);
}

.ant-select-open .ant-select-selection {
  border-color: $color-button-primary;
}