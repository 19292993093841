@import "setting.color";
@import "setting.mixin";

.width{
  &--100_mb{
    width: 100% !important;
  }
}
.wrap {
  width: 100%;
}

.a--error {
  font-size: 12px;
  color: red;
  line-height: 24px;
}

.a--cursor-poiter {
  &:hover {
    cursor: pointer;
  }
}

.hidden {
  display: none !important;
}

.clearfix {
  clear: both !important;
}

.a-radius {
  &-50 {
    border-radius: 50px !important;
  }
}

.row-ph-5 {
  margin-left: -5px !important;
  margin-right: -5px !important;
}

.float {
  &--right {
    float: right !important;
  }
  &--left {
    float: left !important;
  }
}

.a--group--inline-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}

.hidden {
  display: none !important;
  &-md {
    @include a-respond-down(md) {
      display: none !important;
    }
  }
  &-lesslg {
    @include a-respond-only(md, lg) {
      display: none !important;
    }
  }
  &-sm{
    @include a-respond-down(sm){
      display: none !important;
    }
  }
}

.over-flow {
  &--hidden-md {
    @include a-respond-down(md) {
      overflow: hidden;
    }
  }
  &--inherit {
    overflow: inherit !important;
  }
}

.a-radius {
  &-50 {
    border-radius: 50px !important;
  }
  &-3 {
    border-radius: 3px !important;
  }
}

.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.a-disable {
  color: #999999 !important;
  text-decoration: none !important;
  cursor: default;
  pointer-events: none;
  &:hover {
    cursor: default;
  }
}

.disable__link {
  a {
    color: #999999 !important;
    text-decoration: none !important;
    cursor: default;
    pointer-events: none;
  }
  &:hover {
    cursor: default;
  }
}
.white-space{
  &-nowrap{
    white-space: nowrap !important;
  }
}
.rotate {
  &--180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  &---180 {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  &--rotateY-180{
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
}
.mark_important{
  &:hover{
    cursor: pointer;
  }
  .unmark{
    color: #999999;
  }
  i{
    color: orange;
  }
}
div[data-role="droplistContent"]{
  overflow: inherit !important;
}
.search-order-item {
  div[data-role="droplistContent"]{
    overflow-y: auto !important;
    &::-webkit-scrollbar {
      width: 6px;

      &:horizontal {
        height: 6px;
      }
    }

    &::-webkit-scrollbar-thumb {
      background: #e2e5ec;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #e2e5ec;
      cursor: pointer;
    }
  }
}
