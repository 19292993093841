@import "../../../../resources/scss/settings/setting.color";
@import "../../../../resources/scss/settings/setting.mixin";
.a__complaint{
  &__content_list{
    @include a-respond-down(md){
      overflow-x: auto;
    }
  }
  &__info{
    &__block{
      a {
        &.a-link{
          width: calc(75% + 22px) !important;
          margin-left: calc(25% - 22px) !important;
          text-align: left !important;
          @include a-respond-down(xxl){
            width: calc(60% + 15px) !important;
            margin-left: calc(40% - 15px) !important;
          }
          @include a-respond-down(sm) {
            margin-top: 10px;
          }

        }
      }
    }
    &__block_bottom {
      width: 100%;
      margin-top: 20px;
      .block_bottom_first {
        margin-left: calc(12.5% - 10px);
        @include a-respond-down(xxl) {
          margin-left: calc(20%);
        }
        display: flex;
        p {
          &:first-child {
            white-space: nowrap;
            font-size: 12px;
            color: #999999;
            font-weight: 700;
          }
          &:last-child{
            font-size: 12px;
            margin-left: 5px;
            line-height: 16px;
            padding-right: 13%;
            @include a-respond-up(xxl){
              padding-right: 28%;
            }
          }
        }
      }
      .block_bottom_second{
        display: flex;
        justify-content: center;
        margin-top: 10px;
        p {
          &:first-child {
            white-space: nowrap;
            font-size: 14px;
            color: #999999;
            font-weight: 700;
          }
          &:last-child{
            font-size: 14px;
            margin-left: 5px;
            line-height: 16px;
            font-weight: 700;
          }
        }
      }
    }
  }
  &__same_link{
    margin-top: 30px;
    .a-link{
      display: inline-flex;
      align-items: center;
    }
    ul{
      margin-top: 15px;
    }
  }
  &__edit_user_ex{
    .a-content-editable--title{
      font-weight: 700;
      color: $color--green;
    }
  }
}
.a-item__complaint {
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  padding: 10px;
  transition: all .3s;
  @include a-respond-down(md) {
    -webkit-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    border-radius: 5px;
    border:none;
  }
  &:hover {
    background: #f2f6fb;
  }
  &.search__header{
    box-shadow: none;
    border-bottom: 1px solid #dddddd;
    &:hover{
      &::before{
        width: 0;
      }
    }
    .search__title{
      text-transform: uppercase;
      font-size: 11px;
      color: #666666;
      font-weight: 700;
      white-space: nowrap;
      //display:flex;
    }
  }

  .item_info {
    display: flex;
    .more_info__item {
      margin-top: 5px;
      font-size: 12px;
      color: $color--font-primary;
      font-weight: 400;
      .link_item {
        text-decoration: none;
        &:focus {
          text-decoration: underline;
        }
      }
    }
    .more_info__action {
      font-size: 12px;
      color: $color--font-primary;
      font-weight: 400;
      .link_item {
        text-decoration: none;
        &:focus {
          text-decoration: underline;
        }
      }
    }
    .link_item {
      a{
        text-decoration: none;
        font-weight: bold;
        &:focus {
          text-decoration: underline;
        }
      }
      &:hover {
        .copy {
          opacity: 1;
          margin-left: 10px;
          cursor: pointer;
          color: #999999;
        }
      }
    }
    .copy {
      opacity: 0;
      width: 11px;
      font-size: 13px;
      margin-left: 10px;
      @include a-respond-down(md) {
        margin-left: -11px;
      }
    }
    .complaint-origin-code {
      font-size: 10px;
      color: #40a9ff;
      word-break: break-word;
    }
  }
  .item_reason {
    display: flex;
    flex-wrap: wrap;
    span {
      font-size: 13px;
      font-weight: 400;
      padding-right: 10px;
    }
    .reason__text{
      width: 100%;
    }
    .icon--sticky_note{
      color: #999999;
      font-size: 14px;
      margin-top:5px;
      transform: rotate(180deg);
    }
  }
  .item_ubought {
    display: flex;
    flex-wrap: wrap;
    span {
      font-weight: 400;
    }
  }
  .item_cost {
    display: flex;
    flex-wrap: wrap;
    span {
      color: $color--green-0;
      font-weight: 700;
      font-size: 14px;
    }
  }
  .item_time {
    flex-wrap: wrap;
  }
  .item_user {
    font-size: 13px;
  }
  .item_option {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    height: 25px;
    @include a-respond-down(md) {
      width: 30px !important;
      padding-right: 20px;
    }
    &:hover {
      cursor: pointer;
    }
    span {
      display: flex;
      position: relative;
      width: 3px;
      height: 3px;
      background-color: $color-gray-c;
      &:hover {
        cursor: pointer;
      }
      &:before {
        position: absolute;
        content: '';
        width: 3px;
        height: 3px;
        background-color: $color-gray-c;
        top: 0;
        right: 10px;
      }
      &:after {
        position: absolute;
        content: '';
        width: 3px;
        height: 3px;
        background-color: $color-gray-c;
        top: 0;
        right: 5px;
      }
    }
    .item_option__detail {
      position: absolute;
      width: 150px;
      padding: 5px 10px 5px 10px;
      top: 20px;
      border: 1px solid #f2f2f2;
      background-color: white;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-wrap: wrap;
      z-index: 1024;
      @include a-respond-down(md){
        width: 200px;
      }
      li {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 7px 5px 7px 5px;
        a {
          width: 100%;
          text-decoration: none;
          color: $color--font-primary;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }

}

.group__inline {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  &:last-child {
    margin-bottom: 0;
  }
  &__label {
    color: $color--gray-9;
    font-size: 13px;
    width: 30%;
    &.label__time{
      width: 75px;
      white-space: nowrap;
    }
    &.label__date{
      width: 65px;
    }
  }
  &__value {
    color: $color--font-primary;
    font-size: 13px;
  }
}

.a__complaint__detail{
  &__title{
    display: flex;
    justify-content: space-between;
    @include a-respond-down(sm){
      justify-content: flex-start;
      flex-direction: column;
    }
    .title__left{
      align-items: center;
      @include a-respond-down(sm) {
        flex-direction: column;
        align-items: flex-start;
        span{
          margin-top:10px;
          margin-bottom:5px;
          margin-left:0 !important;
        }
      }
    }
  }
  &__block{
    padding: 10px;
    box-sizing: border-box;
    &-md{
      @include a-respond-down(md){
        margin-bottom: 15px;
      }
      @include a-respond-only(md,lg){
        margin-bottom: 15px;
      }
    }
    .change-user-receive{
      color: #0033FF;
      font-size: 13px;
      font-weight: 100;
      cursor: pointer;
      display: block;
    }
    .user-current-receive{
      color: #333333;
    }
    .react-datepicker__input-container{
      input{
        background: white ;
      }
    }
    .amount-required{
      font-size: 15px;
    }
    .special_note__complaint{
      display: flex;
      width: 100%;
      @include a-respond-down(sm){
        flex-wrap: wrap;
      }
      @include a-respond-up(xxl){
        width: 50%;
      }
      .label__special_note__complaint{
        width: 21%;
        @include a-respond-down(lg){
          width: 22%;
        }
        @include a-respond-up(xxl){
          width: 25%;
        }
        @include a-respond-down(sm){
          text-align: left !important;
          width: 100%;
        }
      }
      .value__special_note__complaint{
        width: calc(79% - 4px);
        @include a-respond-down(lg){
          width: 78%;
        }
        @include a-respond-up(xxl){
          width: 75%;
        }
        @include a-respond-down(sm){
          width: 100%;
          padding-left: 0 !important;
          p{
            margin-top:5px;
          }
        }
        p{
          word-break: break-word;
        }
      }
    }
  }
  &__time_block{
    //overflow-y: auto;
    display: flex;
    width: 100%;
    @include a-respond-down(sm) {
      flex-direction: column;
    }
    &::-webkit-scrollbar {
      width: 3px;
      &:horizontal {
        height: 3px;
      }
    }
    &::-webkit-scrollbar-thumb {
      background: #e2e5ec;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #e2e5ec;
      cursor: pointer;
    }
    li{
      display: flex;
      width: 100%;
      height: 32px;
      align-items: center;
      @include a-respond-down(sm) {
        margin-bottom: 10px;
      }
      label{
        display: flex;
        color:#b1afaf;
        margin-right: 10px;
        @include a-respond-down(xl){
          margin-right: 5px;
        }
      }
      p{

      }
    }
  }
  &__solution{
    .complaint_solution__title{
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      p {
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 700;
        color: #333333;
      }
      a{
        margin-left: 20px;
        text-decoration: none;
        font-size: 13px;
        font-weight: 500;
        &:hover{
          color: #0056b3;
        }
      }
    }
  }
}


.a_complaint_status {
  ul {
    display: flex;
    display: -webkit-flex;
    align-content: flex-start;
    li {
      background: #fff;
      border-radius: 50px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      width: 15%;
      margin-right: 10px;
      &.active {
        color: #fff;
        background: $txt-color-primary;
        a {
          color: #ffffff;
        }
      }
      &.danger {
        a {
          color: $txt-color--redf;
        }
      }
      a {
        display: block;
        line-height: 38px;
        text-align: center;
        text-transform: capitalize;
        color: $txt-color--gray5;
        text-decoration: none;
      }
    }
  }
}

.a__complaint__content_list {
  padding: 0;
  .a-filter {
    border-bottom: 1px solid #dddddd;
    padding: .5rem 1rem;
    h3 {
      text-transform: uppercase;
      font-weight: normal;
      line-height: 30px;
      padding-top: 2px;
    }
  }
}


.a-content__complaint-seller {
  .complaint-seller-box {
    position: relative;
    .cs-box-title {
      justify-content: space-between;
    }
    .cs-box-list-col {
      border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
    }
  }
  .group__button {
    .action-btn-cs {
      height: 28px;
      border-radius: 3px;
    }
  }
  .comments-shop-cs {
    position: absolute; bottom: .75rem; right: 1rem;
  }

  .complaint-detail-table-list-package {
    .ant-table-thead {
      font-size: 12px;
    }
  }
}

.a__complaint__order {
  .cs-order-row {
    padding: 6px 0;
    display: flex;
    align-items: center;
  }
}

.option_transaction_cs {
  display: flex !important;
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 80%;
  }
  span {
    width: 20%;
  }
}

.line-comments-shop-cs {
  &+.line-comments-shop-cs {
    border-top: 1px dashed #e4e4e4;
  }
}