@import "../../../../../resources/scss/settings/setting.color";
@import "../../../../../resources/scss/settings/setting.mixin";
.t-table--user{
  tbody{
    tr{
      td{
        @include  a-respond-down(md){
          font-size: 13px;
        }
      }
    }
  }
}
.t-form-search-user{
  @include  a-respond-down(md) {
    width: 100%;
    flex: inherit;
  }
}
.t-form-select-user{
  @include  a-respond-down(md) {
    width: 100%;
    flex: inherit;
    justify-content: space-between;
  }
}
.t-label__form-user{
  @include  a-respond-down(md) {
    font-weight: 500;
  }
}
.access_token__max-width{
  max-width: 800px;
}