@import "../../../../../resources/scss/settings/setting.color";
@import "../../../../../resources/scss/settings/setting.mixin";

.a-table--violate {
  width: 100%;
  display: table;
  @include a-respond-down(md){
    display: block;
  }
  thead {
    th {
      text-align: left;
      font-weight: 700;
      font-size: 11px;
      color: #666666;
      padding-bottom: 5px;
      border-bottom: 1px solid #CCCCCC;
      text-transform: uppercase;
      &:first-child{
        @include a-respond-down(md){
          position: sticky;
          position: sticky;
          position: -webkit-sticky;
          left: 0;
          background-color: white;
          border-bottom: 1px solid #CCCCCC;
        }
      }
      &:last-child {
        text-align: right;
      }
    }
  }
  &--row-info {
    td {
      padding-top: 15px;
      padding-bottom: 10px;
      font-weight: 400;
      @include a-respond-down(md){
        padding-left: 5px;
        padding-right: 30px;
        font-size: 13px;
      }
      &:hover {
        .copy {
          color: #999999;
          opacity: 1;
          margin-left: 10px;
          cursor: pointer;
          margin-top: -12px;
        }
      }
      .copy {
        opacity: 0;
        width: 11px;
        font-size: 13px;
        margin-left: 10px;
        @include a-respond-down(md) {
          margin-left: -11px;
        }
      }
      &:first-child {
        a {
          font-size: 13px;
          font-weight: 700;
          color: #0033FF;
          margin-bottom: 12px;
          text-decoration: none;
          &:visited {
            color: #0033FF;
          }
          &:focus {
            color: #0033FF;
          }
        }
        span {
          color: #333333;
        }
        @include a-respond-down(md){
          left: 0;
          background-color: white;
          position: sticky;
          position: -webkit-sticky;
        }
      }
      &:nth-child(2) {
        .a-table--violate-gr {
          display: flex;
          margin-bottom: 12px;
          span {
            color: #999999;
            width: 45px;
          }
          p {
            color: #333333;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &:nth-child(3) {
        p {
          color: #999999;
          margin-bottom: 12px;
          font-size: 13px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &:nth-child(4) {
        @extend td:nth-child(3);
      }
      &:nth-child(5) {
        @extend td:nth-child(3);
      }
      &:last-child {
        text-align: right;
        @include a-respond-down(md){
          display: block;
          min-width: 100px;
          padding-right: 0;
        }
        span {
          font-size: 12px;
          border-radius: 4px;
        }
      }
    }
  }
  &--row-reason {
    text-align: center;
    border-bottom: 1px solid #CCCCCC;
    td {
      p {
        padding-top: 3px;
        padding-bottom: 3px;
        font-size: 13px;
        font-weight: 400;
        span {
          -webkit-animation-name: show-txt-violet;
          -moz-animation-name: show-txt-violet;
          -o-animation-name: show-txt-violet;
          animation-name: show-txt-violet;
          animation-duration: .5s;
          @include a-respond-down(md){
            -webkit-animation-name: show-txt-violetxl;
            -moz-animation-name: show-txt-violetxl;
            -o-animation-name: show-txt-violetxl;
            animation-name: show-txt-violetxl;
            animation-duration: .5s;
          }
        }
        .can-select {
          position: relative;
          padding-right: 20px;
          cursor: pointer;
          &:before {
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            position: absolute;
            content: "\F303";
            right: 0;
            top: 0;
            font-size: 14px;
            color: #666666;
          }
        }

      }
    }
    &.success {
      td {
        p {
          background-color: rgba(222, 255, 222, 1);
          color: #009966;
        }
      }
    }
    &.danger {
      td {
        p {
          background-color: rgba(255, 236, 235, 1);
          color: #CC0000;
        }
      }
    }
    td {
      padding-bottom: 5px;
    }
  }
}

.a-filter--violate {
  margin-bottom: 30px;
  @include a-respond-down(md){
    flex-wrap: wrap;
  }
  @include a-respond-only(md,lg){
    flex-wrap: wrap;
  }
  &--action {
    margin-top: 28px;
    width: 180px;
    &.order-billno{
      display: flex;
      align-items: flex-end;
      .reset{
        margin-top: 5px;
      }
    }
    @include a-respond-down(xl) {
      margin-top: 24px;
    }
    @include a-respond-down(md){
      margin-top: 15px;
      width: 100%;
    }
    .sort {
      height: 30px;
      min-width: 50px;
      background-color: #e3e3e3;
      border: none;
      color: #333333;
      font-size: 12px;
      font-weight: 700;
      border-radius: 2px;
      margin-top: -2px;
      &:focus {
        outline: none;
      }
      @include a-respond-down(xl) {
        margin-left: 10px;
        margin-top: 1px;
      }
      @include a-respond-down(md){
        margin-left:0;
        padding-left: 100px;
        padding-right: 100px;
      }
      @include a-respond-only(md,lg){
        margin-left: 0;
      }
    }
    .reset {
      color: #0033FF;
      margin-left: 10px;
      font-weight: 400;
      width:65px;
      &:hover {
        cursor: pointer;
      }
      @include a-respond-down(md){
        height: 30px;
        display: flex;
        width: 100px;
        align-items: center;
        justify-content: center;
        border: 1px solid #dddddd;
        color: #333333;
        border-radius: 3px;
        position: relative;
        padding-left: 10px;
         margin-top: 0 !important;
        &:before{
          position: absolute;
          content: '\F3B1';
          font-family: 'Ionicons';
          left: 10px;
          top: 7px;
          font-size: 16px;
        }
      }
    }
  }
  &--form {
    width: 100%;
    margin-right: 15px;
    @include a-respond-down(xl) {
      min-width: 140px;
      margin-right: 10px;
    }
    @include a-respond-down(md) {
      min-width: 130px;
      margin-right: 10px;
      width: calc(50% - 10px);
      margin-bottom: 15px;
    }
    @include a-respond-only(md,lg) {
      width: calc(20% - 10px);
      margin-bottom: 10px;
    }
    @include a-respond-down(sm) {
      margin-bottom: 10px;
    }
    &:first-child{
      @include a-respond-down(md) {
        width: 100%;
      }
      @include a-respond-down(sm) {
        width:calc(50% - 10px);
      }
    }
    label {
      font-size: 12px;
      color: rgb(102, 102, 102);
      @include a-respond-down(md) {
        font-weight: 500;
      }
      @include a-respond-down(sm) {
        margin-bottom: 7px;
      }
    }
  }
}

.a-label--violate {
  border-radius: 4px;
}
