/* ==========================================================================
   # START UTILITIES PADDING
   ==========================================================================
*/
.p {
  //&-0 {
  //  padding-top: 0 !important;
  //}
  //&-1 {
  //  padding-top: (1rem * .25) !important;
  //}
  //&-2 {
  //  padding-top: (2rem * .25) !important;
  //}
  //&-3 {
  //  padding-top: (3rem * .25) !important;
  //}
  //&-4 {
  //  padding-top: (4rem * .25) !important;
  //}
  //&-5 {
  //  padding-top: (5rem * .25) !important;
  //}
  &t {
    &-0 {
      padding-top: 0 !important;
    }
    &-1 {
      padding-top: (1rem * .25) !important;
    }
    &-2 {
      padding-top: (2rem * .25) !important;
    }
    &-3 {
      padding-top: (3rem * .25) !important;
    }
    &-4 {
      padding-top: (4rem * .25) !important;
    }
    &-5 {
      padding-top: (5rem * .25) !important;
    }
  }
  &b {
    &-0 {
      padding-bottom: 0 !important;
    }
    &-1 {
      padding-bottom: (1rem * .25) !important;
    }
    &-2 {
      padding-bottom: (2rem * .25) !important;
    }
    &-3 {
      padding-bottom: (3rem * .25) !important;
    }
    &-4 {
      padding-bottom: (4rem * .25) !important;
    }
    &-5 {
      padding-bottom: (5rem * .25) !important;
    }
  }
  &l {
    &-0 {
      padding-left: 0 !important;
    }
    &-1 {
      padding-left: (1rem * .25) !important;
    }
    &-2 {
      padding-left: (2rem * .25) !important;
    }
    &-3 {
      padding-left: (3rem * .25) !important;
    }
    &-4 {
      padding-left: (4rem * .25) !important;
    }
    &-5 {
      padding-left: (5rem * .25) !important;
    }
  }
  &r {
    &-0 {
      margin-right: 0 !important;
    }
    &-1 {
      margin-right: (1rem * .25) !important;
    }
    &-2 {
      margin-right: (2rem * .25) !important;
    }
    &-3 {
      margin-right: (3rem * .25) !important;
    }
    &-4 {
      margin-right: (4rem * .25) !important;
    }
    &-5 {
      margin-right: (5rem * .25) !important;
    }
  }
}
.aphz-16{
    padding-left: 16px !important;
    padding-right: 16px !important;
}
.aphz-24{
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.aphz-50{
  padding-left: 50px;
  padding-right: 50px;
}
.apl-24{
  padding-left: 24px !important;
}
.apr-24{
  padding-right: 24px !important;
}
.ap-24{
  padding: 24px !important;
}
/* ==========================================================================
   # END UTILITIES WIDTH
   ==========================================================================
*/