@import "./../settings/setting.mixin";
@import "./../settings/setting.color";

.react-select {
  &--filter_distributor__control {
    height: 32px;
    min-height: inherit !important;
  }
  &--normal {
    &__control {
      min-height: 30px !important;
      height: 30px;
      background-color: $bg-color--white !important;
      border: 1px solid $border-color--grayd8 !important;
      &--is-focused {
        border: 1px solid $border-color--blue8 !important;
      }
    }
    &__value-container--is-multi {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      div {
        margin-right: 2px;
        &:last-child {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
      }
    }
    &__option {
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    &__indicators {
      height: 30px !important;
    }
    &__dropdown-indicator {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
    }
    &__menu {
      text-align: left !important;
      &-list {
        &::-webkit-scrollbar {
          width: 5px;
          &:horizontal {
            height: 5px;
          }
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $bg-color--grayc;
          border-radius: 10px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $bg-color--grayc;
          cursor: pointer;
        }
      }
    }
    &__input {
      width: 100%;
      @supports (-moz-appearance:none) {
        display: inline-block;
        height: 30px;
        margin-top: -5px;
      }
      input {
        width: 100% !important;
        overflow: hidden;
      }
    }
    &__value-container {
      @supports (-moz-appearance:none) {
        height: 30px !important;
        margin-top: -4px;
      }
    }
  }
  &--multi {
    &__control {
      min-height: 30px !important;
      height: auto;
      background-color: $bg-color--white !important;
      border: 1px solid $border-color--grayd8 !important;
      &--is-focused {
        border: 1px solid $border-color--blue8 !important;
      }
    }
    &__value-container--is-multi {
      div {
        margin-right: 2px;
        &:last-child {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
      }
    }
    &__option {
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    &__indicators {
      height: 30px !important;
    }
    &__dropdown-indicator {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
    }
    &__menu {
      text-align: left !important;
      &-list {
        &::-webkit-scrollbar {
          width: 5px;
          &:horizontal {
            height: 5px;
          }
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $bg-color--grayc;
          border-radius: 10px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $bg-color--grayc;
          cursor: pointer;
        }
      }
    }
    &__input {
      width: 100%;
      input {
        width: 100% !important;
        overflow: hidden;
      }
    }
  }
  &--filter_item_multi {
    &__control {
      min-height: 28px !important;
      height: auto;
      background-color: $bg-color--white !important;
      border: 1px solid $border-color--grayd8 !important;
      &--is-focused {
        border: 1px solid $border-color--blue8 !important;
      }
    }
    &__value-container--is-multi {
      max-width: 234px;
      div {
        margin-right: 2px;
        &:last-child {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
      }
    }
    &__option {
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    &__indicators {
      height: 28px !important;
    }
    &__dropdown-indicator {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
    }
    &__menu {
      text-align: left !important;
      &-list {
        &::-webkit-scrollbar {
          width: 5px;
          &:horizontal {
            height: 5px;
          }
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $bg-color--grayc;
          border-radius: 10px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $bg-color--grayc;
          cursor: pointer;
        }
      }
    }
    &__input {
      width: 100%;
      input {
        width: 100% !important;
        overflow: hidden;
      }
    }
    &__placeholder {
      font-size: 13px;
      color: $txt-color--gray6 !important;
      @include a-respond-down(xl) {
        font-size: 13px;
      }

    }
  }
  &--purchasebuying {
    &__control {
      min-height: 25px !important;
      height: 25px !important;
      background-color: $bg-color--white !important;
      border: 1px solid $border-color--grayd8 !important;
      &--is-focused {
        border: 1px solid $border-color--blue8 !important;
      }
    }
    &__indicator {
      height: 25px;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    &__value-container{
      height: 25px;
      padding-top: 0;
      padding-bottom: 0;
      div{
        margin-right:0 !important;
        padding-top:0 !important;
      }
    }
    &__option {
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      white-space: normal;
    }
    &__placeholder {
      font-size: 12px;
      padding-left: 5px;
    }
    &__menu {
      text-align: left !important;
      &-list {
        max-height: 150px !important;
        &::-webkit-scrollbar {
          width: 5px;
          &:horizontal {
            height: 5px;
          }
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $bg-color--grayc;
          border-radius: 10px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $bg-color--grayc;
          cursor: pointer;
        }
      }
    }
    &__input {
      width: 100%;
      input {
        width: 100% !important;
        overflow: hidden;
      }
    }
  }
  &--filter_item {
    &__control {
      min-height: 35px !important;
      background-color: $bg-color--white !important;
      border: 1px solid $border-color--grayd8 !important;

    }

    &__option {
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    &__placeholder {
      font-size: 13px;
      padding-left: 8px;
      @include a-respond-down(xl) {
        font-size: 13px;
      }

    }
    &__indicator {
      padding-top: 4px !important;
      padding-bottom: 4px !important;;
    }
    &__menu {
      text-align: left !important;
    }
    &__input {
      width: 100%;
      input {
        width: 100% !important;
        overflow: hidden;
      }
    }
    &__value-container {
      padding: 0 !important;
      height: 25px;
      margin-top: 4px;
      @supports (-moz-appearance:none) {
        margin-top: 0;
      }
    }
    &__single-value {
      padding-left: 10px;
      @supports (-moz-appearance:none) {
        top: 45% !important;
      }
    }
    &__indicators {
      @supports (-moz-appearance:none) {
        height: 25px;
      }
    }
    &__input {
      padding-left: 5px;
      @supports (-moz-appearance:none) {
        margin-top: -4px;
      }
    }
    &__placeholder {
      font-size: 13px;
      color: #666666 !important;
      @include a-respond-down(xl) {
        font-size: 13px;
      }

    }
  }
  &--teller {
    &__control {
      min-height: 25px !important;
      height: 25px !important;
      background-color: $bg-color--white !important;
      border-color: $border-color--grayd8 !important;;
      border-width: 1px !important;
      @include a-respond-down(md) {
        margin-left: 20px;
      }
      &--is-focused {
        border: 1px solid $border-color--blue8 !important;
      }
    }
    &__indicators {
      height: 25px;
      padding-top: 0;
      padding-bottom:0;
      span {
        min-width: auto !important;
      }
    }
    &__menu {
      &-list {
        &::-webkit-scrollbar {
          width: 5px;
          &:horizontal {
            height: 5px;
          }
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $bg-color--grayc;
          border-radius: 10px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $bg-color--grayc;
          cursor: pointer;
        }
      }
    }
    &__input {
      width: 100%;
      input {
        width: 100% !important;
        overflow: hidden;
      }
    }
    &__value-container {
      @supports (-moz-appearance:none) {
        height: 25px !important;
        margin-top: -10px;
      }
    }
  }
  &--violate-policy {
    &__control {
      min-height: 30px !important;
      height: 30px !important;
      background-color: $bg-color--white !important;
      border: 1px solid $border-color--grayd8 !important;
      margin-top: 10px;
      &--is-focused {
        border: 1px solid $border-color--blue8 !important;
      }
      @include a-respond-down(md) {
        margin-top: 5px;
      }
    }
    &__option {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 13px !important;
    }
    &__placeholder {
      font-size: 13px;
      @include a-respond-down(xl) {
        font-size: 12px;
      }

    }
    &__menu {
      text-align: left !important;
      @include a-respond-only(lg, xxl) {
        width: auto !important;
        max-width: 280px !important;
        min-width: 100%;
      }
      &-list {
        @include a-respond-only(lg, xxl) {
          width: 100% !important;
        }
        &::-webkit-scrollbar {
          width: 5px;
          &:horizontal {
            height: 5px;
          }
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $bg-color--grayc;
          border-radius: 10px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $bg-color--grayc;
          cursor: pointer;
        }
      }
    }
    &__input {
      width: 100%;
      @supports (-moz-appearance:none) {
        margin-top: -4px;
      }
      input {
        width: 100% !important;
        overflow: hidden;
      }
    }
    &__value-container {
      @supports (-moz-appearance:none) {
        margin-top: 0;
      }
    }
    &__single-value {
      @supports (-moz-appearance:none) {
        top: 45% !important;
      }
    }
    &__indicators {
      height: 30px;
      padding-top: 0;
      padding-bottom: 0;
      @supports (-moz-appearance:none) {
        height: 25px;
      }
    }
  }
  &--violate-policy-crate {
    &__control {
      min-height: 30px !important;
      height: 30px !important;
      background-color: $bg-color--white !important;
      border: 1px solid $border-color--grayd8 !important;
      margin-top: 10px;
      &--is-focused {
        border: 1px solid $border-color--blue8 !important;
      }
      @include a-respond-down(md) {
        margin-top: 5px;
      }
    }
    &__option {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 13px !important;
    }
    &__placeholder {
      top: 42% !important;
      font-size: 13px;
      @include a-respond-down(xl) {
        font-size: 12px;
      }

    }
    &__menu {
      text-align: left !important;
      @include a-respond-only(lg, xxl) {
        width: auto !important;
        max-width: 280px !important;
        min-width: 100%;
      }
      &-list {
        @include a-respond-only(lg, xxl) {
          width: 100% !important;
        }
        &::-webkit-scrollbar {
          width: 5px;
          &:horizontal {
            height: 5px;
          }
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $bg-color--grayc;
          border-radius: 10px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $bg-color--grayc;
          cursor: pointer;
        }
      }
    }
    &__input {
      width: 100%;
      @supports (-moz-appearance:none) {
        margin-top: -4px;
      }
      input {
        width: 100% !important;
        overflow: hidden;
      }
    }
    &__value-container {
      @supports (-moz-appearance:none) {
        margin-top: 0;
      }
    }
    &__single-value {
      @supports (-moz-appearance:none) {
        top: 45% !important;
      }
    }
    &__indicators {
      height: 30px;
      padding-top: 0;
      padding-bottom: 0;
      @supports (-moz-appearance:none) {
        height: 25px;
      }
    }
  }
  &--violate {
    &__control {
      min-height: 25px !important;
      height: 25px !important;
      background-color: #f2f2f2 !important;
      border-color: $border-color--grayd8;
      border-width: 1px !important;
      &--is-focused {
        border: 1px solid $border-color--blue8 !important;
      }
    }
    &__indicators {
      height: 25px;
      span {
        min-width: auto !important;
      }
    }
    &__menu {
      text-align: left;
      &-list {
        &::-webkit-scrollbar {
          width: 5px;
          &:horizontal {
            height: 5px;
          }
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $bg-color--grayc;
          border-radius: 10px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $bg-color--grayc;
          cursor: pointer;
        }
      }
    }
    &--cus_op {
      padding-left: 0px;
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &--cus_gr {
      font-weight: 700;
      color: $txt-color--gray3;
    }
    &__input {
      width: 100%;
      padding-left: 0;
      height: 25px;
      margin-top: -5px;
      input {
        width: 100% !important;
        overflow: hidden;
      }
      @supports (-moz-appearance:none) {
        margin-top: -4px;
      }
    }
    &__value-container {
      height: 25px;
      @supports (-moz-appearance:none) {
        margin-top: 0;
      }
      div{
        margin: 0 !important;
        padding-top: 0 !important;
      }
    }
    &__single-value {
      @supports (-moz-appearance:none) {
        top: 45% !important;
      }
    }
    &__indicators {
      @supports (-moz-appearance:none) {
        height: 25px;
      }
    }

  }
  &--violate_modal {
    &__control {
      min-height: 30px !important;
      height: 30px !important;
      background-color: $bg-color--white !important;
      border: 1px solid $border-color--grayd8 !important;
      &--is-focused {
        border: 1px solid $border-color--blue8 !important;
      }
    }

    &__option {
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      padding-left: 20px !important;
    }
    &__placeholder {
      font-size: 12px;
      @include a-respond-down(xl) {
        font-size: 9px;
      }

    }
    &__group-heading {
      font-weight: 700 !important;
      color: #333333 !important;
      font-size: 100% !important;
    }
    &__menu {
      text-align: left !important;
      &-list {
        &::-webkit-scrollbar {
          width: 5px;
          &:horizontal {
            height: 5px;
          }
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $bg-color--grayc;
          border-radius: 10px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $bg-color--grayc;
          cursor: pointer;
        }
      }
    }
  }
  &--filter-nobill {
    &__control {
      min-height: 32px !important;
      height: 32px !important;
      background-color: $bg-color--white !important;
      border: 1px solid $border-color--grayd8 !important;
      margin-top: 10px;
      &--is-focused {
        border: 1px solid $border-color--blue8 !important;
      }
      @include a-respond-down(md) {
        margin-top: 5px;
      }
    }
    &__option {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 13px !important;
    }
    &__placeholder {
      font-size: 13px;
      @include a-respond-down(xl) {
        font-size: 12px;
      }

    }
    &__menu {
      text-align: left !important;
      @include a-respond-only(lg, xxl) {
        width: auto !important;
        max-width: 280px !important;
        min-width: 100%;
      }
      &-list {
        @include a-respond-only(lg, xxl) {
          width: 100% !important;
        }
        &::-webkit-scrollbar {
          width: 5px;
          &:horizontal {
            height: 5px;
          }
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $bg-color--grayc;
          border-radius: 10px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $bg-color--grayc;
          cursor: pointer;
        }
      }
    }
    &__input {
      width: 100%;
      @supports (-moz-appearance:none) {
        margin-top: -4px;
      }
      input {
        width: 100% !important;
        overflow: hidden;
      }
    }
    &__value-container {
      @supports (-moz-appearance:none) {
        margin-top: 0;
      }
    }
    &__single-value {
      @supports (-moz-appearance:none) {
        top: 45% !important;
      }
    }
    &__indicators {
      @supports (-moz-appearance:none) {
        height: 25px;
      }
    }
  }
  &--settings {
    &__control {
      min-height: 30px !important;
      height: 30px !important;
      background-color: $bg-color--white !important;
      border: 1px solid $border-color--grayd8 !important;
      margin-top: 10px;
      max-width: 400px;
      &--is-focused {
        border: 1px solid $border-color--blue8 !important;
      }
      @include a-respond-down(md) {
        margin-top: 5px;
      }
    }
    &__option {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 13px !important;
    }
    &__placeholder {
      font-size: 13px;
      @include a-respond-down(xl) {
        font-size: 12px;
      }

    }
    &__menu {
      text-align: left !important;
      max-width: 400px;
      &-list {
        &::-webkit-scrollbar {
          width: 5px;
          &:horizontal {
            height: 5px;
          }
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $bg-color--grayc;
          border-radius: 10px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $bg-color--grayc;
          cursor: pointer;
        }
      }
    }
    &__input {
      width: 100%;
      @supports (-moz-appearance:none) {
        margin-top: -4px;
      }
      input {
        width: 100% !important;
        overflow: hidden;
      }
    }
    &__value-container {
      @supports (-moz-appearance:none) {
        margin-top: 0;
      }
    }
    &__single-value {
      @supports (-moz-appearance:none) {
        top: 45% !important;
      }
    }
    &__indicators {
      @supports (-moz-appearance:none) {
        height: 25px;
      }
    }
  }
  &--sale_figures {
    &__control {
      min-height: 30px !important;
      height: auto;
      background-color: $bg-color--white !important;
      border: 1px solid $border-color--grayd8 !important;
      width: 258px;
      @include a-respond-down(sm) {
        width: 100% ;
      }
      &--is-focused {
        border: 1px solid $border-color--blue8 !important;
      }
    }
    &__value-container--is-multi {
      max-width: 234px;
      div {
        margin-right: 2px;
        &:last-child {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
      }
    }
    &__option {
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    &__indicators {
      height: 30px !important;
    }
    &__dropdown-indicator {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
    }
    &__menu {
      text-align: left !important;
      &-list {
        &::-webkit-scrollbar {
          width: 5px;
          &:horizontal {
            height: 5px;
          }
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $bg-color--grayc;
          border-radius: 10px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $bg-color--grayc;
          cursor: pointer;
        }
      }
    }
    &__input {
      width: 100%;
      input {
        width: 100% !important;
        overflow: hidden;
      }
    }
    &__placeholder {
      font-size: 13px;
      color: #666666 !important;
      @include a-respond-down(xl) {
        font-size: 13px;
      }

    }
  }
}

.single-react {
  &--violate {
    max-width: 350px;
    margin: 0 auto;
    -webkit-animation-name: show-violet;
    -moz-animation-name: show-violet;
    -o-animation-name: show-violet;
    animation-name: show-violet;
    animation-duration: .5s;
    @include a-respond-down(xl) {
      -webkit-animation-name: show-violetxl;
      -moz-animation-name: show-violetxl;
      -o-animation-name: show-violetxl;
      animation-name: show-violetxl;
      animation-duration: .5s;
    }
  }
}
.react-tagsinput-tag1{
  &.react-tagsinput--focused{
    border-color: $border-color--blue8;
  }
  input{
    margin-bottom: 0;
    width: 100%;
  }
  .react-tagsinput-tag{
    margin: 3.5px 0 3.5px 5px;
    padding: 2px 5px;
    background: $bg-color--grayc;
    border:1px solid $border-color--grayd8;
    color: $txt-color--gray3;
  }
  .react-tagsinput-remove{
    color: $txt-color--gray3;
  }
}