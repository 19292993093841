@import "../../../../resources/scss/settings/setting.color";
@import "../../../../resources/scss/settings/setting.mixin";
.a_orbill{
  display: flex;
  @include a-respond-down(sm) {
    flex-direction: column;
  }
  .notify__orbill{
    background-color: rgba(255, 255, 242, 1);
    border: 1px solid rgba(255, 204, 102, 1);
    border-radius: 3px;
    width: 100%;
    display: flex;
    height: 30px;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    @include a-respond-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }
    p{
      font-size: 11px;
      color: #CC0000;
      @include a-respond-down(sm) {
        padding: 5px;
      }
      >span{
        font-weight: bold;
      }
    }
  }
}
.a-label--order-billno{
  display: flex;
  margin-bottom: 12px;
}