@import "./../settings/setting.mixin";
@import "./../settings/setting.color";

.a-alert {
  &-warning {
    background-color: #fefee5;
    &__icon {
      width: 30px;
      height: 100%;
      color: #ff5500;
      font-size: 20px;
      margin-right: 20px;
      &.item {
        margin-top: -5px;
      }
    }
  }
  &-success {
    background-color: #dff0d8;
    &__icon {
      width: 30px;
      height: 100%;
      color: #3c763d;
      font-size: 20px;
      margin-right: 20px;
    }
  }
  &-info {
    background-color: #d9edf7;
    &__icon {
      @extend .a-alert-warning__icon;
      color: #31708f;
    }
  }
  &-danger {
    background-clor: #f2dede;
    &__icon {
      @extend .a-alert-warning__icon;
      color: #a94442;

    }
  }
  &--log {
    &-purchase-buying {
      display: inline-flex;
      padding: 15px 15px 10px 15px;
      align-items: center;
      &.purchasebuying {
        align-items: flex-start;
      }
      &__submit {
        font-size: 14px;
        font-weight: bold;
        color: #0033FF;
        text-decoration: none;
        &.item {
          margin-right: 20px;
        }
        span {
          font-size: 14px;
        }
      }
      &--time-ago {
        color: #999999;
        font-size: 12px;
        font-weight: 500;
      }
      &__body {
        span {
          font-size: 12px;
          line-height: 20px;
        }
        &--collapse {
          display: flex;
          align-items: center;
          span {
            font-size: 11px;
            color: #666666;
            font-weight: 400;
          }
          .a-waring {
            color: #ff5500;
            font-size: 12px;
            margin-right: 10px;
            margin-left: 11px;
            &.item {
              margin-top: -5px;
            }
          }
          .a-collaped {
            position: absolute;
            right: 0;
            font-size: 16px;
            margin-right: 5px;
            background-image: linear-gradient(0deg, #555555 0%, #f1f1f1 50%, #555555 50%, #f1f1f1 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-size: 300% 10px, 10px 300%;
            animation: color-down-top .8s infinite linear;
          }
        }
        ul {
          margin-bottom: 5px;
          li {
            line-height: 20px;
            .read-more {
              font-size: 12px;
              color: #999999;
              margin-left: 10px;
              transition: color linear .3s;
              &.detail {
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
              }
              &:hover {
                color: #0033FF;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }
      &--action {
        display: flex;
        justify-content: flex-end;
        .read-more {
          color: #999999;
          font-size: 12px;
          font-weight: 400;
          margin-right: 70px;
        }
        .a-collapsing {
          color: #999999 !important;
          font-size: 16px;
          margin-right: 5px;
          background-image: linear-gradient(0deg, #555555 0%, #f1f1f1 50%, #555555 50%, #f1f1f1 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-size: 300% 10px, 10px 300%;
          animation: color-top-down .8s infinite linear;
        }
      }
    }
  }
  &--purchase--list {
    margin-top: -10px;
    transition: ease all 1s;
    &__body {
      padding: 3px 10px;
      width: 500px;
      transition: height .5s linear;
      @include a-respond-down(md) {
        width: calc(100% - 20px);
      }
      &.collapsed {
        padding: 12px 10px 5px 15px;
      }
    }
    &__main {
      width: 100%;
      &--collapse {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }
  }

  &__dotted {
    position: relative;
    padding-left: 14px;
      font-size: 12px;
      color: rgba(0,0,0,.65);
      line-height: 16px;
      height: 16px;
    white-space: nowrap;
    &:after {
      position: absolute;
      content: '';
      top: 40%;
      left: 0;
      width: 6px;
      height: 6px;
        background-color: white;
        -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
    }
    &--warning {
      &::after {
        background-color: #FAAD14;
      }
    }
    &--success {
      &::after {
        background-color: #00AB1E;
      }
    }
    &--failed {
      &::after {
        background-color: #FF0000;
      }
    }
  }
}

.a-notification-dot {
  position: relative;
  &:before{
    position: absolute;
    top: calc(50% - 4px);
    left: 0;
    content: '';
    width: 8px;
    height: 8px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: transparent;
  }
  &.animation {
    &:before {
      -webkit-animation: opacity-shadow .8s linear infinite;
      -o-animation: opacity-shadow .8s linear infinite;
      animation: opacity-shadow .8s linear infinite;
    }
  }
  &.active{
    &:before{
      background-color: #52C41A;
    }
  }
  &.unactive{
    &:before{
      background-color: $color--gray-9;
    }
  }
  &.red {
    &:before{
      background-color: #FF0000;
    }
  }
  &.blue {
    &:before{
      background-color: $txt-color-primary;
    }
  }
  &.orange {
    &:before{
      background-color: #ffc107;
    }
  }
  &.green {
    &:before{
      background-color: #52C41A;
    }
  }
}
