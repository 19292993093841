.slow-purchasing-reason {
	.reason-line {
		border-top: 1px solid #eeeeee;
		&[draggable="true"] {
			background: #eeeeee;
		}
	}
	.reason-line-move {
		cursor: move;
		padding: 3px 0;
		input {
			max-width: 98%;
		}
		a {
			color: #666666;
			display: inline-block;
			line-height: 32px;
			i {
				opacity: 0;
				transition: all .3s;
				-webkit-transition: all .3s;
				color: #3a72fe;
			}
			&:hover i {
				opacity: 1;
			}
		}
	}
}