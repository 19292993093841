@keyframes show-violet {
  0% {
    opacity: 0;
    width: 0%;
  }
  100% {
    opacity: 1;
    width: 25%;
  }
}

@keyframes notify {
  0% {
    opacity: 0;
  }
  50% {
    opacity: .7;
  }
  100% {
    opacity: 1;
  }
}

@keyframes show-violetxl {
  0% {
    opacity: 0;
    width: 0%;
  }
  100% {
    opacity: 1;
    width: 50%;
  }
}

@keyframes show-txt-violet {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes Gradient {
  0% {
    background-position: 460% 0, 100% 320%, 310% 100%, 0% 310%; /* average reset of bg-position , tune it to desired effect */
  }
}

@keyframes fullwidth {

  0% {
    width: 0;
  }
  100% {
    width: 100%;

  }
}

@keyframes fullHeight {

  0% {
    height: 0;
  }
  100% {
    height: 100%;

  }
}


@keyframes show {
  0% {
    opacity: 0;
    width: 0%;
  }
  100% {
    opacity: 1;
    width: 20%;
  }
}

@keyframes color-top-down {
  0% {
    background-position: 0; /* average reset of bg-position , tune it to desired effect */
  }

}

@keyframes color-down-top {
  100% {
    background-position: 0; /* average reset of bg-position , tune it to desired effect */
  }
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

@mixin dislay-flex($justify,$flex-direction) {
  display: flex;
  justify-content: $justify;
  flex-direction: $flex-direction;
}

$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1280px,
  xl:1400px,
  xxl:1600px
);
@mixin a-respond-up($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin a-respond-down($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin a-respond-only($lower, $upper) {

  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  } @else {

    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {

      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {

      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

@mixin dislay-flex($justify,$flex-direction) {
  display: flex;
  justify-content: $justify;
  flex-direction: $flex-direction;
}
@mixin scrollcustom {
  &::-webkit-scrollbar {
    width: 6px;

    &:horizontal {
      height: 6px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: #6c757d;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #6c757d;
    cursor: pointer;
  }
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

/*Keyframe show input inline*/
@include keyframes(show-input-inline){
  from{
    margin-top:-1rem;
    opacity: 0;
  }
  to{
    margin-top:0;
    opacity: 1;
  }
}

/*Keyframe opacity */
@include keyframes(opacity-shadow){
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

/*Keyframe show error*/
@include keyframes(show-error-text){
  from{
    margin-top:-1rem;
  }
  to{
    margin-top:.3rem;
  }
}

/*Key frame xoay vòng tròn*/
@include keyframes(circle) {
  100% { transform: rotate(-360deg); }
}
/*End*/