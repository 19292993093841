@import "../../../../../resources/scss/settings/setting.color";
@import "../../../../../resources/scss/settings/setting.mixin";

.a-setting {
  &__title {
    padding: 5px 5px 3px 0;
    text-transform: uppercase;
    p {
      font-size: 14px;
      color: #333333;
      font-weight: 500;
    }
    &:nth-child(n+2) {
      margin-top: 20px;
      @include a-respond-down(sm) {
        margin-top: 30px;
      }
    }
    .ant-switch-checked {
      background: #0050B3;
    }
  }
  &__body {
    border-top: 1px solid rgba(196, 196, 196, 0.78);
    @include a-respond-down(sm) {
      padding-top: 10px;
      margin-bottom: 15px;
    }
    .ant-switch-checked {
      background: #0050B3;
    }
    .setting-line+.setting-line {
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid #eee;
    }
    .label__title {
      display: flex;
      justify-content: flex-end;
      font-size: 13px;
      color: #666666;
      height: 100%;
      @include a-respond-down(sm) {
        justify-content: flex-start;
        margin-bottom: 4px;
      }
    }

    .disable-title {
      opacity: .5;
      color: #999;
      pointer-events: none;
    }

    .show_img {
      position: relative;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      border: 1px solid #f2f2f2;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        max-height: 100%;
        object-fit: cover;
      }
      &--remove-img {
        cursor: pointer;
        color: #cca2b4;
        position: absolute;
        z-index: 999;
        top: 0;
        right: 0;
      }
    }
    .row_setting {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      .title__sub {
        padding: 5px 0 3px 0;
        margin-left: 15px;
        margin-bottom: 15px;
        border-bottom: 1px dashed #c4c4c4;
        color: #666666;
      }
    }
    .font-checkbox--setting {
      margin-top: 2px;
    }
    .setting__more{
      margin-top: -10px;
      @include a-respond-down(md){
        margin-top: 20px;
      }
    }
  }
  &__block {
    margin-bottom: 10px;
    .setting__action {
      margin-top: 40px;
      padding-top:15px;
      border-top: 1px solid #f2f2f2;
    }
    .width-input-setting {
      max-width: 150px;
    }
    .text-error {
      color: red;
      font-size: 10px;
    }

  }
  &--tab {
    background-color: white;
    border-bottom: 1px solid #c4c4c4;
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    -webkit-box-shadow: 0px 1px 10px 1px rgba(69, 65, 78, 0.08);
    box-shadow: 0px 1px 10px 1px rgba(69, 65, 78, 0.08);
    padding-left: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    &__item {
      padding: 10px 20px;
      &.active {
        position: relative;

        &::before {
          position: absolute;
          content: '';
          background-color: #0747a6;
          width: 100%;
          height: 2px;
          top: calc(100% - 1px);
          left: 0;
        }
      }
      a {
        text-decoration: none;
        color: #333333;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
      }
    }
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -20px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 20px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}