@import "../settings/setting.mixin";
@import "../settings/setting.color";

.order-group label input[type='checkbox'] {
  width: 0;
}

.order-group .column-left {
  min-height: 500px;
  font-size: 14px;
  white-space: nowrap;
}

.order-group .column-right {
  padding-left: 0px !important;
  @include a-respond-down(md) {
    padding-left: 15px !important;
    margin-top: 10px;
  }
}

.order-group label {
  font-weight: 700;
  color: #666666;
  font-size: 12px;
}

.order-group .column-left .active svg {
  width: 14px;
  height: 14px;
  background: #33cc99;
  color: #33cc99;
  margin-top: -1px;
  margin-left: 1px;
}

.order-group .column-left .inactive svg {
  width: 14px;
  height: 14px;
  background: #ff3300;
  color: #ff3300;
  margin-top: -1px;
  margin-left: 1px;
}

.order-group .column-left .active button,
.order-group .column-left .inactive button {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  padding-left: 0;
  margin-left: 10px;
  margin-top: -17px;
}

.order-group .description-order-group {
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.43333;
  color: #333333;
  flex: 1 1 auto;
  overflow: hidden;
  margin-top: 2px;
  font-size: 13px;
}

.order-group .description-address-purchase {
  line-height: 2.53333;
}

.order-group .column-left .active,
.order-group .column-left .inactive {
  background-color: hsla(206, 12%, 89%, 0.43);
  border: 1px solid hsla(206, 12%, 89%, 0.43);
  border-radius: 4px;
  width: 100%;
  transition: ease all .4s;
}

.order-group .column-left .active:hover {
  background: white;
  border: #33cc99 1px solid;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(153, 153, 153, 0.2);
  -moz-box-shadow: 0px 3px 3px 0px rgba(153, 153, 153, 0.2);
  box-shadow: 0px 3px 3px 0px rgba(153, 153, 153, 0.2);
}

.order-group .column-left .inactive:hover {
  background: white;
  border: #ff3300 1px solid;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(153, 153, 153, 0.2);
  -moz-box-shadow: 0px 3px 3px 0px rgba(153, 153, 153, 0.2);
  box-shadow: 0px 3px 3px 0px rgba(153, 153, 153, 0.2);
}

.order-group .column-left .active:hover .description-order-group {
  cursor: pointer;
  color: #33cc99;
  transition: ease all .3s;
}

.order-group .column-left .inactive:hover .description-order-group {
  cursor: pointer;
  color: #ff3300;
  transition: ease all .3s;
}

.order-group .column-left .active div,
.order-group .column-left .inactive div {
  width: auto !important;
}

.order-group .selected-item {
  border-color: #80bdff !important;
}

.order-group input::placeholder, .order-group textarea::placeholder {
  color: #d5d0cf;
  font-size: 14px;
}

.order-group textarea {
  border: 1px solid $color--border;
  margin-top: .5rem;
  color: #495057;
  font: 400 13.3333px Arial;
  padding: 10px;
  font-size: 14px;
  &:focus {
    box-shadow: none;
    outline: none;
    background-color: #fff;
    border-color: #80bdff;
  }
}

.order-group .order-group-status {
  margin-top: 5px;
  margin-left: -5px;
}

.order-group .order-group-status label div {
  &:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid #80bdff !important;
  }
}

.order-group .order-group-link-create {
  float: left;
  margin-top: 0px;
  &:focus {
    outline: none;
  }
  &:hover {
    outline: none;
    text-underline: none;
  }
  @include a-respond-down(sm) {
    padding-left: 0;
  }
}

.order-group .order-group-link-create svg {
  margin-top: -2px;
}

.order-group .validatorMessage {
  font-size: 0.857143em;
  font-style: inherit;
  line-height: 1.43333;
  font-weight: normal;
  color: rgb(222, 53, 11);
  margin-top: 4px;
}

.order-group .validatorMessage svg {
  width: 16px;
  height: 16px;
}

.order-group .inactive-error {
  display: none;
}

.order-group .active-error {
  display: block;
}

.order-group .create_order_group {
  float: right;
  margin-top: 3px;
  font-size: 12px;
  text-transform: uppercase;
  color: #0033FF !important;
}

.order-group .order-group-link-create svg:focus {
  color: #0033FF !important;
}

.order-group .column-left .item-order-group {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  flex-direction: row;
  @include a-respond-down(sm) {
    height: 50px;
  }
  &:hover {
    cursor: pointer;
  }

}

.order-group .column-left .list-code {
  font-size: 11px;
  color: #999999;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 3px;
}

.a-order--group {
  padding-right: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.43333;
  color: #6b778c;
  flex: 1 1 auto;
  overflow: hidden;
  cursor: pointer;
}

.pr-10 {
  padding-right: 10px;
}

.purchase-address {
  white-space: normal !important;
  &-block {
    button {
      margin-top: -18px;
    }
  }
  &-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal !important;
    line-height: 21px !important;
    height: 42px;
    max-height: 42px;
    padding-right: 42px;
  }
}

.order_group {
  &--list {
    clear: both;
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    @include a-respond-down(sm) {
      max-height: 300px;
      overflow-y: auto;
    }
    &::-webkit-scrollbar {
      width: 4px;
      &:horizontal {
        height: 4px;
      }
    }
    &::-webkit-scrollbar-thumb {
      background: #f2f2f2;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #f2f2f2;
    }
  }
  &__item {
    padding: 5px;
    width: calc(25% - 10px);
    position: relative;
    .item-default {
      position: absolute;
      right: 4px;
      top: 6px;
      font-size: 8px;
      background-color: #a8a8a8;
      color: white;
      padding: 3px;
      border-top-right-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    @include a-respond-down(xl) {
      width: calc(50% - 10px);
    }
    @include a-respond-only(xl, xxl) {
      width: calc(33% - 10px);
    }
    @include a-respond-down(md) {
      width: calc(50% - 10px)
    }
    @include a-respond-down(sm) {
      width: calc(100% - 10px);
    }
  }
}