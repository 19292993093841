
.a-purchasod__chat__dropdown li.active {
  background-color: #0052CC;
}

.a-purchasod__chat__dropdown li.active a {
  color: #ffffff;
}

.search-private input.input-private {
  background-color: #fbe4a0;
}

.search-public i, .search-private i {
  font-size: 17px;
  padding-left: 5px;
  padding-top: -5px;
  position: absolute;
  cursor: pointer;
}

.search-private i {
  color: #CA5201;

}

.search-private input::placeholder {
  color: #CA5201;
  font-size: 12px;
}

.search-public input, .search-private input {
  font-size: 13px;
  padding: initial;
  box-sizing: border-box;
}

.a-purchasod__chat__form-search .icon-spinner {
  position: absolute;
  top: 60px;
  padding-left: 50%;
}

.a-purchasod {
  font-size: 13px;
  &__chat {
    width: 100%;
    border: 1px solid $color--border;
    padding: 10px 5px;
    -webkit-box-shadow: 0px 3px 3px 0px rgba(153, 153, 153, .2);
    -moz-box-shadow: 0px 3px 3px 0px rgba(153, 153, 153, .2);
    box-shadow: 0px 3px 3px 0px rgba(153, 153, 153, .2);
    &__main {
      max-height: calc(100vh - 320px);
      overflow-y: auto;
      overflow-x: hidden;
      word-break: break-word;
      &::-webkit-scrollbar {
        width: 5px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #999999;
        border-radius: 10px;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #666666;
      }
    }

    &__title {
      padding: 15px 14px 5px 25px;
      display: flex;
      &__list {
        display: inline-flex;
        width: 100%;
        @include a-respond-down(xl) {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-right: 10px;
        }
        li {
          padding-right: 22px;
          @include a-respond-down(xl) {
            padding-right: 0;
          }
          a {
            transition: ease all .5s;
            text-decoration: none;
            color: #333333;
            padding-bottom: 5px;
            &:hover {
              color: $color--primary;
              position: relative;
              &::before {
                position: absolute;
                content: '';
                top: 100%;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: $color--primary;
                animation-name: fullwidth;
                animation-duration: .5s;
              }
            }
          }
          &.active {
            a {
              color: $color--primary;

              position: relative;
              &::before {
                position: absolute;
                content: '';
                top: 100%;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: $color--primary;

              }
            }
          }
        }
      }
    }
    &__dropdown {
      display: none;
      transition: ease all .5s;
      z-index: 99999999;
      li {
        height: 50%;
        display: flex;
        justify-content: flex-start !important;
        flex-direction: row;
        padding-left: 10px;
        align-items: center;
        &:first-child {
          border-bottom: 1px solid #cccccc;
        }
        &:last-child {
          padding-left: 12px !important;
        }
        &:hover {
          background-color: $color--primary;
          cursor: pointer;
          a {
            color: white;
          }
        }
        a {
          text-decoration: none;
          color: $color--font-primary;
          i {
            margin-right: 10px;
          }
        }
      }
    }
    &__option {
      position: relative;
      a {
        color: $color--font-primary;
      }
      &:hover {
        .a-purchasod__chat__dropdown {
          display: block;
          position: absolute;
          width: 100px;
          height: 50px;
          top: 100%;
          right: 0;
          background-color: white;
          border: 1px solid #cccccc;
        }
      }
    }
    &__form-search {
      padding: 15px 14px 5px 25px;
      position: relative;
      &.private {
        position: relative;
        .icon-status {
          color: #CA5201;
        }
        input {
          background-color: rgba(255, 250, 241, 1);
          &::-webkit-input-placeholder {
            color: #CA5201;
          }
          ::-moz-placeholder {
            color: #CA5201;
          }
          :-ms-input-placeholder {
            color: #CA5201;
          }
          :-moz-placeholder {
            color: #CA5201;
          }
        }
      }
      .icon-status {
        position: absolute;
        top: 23px !important;
        left: 33px;
        font-size: 16px;
        color: $color--primary;
      }
      .icon-upload {
        position: absolute;
        top: 23px;
        right: 25px;
        font-size: 16px;
        color: #666666;
        &:hover {
          cursor: pointer;
        }
      }
      input {
        width: 100%;
        border-radius: 0;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        padding-left: 35px;
        border: 1px solid #cccccc;
        background-color: rgba(242, 242, 242, 1);
        height: 35px;
        &:focus {
          border: 1px solid #cccccc;
          outline: none;
          box-shadow: none;
        }
      }
    }
    &__body {
      padding: 0px 12px 5px 12px;
      &__comment {
        padding-bottom: 15px;
        border-bottom: 1px solid #cccccc;
        margin-bottom: 10px;
        &__detail {
          @include dislay-flex(flex-start, row);
          align-items: center;
          i {
            margin-right: 5px;
            font-size: 11px !important;
          }
          p {
            font-size: 12px;
            color: #666666;
            .code {
              color: $color--primary;
              font-weight: bold;
              font-weight: bold;
              text-decoration: none;
              font-size: 14px;
            }
          }
        }
        &__author {
          display: flex;
          justify-content: space-between;
          margin-top: 1px;
          .type-chat {
            margin-left: 0;
          }
          span {
            &.customer {
              font-weight: bold;
              .type-chat-admin {
                color: #CC0000;
              }
              .type-chat-partner_admin {
                color: #CC0000;
              }
              .type-chat-partner_customer {
                color: #CC0000;
              }
              .icon-message {
                margin-top: 4px;
                position: absolute;
              }
            }
            &.system {
              color: #666666;
              font-weight: bold;
            }
            margin-left: 15px;
          }
          p {
            font-size: 10px;
            color: #999999;
            @include dislay-flex(flex-start, row);
            align-items: center;
            i {
              margin-right: 5px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.a-log {
  &--problem_order {
    &__item {
      &:not(:last-child) {
        margin-bottom: 10px !important;
      }
    }
  }
  &--transaction, &--problem_order {
    position: fixed;
    right: 24px;
    top: 130px;
    display: flex;
    flex-direction: column;
    width: 350px;
    height: calc(100vh - 130px);
    background-color: white;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-transition: linear height .4s;
    -moz-transition: linear height .4s ;
    -ms-transition: linear height .4s ;
    -o-transition: linear height .4s ;
    transition: linear height .4s ;
    @include media-breakpoint-down(lg) {
      width: 300px;
    }
    &.scroll {
      top: 0;
      height: 100vh;
      -webkit-transition: linear height .4s;
      -moz-transition: linear height .4s ;
      -ms-transition: linear height .4s ;
      -o-transition: linear height .4s ;
      transition: linear height .4s ;
    }
    &__title {
      padding: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, .15);

      .title {
        font-size: 16px;
        line-height: 21px;
        color: rgba(0, 0, 0, .65);
        text-transform: uppercase;
      }
    }

    &__body {
      margin-top: 16px;
      padding: 0 16px 16px 16px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 6px;

        &:horizontal {
          height: 6px;
        }
      }

      &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #6c757d;
        cursor: pointer;
      }
      &:hover {
        &::-webkit-scrollbar-thumb {
          background: #6c757d;
          border-radius: 10px;
        }
      }
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 4px;
      }

      .time-log {
        font-size: 12px;
        line-height: 16px;
        color: rgba(0, 0, 0, .25);
      }

      .detail-log {
        font-size: 12px;
        line-height: 16px;
        color: rgba(0, 0, 0, .85);
      }
    }
  }
}