.list-promotion-items {
	display: flex;
	flex-wrap: wrap;
	.promotion-item {
		margin: 0 10px 20px 10px;
		width: 260px;
		> div {
			height: 260px;
			text-align: center;
			background-color: #f9f9f9;
			img {
				max-width: 100%;
				max-height: 100%;
				object-fit: cover;
			}
		}
		h3 {
			font-size: 16px;
			margin: 10px 0;
			line-height: 22px;
		}
		p {
		}
	}
}
