@import "../settings/setting.mixin";
@import "../settings/setting.color";

/* ==========================================================================
   # START UTILITIES WIDTH
   ==========================================================================
*/
.w {
  &_20 {
    width: 20%;
  }
  &_25 {
    width: 25%;
  }
  &_50 {
    width: 50%;
  }
  &_40 {
    width: 40%;
  }
  &_60 {
    width: 40%;
  }
  &_75 {
    width: 75%;
  }
  &_80 {
    width: 80%;
  }
  &_sm {
    &_25 {
      @include a-respond-down(sm) {
        width: 25% !important;
      }
    }
    &_50 {
      @include a-respond-down(sm) {
        width: 50% !important;
      }
    }
    &_40 {
      @include a-respond-down(sm) {
        width: 40% !important;
      }
    }
    &_60 {
      @include a-respond-down(sm) {
        width: 40% !important;
      }
    }
    &_75 {
      @include a-respond-down(sm) {
        width: 75% !important;
      }
    }
  }
  &_md {
    &_25 {
      @include a-respond-down(md) {
        width: 25% !important;
      }
    }
    &_50 {
      @include a-respond-down(md) {
        width: 50% !important;
      }
    }
    &_40 {
      @include a-respond-down(md) {
        width: 40% !important;
      }
    }
    &_60 {
      @include a-respond-down(md) {
        width: 40% !important;
      }
    }
    &_75 {
      @include a-respond-down(md) {
        width: 75% !important;
      }
    }
  }
  &_lg {
    &_25 {
      @include a-respond-down(xl) {
        width: 25% !important;
      }
    }
    &_50 {
      @include a-respond-down(xl) {
        width: 50% !important;
      }
    }
    &_40 {
      @include a-respond-down(xl) {
        width: 40% !important;
      }
    }
    &_60 {
      @include a-respond-down(xl) {
        width: 40% !important;
      }
    }
    &_75 {
      @include a-respond-down(xl) {
        width: 75% !important;
      }
    }
  }
  &_xxl {
    &_25 {
      @include a-respond-up(xxl) {
        width: 25% !important;
      }
    }
    &_50 {
      @include a-respond-up(xxl) {
        width: 50% !important;
      }
    }
    &_40 {
      @include a-respond-down(xxl) {
        width: 40% !important;
      }
    }
    &_60 {
      @include a-respond-down(xxl) {
        width: 40% !important;
      }
    }
    &_75 {
      @include a-respond-up(xxl) {
        width: 75% !important;
      }
    }
  }
}

/* ==========================================================================
   # END UTILITIES WIDTH
   ==========================================================================
*/
