.new_more__codebill {
  display: inline-block;
  position: relative;
  .more__codebill {
    position: absolute;
    top: 0;
    right: -25px;
    width: 20px;
    height: 12px;
    line-height: 12px;
    padding-top: 2px;
    background-color: #CCCCCC;
    color: white;
    font-size: 11px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
      cursor: pointer;
    }
  }
}