@import "./../../../../../resources/scss/settings/setting.mixin";
@import "./../../../../../resources/scss/settings/setting.color";

.btn--receipt {
  box-shadow: none;
  border: none;
  outline: none !important;
  width: 97px !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  line-height: 14px !important;
  background-color: #285acc !important;
  padding: 0 !important;
  color: white !important;
  span {
    color: white !important;
  }
  img {
    margin-right: 3px;
  }
  &:hover {
    background-color: #0065FF;
  }
}
.a--title--address {
  margin-bottom: -11px;
  margin-left: 35%;
  text-transform: uppercase;
  color: #666666;
  font-size: 11px;
  padding-top: 26px;
  font-weight: bold;
  @include a-respond-down(xxl) {
    margin-left: 36%;
  }
  @include a-respond-down(md) {
    display: none;
  }
}

.icon-wait-read-comment {
  color: #ff9900;
}
.a-item {
  &.purchase {
    .a-item__top {
      .icon-wait-read-comment svg {
        color: #ff9900;
      }
      ul li:nth-child(2).block-paing {
        width: 32%;
      }
      ul li:nth-child(3).block-paing {
        width: 14%;
        flex-wrap: wrap;
        .a-item__block__shop{
          width: 100%;
          margin-bottom: 10px;
          span{
            height: 15px;
          }
        }
      }
      ul li:nth-child(4) {
        width: 14%;
      }
      ul li:nth-child(5) {
        width: 22%;
        flex-wrap: wrap;
        justify-content: flex-start;
        display: block;
        p{
          width: 100%;
          margin-bottom: .4rem;
        }
        @include a-respond-down(md){
          display:flex;
          flex-wrap:wrap;
          p{
            width: 100%;
          }
        }
        .a-item__block__is_complaintseller{
          margin-top:10px;
        }
      }
      ul li:nth-child(6) {
        width: 18%;
        display: flex;
        justify-content: flex-end;
      }
      .a-item__block__purchase-2{
        position: relative;
        display: block;
        p{
          margin-bottom:5px;
        }
        .more__codebill{
          position: absolute;
          top: 0;
          right: -25px;
          width: 20px;
          height: 13px;
          background-color: #CCCCCC;
          color: white;
          font-size: 11px;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover{
            cursor: pointer;
          }
        }
        .more__codebill__detail{
          position: absolute;
          left: 100%;
          top: 20px;
          min-width: 150px;
          max-height: 150px;
          box-shadow: 0 0 15px rgba(0,0,0,.2);
          padding: 10px;
          background-color: white;
          z-index: 1010;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          &::-webkit-scrollbar {
            width: 3px;
            &:horizontal {
              height: 3px;
            }
          }
          &::-webkit-scrollbar-thumb {
            background: #e2e5ec;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-thumb:hover {
            background: #e2e5ec;
            cursor: pointer;
          }
          li{
            margin-bottom: 5px;
            &:last-child{
              margin-bottom: 0;
            }
            a{
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  &.icon-has-read-comment {
    background: #F8F8F8;
  }

  &--group {
    &--label {
      min-width: fit-content !important;
      padding-right: 10px;
    }
    &--value {

    }
  }
  &__bottom {
    padding: 9px 5px;
    @include a-respond-down(md){
      justify-content: space-between !important;
    }
    &__block {
      display: flex;
      align-items: center;

    }
    &__group {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
    }
    &__label {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 700;
      color: #333333;
      min-width: auto !important;
      &.normal {
        font-weight: 400;
      }
    }
    &__value {
      font-size: 11px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #666666;
      min-width: auto !important;
      padding-left: 10px !important;
      font-weight: 400;
    }
  }
}
.a-sort{
    &.purchase{
      ul{
        li{
          &:nth-child(2){
            width: calc(32% + 13px);
          }
          &:nth-child(4){
            width: 7.5%;
          }
        }
      }
    }
}
.a-item--group--value p {
  max-width: 100% !important;
}

.a-text-underline:hover {
  text-decoration: underline;
}

.a-item__block__total--money span.a-text--blue {
  color: #0033FF !important;
}

.a-tab-order--number-noti {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(203, 245, 204, 1);
  color: #006600;
  border-radius: 12px;
  padding: 2px 6px;
  &.more {
    padding: 2px 10px;
  }
}

.a-item__account {
  img.img-shop {
    width: 16px;
    height: 16px;
    object-fit: cover;
  }
}

.a__select {
  display: flex;
  align-items: center;
  position: relative;
  .button_select_china {
    color: #666666;
    border: 1px solid #f2f2f2;
    height: 32px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 280px;
    background-color:white;
    @include a-respond-down(sm) {
      max-width: 200px;
      min-width: 200px;
    }
    &:focus {
      background-color: #0052CC;
      color: white;
      border: 1px solid #0052CC;
      outline: none;
    }
  }
  &__value{
    max-height: 250px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
      &:horizontal {
        height: 5px;
      }
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #CCCCCC;
      border-radius: 10px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #CCCCCC;
      cursor: pointer;
    }

    li {
      display: flex;
      align-items: center;
      padding: 5px;
      cursor: pointer;
      &:hover {
        background-color: #f2f2f2;
      }
      .select_value{
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: block;
      }
    }
  }
  &__option {
    margin-top: 5px;
    position: absolute;
    top: 100%;
    width: 100%;
    box-shadow: 0 10px 0 #00000029;
    border: 1px solid #f2f2f2;
    background-color: white;
    z-index: 10101;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.1);
    .input-search {
      padding: 5px;
      &:hover {
        background-color: white;
      }
      input {
        height: 30px;
        width: 100%;
        border-radius: 4px;
        border: 1px solid #a8a8a8;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
}
