.order-problems {
	display: flex;
	display: -webkit-flex;
	.border-resolve-order {
		position: relative;
		&:before {
			content: "";
			position: absolute; top: 3px; left: 0;
			height: calc(100% - 6px);
			border-left: 1px solid #ddd;
		}
	}
}

.unresolve-order-modal {
	.ant-modal-body {
		padding: 0;
	}
	.ant-modal-header {
		border-bottom: none;
		margin-bottom: 10px;
	}
	.ant-modal-footer {
		border-top: none;
	}
	.ant-modal-content {
		padding: 20px 15px;
	}
}