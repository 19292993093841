.time-ago {
    height: 16px !important;
    &--red {
        color: #ff0000;
    }
    &--yellow {
        color: #ff9900;
    }
    &--green {
        color: #009933;
    }
    &--orange {
        color: #e26918;
    }
}

.add-product-info {
    //.ant-form-item-label,
    //.ant-form-item-control {
    //    line-height: 24px !important;
    //}
    .ant-radio-button-wrapper {
        margin: 5px;
    }
    .ant-radio-button-wrapper:first-child,
    .ant-radio-button-wrapper:last-child {
        border-radius: unset !important;
    }
}

.ant-btn-link {
    color: #1890ff;
    background-color: transparent;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.btn-cancel-order{
    padding-top: 1px;
    color: #fff;
    background:#ff0000;
    border-color: #eb1919;
    &:hover{
        background:#eb1919;
        border-color: #eb1919;
        color: #fff;
    }
    &:focus{
        background:#ff0000;
        border-color: #eb1919;
        color: #fff;
    }
}
.btn-submit {
    background: #0050B3;
    color: #fff;
    &:focus {
        background: #0050B3;
        color: #fff;
    }

    &:hover {
        background: #559bf1;
        color: #fff;
    }
}