@import "../../../resources/scss/settings/setting.color";
@import "../../../resources/scss/settings/setting.variable";
@import "../../../resources/scss/settings/setting.mixin";

.a_complaint_solution{
  &__top{
    display: flex;
    @include a-respond-down(lg) {
      flex-wrap: wrap;
    }
  }
  &__left{
    width: var(--width-body-is-form-chat);
  }
  &__right{
    width: var(--width-form-chat);
    float: left;
    position: fixed;
    right: 0;
    top: 0;
  }
  &__title{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    &__complaint_id{
      margin-left: 5px;
      a{
        text-decoration: none;
      }
    }
    &__status{
      margin-left: 20px;
      padding:4px 5px 3px 5px;
      font-size:11px;
    }
    &__id{
      width: 100%;
      margin-top: 8px;
      font-size: 12px;
      color: $txt-color--gray7;
    }
    &__block_right{
      margin-bottom: 20px;
      font-size: 13px;
      font-weight: 700;
      color: $txt-color--gray3;
      span{
        font-weight: 400;
      }
    }
  }
  &__action{
    display: flex;
    a{
      font-size: 13px;
    }
    @include a-respond-down(lg) {
      justify-content: flex-end;
      width: 100%;
      margin-top: 15px;
    }
  }
  &__body{
    margin-top: 20px;
    &__action{
      display: flex;
      justify-content: flex-end;
    }
    &__block{
      &.block__border_bottom{
        padding-bottom: 20px;
        border-bottom: 1px solid #d8d8d8;
        margin-bottom: 20px;
      }
      &.block__table{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        p{
          font-weight: 700;
          font-size: 13px;
        }
      }

      .action_save__code {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 15px;
        padding-right: 15px;
        font-weight: 500;
        font-size: 13px;
        font-style: normal;
        border: 1px solid #d8d8d8;
        color: #333333 !important;
        text-decoration: none;
        background-color: #e4e4e4 !important;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0 2px 2px 0;
        border-left: 0;
        text-transform: uppercase;
        &:hover {
          background-color: #d2d2d2 !important;
        }
      }
    }
  }
  &__label{
    float: right;
    width: 100%;
    padding-top: 7.5px;
    font-size: 13px;
    font-weight: 500;
    text-align: right;
    color: $txt-color--gray3;
    &::before{
      display: block;
      content: '';
      clear: both;
    }
  }
  &__value{
    font-size: 13px;
    font-weight: 400;
    color: $txt-color--gray3;
    padding-top: 7.5px;
    .value__code_bill{
      width: 100%;
      word-break: break-word;
    }
  }
  &__link{
    text-decoration: none;
    font-weight: 500;
    &:hover{
      text-decoration: underline;
    }
  }
  &__form_group{
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 20px;
  }
}

.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
}

.react-tagsinput--focused {
  border-color: #a5d24a;
}

.react-tagsinput-tag {
  background-color: #cde69c;
  border-radius: 2px;
  border: 1px solid #a5d24a;
  color: #638421;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: " ×";
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 80px;
}

.disable-tagsinput{
  border: 1px solid #ccc !important;
}

.disable{
  pointer-events: none;
  opacity: .5;
  background-color: #f2f2f2;
  border: 1px solid #c4c4c4;
}
span.disabled{
  pointer-events: none;
  opacity: .5;
  background-color: #f2f2f2;
}