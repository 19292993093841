.a-upload-csv {
    width: 376px;
    height: 100%;
    padding-left: .4rem;
    padding-right: .4rem;
    margin-bottom: 2rem;
    .ant-upload-drag{
        margin-top: 45%;
        background-color: white;
        border: none;
        margin-bottom: 2rem;
    }
    @include media-breakpoint-down(lg){
        width: 300px;
    }
}