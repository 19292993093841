@import "./../settings/setting.mixin";
@import "./../settings/setting.color";
/* ==========================================================================
   # START COMPONENTS HEADER
   ==========================================================================
*/
.pageHeader {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  -webkit-box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
  box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
  width: 100%;
  height: 70px;
  left: 220px;
  background-color: white;
  @include a-respond-down(xs) {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    @include a-respond-down(xs) {
      justify-content: center;
    }
  }
  @include a-respond-down(md) {
    margin-top: 60px;
  }
  @include a-respond-down(sm) {
    margin-top: 49px;
    height: 50px;
  }
  &.purchase_buying{
    width: calc(100% - 269px);
    -webkit-box-shadow: -6px 1px 15px 1px rgba(69, 65, 78, 0.08);
    box-shadow: -6px 1px 15px 1px rgba(69, 65, 78, 0.08);
    @include a-respond-down(md){
      width: 100%;
    }
    @include a-respond-down(md){
      width: 100%;
    }
  }
  &-title {
    margin-right: 40px;
    padding-left: 15px;
    @include a-respond-down(xs) {
      margin-right: 0;
      padding-left: 5px;
    }

  }
  &--breadcrumbs {
    position: relative;
    margin-top: 0px;
    @include  a-respond-down(xs){
      margin-top: 0;
    }
    @include  a-respond-down(md){
      margin-top:0;
    }
    &::before {
      position: absolute;
      content: '';
      height: 52%;
      width: 1px;
      top: 0%;
      right: -37px;
      background-color: #e2e5ec;
      @include a-respond-down(xs) {
        width: 0;
      }
      @include  a-respond-down(md){
        width: 0;
      }
    }
    span {
      @include a-respond-down(xs) {
        display: none;
      }
    }
  }
  h2 {
    color: #3f4047;
    font-size: 22px;
    text-transform: uppercase;
    @include a-respond-down(sm) {
      font-size: 18px;
      padding-left: 10px;
    }
  }
  span {
    font-size: 12px;
    color: #3f4047;
  }
  &-breadcrumbs {
    font-size: 14px;
    margin-top:-19px;
    @include a-respond-down(xs) {
      display: none;
    }
    @include a-respond-down(md) {
      display: none;
    }
  }
}

/* ==========================================================================
   # END COMPONENTS HEADER
   ==========================================================================
*/

