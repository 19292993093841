@import "./../settings/setting.mixin";
@import "./../settings/setting.color";

/* ==========================================================================
   # START COMPONENT CONTENT
   ==========================================================================
*/
.a-content {
    width: 100%;
    padding: 1.55rem 0 1rem 0;
    @include a-respond-down(sm) {
        padding: 1rem 0 1rem 0;
    }

    &--center {
        margin: 0 auto !important;
    }

    &__complaint-seller {
        width: calc(100% - 268px);
        padding: 0 15px;
        @include a-respond-down(md) {
            width: 100%;
        }
    }

    &__right {
        width: 100%;

        &__center {
            display: flex;
            justify-content: center;
            width: 100%;
            @include a-respond-up(xxl) {
            }
            margin-left: 20px;

            &.width-fixed {
                overflow: hidden;
                @include a-respond-down(md) {
                    max-width: 100%;
                }
            }

            &.settings__body {
                margin-left: 20px;
                @include a-respond-down(md) {
                    margin-left: 0 !important;
                }
            }
        }
    }

    &--box-shadow {
        -webkit-box-shadow: 0px 1px 10px 1px rgba(69, 65, 78, 0.08);
        box-shadow: 0px 1px 10px 1px rgba(69, 65, 78, 0.08);
        background-color: white;
        padding: 1rem;
        border-radius: 4px;
        box-sizing: border-box;
        @include a-respond-down(sm) {
            padding: .5rem;
        }
    }

    &--ant-layout {
        margin: 0 24px -1rem 24px;
        @include a-respond-down(sm) {
            margin: 0 5px;
        }
    }

    &--upload-csv {
        display: flex;
        flex-direction: row;
    }

    &--is-pagination-fixed {
        margin-bottom: 66px;
        @include media-breakpoint-down(lg) {
            margin-bottom: 74px !important;
        }

        .list-block-file-upload {
            bottom: 66px !important;
            @include media-breakpoint-down(lg) {
                bottom: 74px !important;
            }
        }
    }

    &--detail-transaction {
        width: calc(100% - 350px - 24px);
        @include media-breakpoint-down(lg) {
            width: calc(100% - 300px - 24px);
        }
    }
    &--is-sub-sidebar {
        width: calc(100% - 260px);
        margin-left: 20px;
        @include media-breakpoint-down(lg) {
            width: calc(100% - 214px);
        }
    }
    /*
     * Style chi tiết đơn có vấn đề
     */
    &--problem-detail {
        width: calc(100% - 350px - 24px);
        @include media-breakpoint-down(lg) {
            width: calc(100% - 300px - 24px);
        }
        h3 {
            text-transform: capitalize;
        }
        .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
            padding: 10px 16px !important;
        }
    }
}

.a-list {
    background-color: white;

    &--form-search {
        padding: 20px 24px 24px 24px;
        background-color: white;
    }

    &--action-search {
        display: flex;
        flex-direction: column;

        .link-reset {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 3px;
            margin-bottom: 4px;
            color: rgba(0, 0, 0, .45);
            font-size: 12px;
            line-height: 16px;

            i {
                margin-right: 5px;
            }

            &:hover {
                text-decoration: none;
            }

            &:focus {
                text-decoration: none;
            }
        }
    }

    &__top {
        padding: 16px 24px;
        border-bottom: .1px solid #dddddd;
        background-color: white;

        .a-list--title {
            font-size: 18px;
        }
    }

    &__body {
        padding: 24px 0 0 24px;
        width: 100%;

        &.upload-csv {
            padding: 24px 24px 0 24px;

            .error-deal {
                .name-file {
                    color: $color--red-c;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .list-block-file-upload {
                position: fixed;
                bottom: 1px;
                right: 24px;
                display: flex;
                flex-direction: column;
                width: 296px;
                height: 200px;
                z-index: 1010;
                background-color: white;
                -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
                -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
                box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
                border-radius: 4px;
                color: rgba(0, 0, 0, .85);
                font-size: 12px;
                -webkit-transition: linear height .2s;
                -moz-transition: linear height .2s;
                -ms-transition: linear height .2;
                -o-transition: linear height .2s;
                transition: linear height .2s;

                &.collapsed {
                    height: 40px;
                    overflow: hidden;
                }

                .title {
                    display: flex;
                    padding: 11px 16px;
                    color: white;
                    background-color: $color--primary;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;

                    .action {
                        margin-left: auto;

                        i {
                            &:last-child {
                                margin-left: 10px;
                            }

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }

                .body {
                    display: flex;
                    flex-direction: column;
                    height: 100%;


                    .body-top {
                        display: flex;
                        padding: 8px 16px;
                        background-color: rgba(0, 0, 0, .05);

                        .number-uploading {
                            font-size: 12px;
                        }

                        .cancel-upload {
                            margin-left: auto;
                            text-transform: uppercase;
                            color: $txt-color-primary;
                        }
                    }

                    .list-file-upload {
                        height: 100%;
                        overflow-y: auto;

                        &::-webkit-scrollbar {
                            width: 4px;

                            &:horizontal {
                                height: 4px;
                            }
                        }

                        &::-webkit-scrollbar-thumb {
                            background: rgba(0, 0, 0, .45);
                            border-radius: 4px;
                        }

                        &::-webkit-scrollbar-thumb:hover {
                            background: rgba(0, 0, 0, .45);
                            cursor: pointer;
                        }

                        li {
                            display: flex;
                            align-items: center;
                            list-style-type: none;
                            padding: 12px 14px;

                            &:not(:last-child) {
                                border-bottom: 1px solid rgba(0, 0, 0, .15);
                            }

                            .file-name-upload {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                padding-right: 5px;
                            }

                            .icon-upload {
                                margin-left: auto;
                            }

                        }
                    }
                }
            }
        }
    }

    &--bill-code {
        display: flex;
        flex-wrap: wrap;
        margin-left: -8px;
        margin-right: -8px;
        &__item {
            position: relative;
            margin-bottom: 4px;
            &:not(:first-child) {
            }

            &:not(:last-child) {
                &:before {
                    position: absolute;
                    top: 3px;
                    right: 0;
                    content: '';
                    width: 1px;
                    height: 16px;
                    background-color: rgba(0, 0, 0, .25);
                }
            }

        }

        &__link {
            color: $txt-color-primary;
            text-decoration: underline;
            line-height: 16px;
            padding-left: 8px;
            padding-right: 8px;

            &:visited {
                color: $txt-color-primary;
            }
        }
        &__normal {
            line-height: 16px;
            padding-left: 8px;
            padding-right: 8px;
        }
    }

    &--user {
        .a-user {
            display: inline-flex;
            padding: 5px 11px;
            border: 1px solid $color--primary;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
            margin-bottom: 10px;
            &:not(:last-child) {
                margin-right: 10px;
            }
            &--name {
                color: $color--primary;
                font-size: 14px;
                font-weight: 500;
                line-height: 19px;
                margin-right: 14px;
            }

            &--icon-remove {
                display: flex;
                align-items: center;
                i {
                    margin-top:3px;
                    font-size: 12px;
                    color: $color--primary;
                }
            }
        }
    }
}

.a-block {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: white;

    &__top {

        &__title {
            margin-bottom: 10px;
            font-size: 18px;
            font-weight: bold;
            color: $txt-color-primary;
            line-height: 24px;
        }

        &--info-transaction {
            margin-left: 24px;
            margin-right: 24px;
            margin-bottom: 14px;
            padding-bottom: 10px;
            border-bottom: 1px solid rgba(0,0,0,.25);
        }

        &--order-transaction {
            padding-left: 24px;
            padding-right: 24px;
        }
        &--problem-detail {
            margin-left: 24px;
            margin-right: 24px;
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            strong {
                font-weight: 500;
            }
            a {
                border: 1px solid #2a87d0;
                display: inline-block;
                border-radius: 3px;
                padding: 2px 15px;
                transition: all .5s;
                &:hover {
                    border-color: #40a9ff;
                }
            }
        }
    }

    &__body {
        padding-left: 24px;
        padding-right: 24px;
        &__collapse {
            &.line-wrap {
                display: flex;
                width: 100%;
                height: 1px;
                border-bottom: 1px dashed rgba(0, 0, 0, .25);
                margin-bottom: 14px;
            }
        }
        &--order-transaction {
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid rgba(0, 0, 0, .15);
        }
        &--problem-detail {
            .pd-thumb {
                img {
                    max-width: 100%;
                }
            }
            .img-logo-partner {
                background-repeat: no-repeat;
                width: auto;
                display: inline-block;
                padding-left: 20px;
                line-height: 18px;
                text-transform: capitalize;
            }
        }
        &--create-payment-request {
            background-color: white;
            padding-left: 24px;
            padding-right: 24px;
            display: flex;
            justify-content: center;
        }
    }

    &--info-transaction {
        padding-top: 16px;
        margin-top: 16px;
    }

    &--order-transaction {
        margin-top: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
        .order-transaction--title-info {
            margin-bottom: 10px;
            margin-top: 10px;
            font-size: 14px;
            color: #0F0F0F;
            line-height: 19px;
            font-weight: 500;
        }
    }

    &--notify-heading {
        position: relative;

        &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: white;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
    }

    &--notify-warning {
        &::before {
            background-color: #FF0000;
        }
    }

    &--notify-none {
        &::before {
            background-color: white;
        }
    }

    &--notify-success {
        &::before {
            background-color: $txt-color--green0;
        }
    }

    &--export-data {
        display: flex;
        flex-direction: column;
        width: 100%;
        .title {
            margin-bottom: 20px;
            font-size: 32px;
            font-weight: bold;
            line-height: 43px;
            color: rgba(0,0,0,.65);
            text-transform: uppercase;

        }
        .a-form--ant-item {
            width: 80%;
            .ant-calendar-picker {
                width: 100%;
            }
        }
    }
}


.img-logo-partner {
    width: 16px;
    height: 16px;
    object-fit: cover;
    margin-right: 6px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    &._alipay {
        background-image: url('./../img/logo-partner/alipay-1-569587.png');
        background-size: 16px 16px;
    }
    &._1688 {
        background-image: url('./../img/logo-partner/favicons_1688.png');
        background-size: 16px 16px;
    }
    &._tmall {
        background-image: url('./../img/logo-partner/favicons_tmall.png');
        background-size: 16px 16px;
    }
    &._taobao {
        background-image: url('./../img/logo-partner/favicons_taobao.png');
        background-size: 16px 16px;
    }
    &._amazon {
        background-image: url('./../img/logo-partner/favicons_amazon.png');
        background-size: 16px 16px;
    }
}
.t-group{
    &--input-inline {
        input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        button {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}
.img-link-newtab {
    width: 14px;
    height: 14px;
}

.circle-icon {
    border: 1px solid white;
    border-radius: 50%;
    color: white;
    padding: 0.7em;
    &--purple {
        @extend .circle-icon;
        background-color: #7633d3;
    }
    &--gold {
        @extend .circle-icon;
        background-color: #fea63c;
    }
    &--blue {
        @extend .circle-icon;
        background-color: #2598ff;
    }
    &--red {
        @extend .circle-icon;
        background-color: #ff5556;
    }
}

.a-account {
    &__employee {
        display: inline-flex;
        align-items: center;
        margin-bottom: 10px;
        padding: 4px 6px;
        background-color: rgba(0, 58, 140, .1);
        border: 1px solid rgba(0, 58, 140, .6);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        &:not(:first-child) {
            margin-right: 10px;
        }
        &:not(:last-child) {
            margin-right: 10px;
        }
        &__logo-partner {
            display: flex;
            position: relative;
            padding-right: 7px;
            width: 21px;
            height: 14px;

            &:before {
                position: absolute;
                top: 0;
                right: 0;
                content: '';
                width: 1px;
                height: 100%;
                background-color: rgba(0, 58, 140, .6);
            }

            .img-partner {
                width: 14px;
                height: 14px;
            }
        }

        &__body {
            margin-left: 7px;
            .name-account {
                color: rgba(0, 0, 0, .85);
            }
            .date-create-account {
                color: rgba(0, 0, 0, .65);
                line-height: 16px;
            }
        }

        &__close {
            margin-left: 10px;
            i {
                color: rgba(0, 0, 0, .65);
            }
        }
    }
}
.opacity_6 {
    opacity: .6 !important;
}
.opacity_4 {
    opacity: .4 !important;
}
.align-item-center {
    display: flex;
    display: -webkit-flex;
    align-items: center;
}

/* ==========================================================================
   # END COMPONENT CONTENT
   ==========================================================================
*/

.related_transaction {
    .edit_btn {
        display: inline-block;
        /*opacity: 0;
        transition: all .3s;*/
    }
    /*&:hover {
        .edit_btn {
            opacity: 1;
        }
    }*/
}

.a-modal--related-transaction {
    .link_item {
        width: 360px;
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap;
        overflow: hidden;
    }
}

.payment-request-modal {
    .img-item {
        margin-top: 5px;
        li {
            position: relative;
            display: inline-block;
            span {
                position: absolute; top: 0; right: 0;
                display: inline-block;
                background: rgba(255,255,255,.8);
                width: 16px;
                height: 16px;
                line-height: 16px;
                font-size: 10px;
                text-align: center;
                color: #ea442b;
                text-decoration: none;
            }
        }
    }
}
.header_partner {
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    color: #fff;
    font-weight: bold;
    display: flex;
    height: 26px;
    width: 26px;
    align-items: center;
    justify-content: center;
    background-color: #326eea;
    margin-top: -3px;
    margin-bottom: -3px;
}

.box-square {
    box-shadow: 0px 1px 10px 1px rgba(69, 65, 78, 0.08);
    background-color: white;
    box-sizing: border-box;
    &--title {
        padding: 1rem;
        text-transform: uppercase;
        border-bottom: 1px solid #cccccc;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
    }
    &--body {
    }
}
