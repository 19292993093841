@import "../settings/setting.mixin";
@import "../settings/setting.color";

.a-btn {
    height: 35px;
    border-radius: 2px;

    &--primary {
        border: 1px solid $color-button-primary;
        height: 30px;
        color: white !important;
        display: flex;
        align-items: center;
        text-decoration: none;
        background-color: #0050B3 !important;
        font-weight: 400;
        font-style: normal;
    }

    &--default {
        color: #212529;
        background-color: #f3f5f6;
        border-color: #f3f5f6;
    }

    &--disable {
        color: white !important;
        pointer-events: none;
        background: #999999 !important;
        cursor: default !important;
    }

    &--none {
        color: #0050B3 !important;
        display: flex;
        align-items: center;
        text-decoration: none;

        &:hover {
            cursor: pointer;
        }
    }

    &--orange {
        border: 1px solid orange;
        height: 30px;
        color: white !important;
        display: flex;
        align-items: center;
        text-decoration: none;
        background-color: orange;
        font-weight: 400;
        font-style: normal;

        &:focus {
            outline: none;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &--payment {
        border: 1px solid #003A8C;
        height: 35px;
        color: white !important;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        background-color: #003A8C;
        font-weight: 700;
        font-style: normal;
        font-family: 'Roboto';
        text-transform: uppercase;
        font-size: 12px;
        min-width: 115px;

        &:focus {
            outline: none;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &--default-action {
        border: 1px solid #e3e3e3;
        height: 35px;
        color: white !important;
        display: flex;
        align-items: center;
        text-decoration: none;
        background-color: #e3e3e3 !important;
        font-weight: 700;
        font-style: normal;
        font-size: 12px;
        padding-left: 15px;
        padding-right: 15px;
        text-transform: uppercase;
    }

    &--disable-action {
        background-color: #e3e3e3 !important;
        border: 1px solid #e3e3e3;
        pointer-events: none;
    }

    &--submit {
        box-shadow: none;
        border: none;
        outline: none;
        width: 92px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 14px;
        background: $color-button-primary;
        padding: 0;

        &:focus {
            color: #0080ff;
        }
    }

    &--pop-submit {
        padding-left: .8rem;
        padding-right: .8rem;
        height: 29px;
        background-color: rgba(0, 128, 0, 0.87);;
        border: 1px solid rgba(0, 128, 0, 0.87);
        color: white;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.disable {
            opacity: .5;

            &:hover {
                cursor: not-allowed;
            }
        }
    }

    &--height-35 {
        height: 35px;
    }

    &--uppercase {
        font-weight: 700;
        font-style: normal;
        text-transform: uppercase;
    }

    &--pop-cancel {
        padding-left: .2rem;
        padding-right: .2rem;
        background-color: white;
        border: none;
        color: #FF0000;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.disable {
            opacity: .5;

            &:hover {
                cursor: not-allowed;
            }
        }
    }

    &--filter {
        &__group_purchase {
            width: 500px;
            position: relative;
            @include a-respond-down(md) {
                width: 100%;
                max-width: 100%;
            }
            @include a-respond-only(md, lg) {
                width: 300px;
            }

            .icon-search {
                position: absolute;
                top: 5px;
                left: 6px;
                color: #CCCCCC;
                font-size: 19px;
                @include a-respond-down(md) {
                    font-size: 18px;
                }
            }

            .icon_remove {
                position: absolute;
                top: 9px;
                right: 6px;
                color: #CCCCCC;

                &:hover {
                    transition: all .3s ease;
                    cursor: pointer;
                    color: #555555;
                }
            }
        }

        &-order {
            &.filter-order--over {
                div {
                    div {
                        div[data-role="droplistContent"] {
                            overflow: inherit !important;
                        }
                    }
                }
            }

            .search__complaintseller {
                display: flex;
                justify-content: space-between;
                padding: 5px 0 0 0;

                label {
                    display: flex;
                    align-items: center;

                    input[type="radio"] {
                        margin-right: 6px;
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }

    &-link {
        &:hover {
            color: $color-button-primary;
        }

        &--uppercase {
            text-transform: uppercase;
            font-size: 12px;
        }
    }

    &-resize {
        border: none;
        background-color: white;

        &:hover {
            cursor: pointer;
        }

        &:focus {
            outline: none;
        }
    }

    &--pre_next {
        border: 1px solid rgba(228, 228, 228, 1);
        display: flex;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        height: 20px;
        margin-left: 20px;
        @include a-respond-down(md) {
            padding-left: 0;
            padding-right: 0;
            border-radius: 20px;
            height: 22px;
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
            margin-left: 0;
        }
        border-radius: 2px;

        button {
            border: none;
            outline: none;
            background-color: white;
            height: 20px;
            color: #CCCCCC;
            font-weight: 500;
            font-size: 13px;
            -webkit-transition: ease all .3s;
            -moz-transition: ease all .3s;
            -ms-transition: ease all .3s;
            -o-transition: ease all .3s;
            transition: ease all .3s;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            @include a-respond-down(md) {
                border-radius: 20px;
                width: 65px;
                height: 22px;
                font-size: 10px;
            }

            &.active {
                color: #666666;
                @include a-respond-down(md) {
                    color: #555555;
                    background-color: #dddddd;
                }
            }

            &:last-child {
                border-radius: 0;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }

    &--pre_next_sale {
        border: 1px solid rgba(228, 228, 228, 1);
        display: flex;
        align-items: center;
        padding-left: 4px;
        padding-right: 4px;
        border-radius: 2px;
        margin-top: 8px;
        background: #ffffff;
        min-width: 165px;
        @include a-respond-down(md) {
            border-radius: 20px;
            height: 30px;
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
            margin-left: 0;
            min-width: auto;
        }

        button {
            border: none;
            outline: none;
            background-color: white;
            height: 30px;
            color: #CCCCCC;
            font-weight: 500;
            min-width: 80px;
            font-size: 13px;
            -webkit-transition: ease all .3s;
            -moz-transition: ease all .3s;
            -ms-transition: ease all .3s;
            -o-transition: ease all .3s;
            transition: ease all .3s;
            @include a-respond-down(md) {
                border-radius: 20px;
                width: 65px;
                height: 22px;
                font-size: 10px;
            }

            &.active {
                color: #666666;
                @include a-respond-down(md) {
                    color: #555555;
                    background-color: #dddddd;
                }
            }
        }
    }

    &--search_purchase {
        width: 100px;
        background-color: $color-button-primary;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-size: 14px;
        border-radius: 2px;
        height: 32px;
        position: absolute;
        right: 0;
        top: 0;
        &:focus {
            color: white;
        }
    }

    &--blue {
        height: 30px;
        border: 1px solid $color-button-primary;
        height: 30px;
        color: white !important;
        display: flex;
        align-items: center;
        text-decoration: none;
        background-color: #0050B3 !important;
        font-weight: 400;
        font-style: normal;
        font-family: 'Roboto';
        padding-left: 10px;
        padding-right: 10px;
        white-space: nowrap;
    }

    &--red {
        border: 1px solid #ff0000;
        height: 30px;
        color: white !important;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        background-color: #ff0000 !important;
        font-weight: 400;
        font-style: normal;
        padding-left: 10px;
        padding-right: 10px;
        white-space: nowrap;
    }

    &--gray {
        border: 1px solid #e3e3e3;
        height: 30px;
        color: #333333 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        background-color: #e4e4e4 !important;
        font-weight: 400;
        font-style: normal;
        padding-left: 10px;
        padding-right: 10px;
        white-space: nowrap;

        > span {
            color: #333333 !important;
        }
    }

    &--green {
        border: 1px solid #28a745;
        height: 30px;
        color: white !important;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        background-color: #28a745 !important;
        font-weight: 400;
        font-style: normal;
        padding-left: 10px;
        padding-right: 10px;
        white-space: nowrap;

        > span {
            color: white !important;
        }
    }

    &--syncs {
        border: 1px solid #285acc;
        height: 30px;
        color: white !important;
        display: flex;
        align-items: center;
        text-decoration: none;
        background-color: #285acc !important;
        font-weight: 500;
        text-transform: uppercase;
        white-space: nowrap;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 12px;
        border-radius: 2px;
        margin-right: 35px;
        margin-left: 20px;
        @include a-respond-up(xl) {
            margin-left: 15px;
        }
        @include a-respond-down(sm) {
            justify-content: center;
            margin-top: 20px;
        }

        i {
            margin-right: 10px;
        }
    }

    &--export {
        border: none;
        margin-top: 25px !important;
        text-decoration: none !important;;
        padding: 0px 30px !important;;
        height: 30px !important;;
        background-color: rgb(0, 82, 204) !important;;
        color: white !important;;
        display: flex !important;;
        align-items: center !important;;
        justify-content: center !important;;
        border-radius: 2px !important;;
        font-weight: 500;
        margin-left: 2rem !important;
        @include a-respond-only(md, lg) {
            margin-top: 35px !important;
        }
        @include a-respond-down(md) {
            margin-top: 36px !important;
        }
        @include a-respond-down(sm) {
            margin-left: 0 !important;
            margin-top: 10px !important;
            width: 100% !important;
        }

        &:focus {
            color: white;
        }

        i {
            margin-right: 5px;
        }

    }

    &--toggle_sale {
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: #333333;
        padding-left: 5px;
        padding-left: 5px;
        padding-right: 5px;

        &.button1 {
            border: 1px solid #a8a8a8;
            border-right: 0;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &.button2 {
            border: 1px solid #a8a8a8;
            border-left: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        &:focus {
            color: #333333;
        }

        &.active {
            background-color: #999999;
            color: white;
            border: 1px solid #999999;
        }
    }

    &--select-file {
        padding: 0 10px;
        height: 35px;
        background-color: #f4f5f8;
        border: 1px solid #ebedf2;
        color: #666666;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            cursor: pointer;
        }

        &.disable {
            pointer-events: none;
            opacity: .5;

            &:hover {
                cursor: default;
            }
        }
    }

    &--setting_cancel {
        height: 30px;
        padding: 0 40px;
        border: 1px solid $color--red-c;
        background-color: $color--red-c;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;

        &:focus {
            color: white;
        }

        &.disable {
            pointer-events: none;
            opacity: .5;
            background-color: #c4c4c4;
            border: 1px solid #c4c4c4;

            &:hover {
                cursor: default;
            }
        }
    }

    &--setting_save {
        padding: 0 50px !important;
        border: 1px solid $color-button-primary;
        background-color: $color-button-primary;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white !important;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;

        &:focus {
            color: white;
        }
        &.disable {
            pointer-events: none;
            opacity: .5;
            background-color: #c4c4c4 !important;
            border: 1px solid #c4c4c4 !important;
            color: #f2f2f2;

            &:hover {
                cursor: default;
            }
        }
    }

    &__sort_complaint_order {
        background-color: #CCCCCC;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        font-size: 12px;
        font-weight: 700;
        color: white;
        padding: 2px 8px;

        &:hover {
            cursor: pointer;
        }
    }

    &--complaint_solution {
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 500;
        border: 1px solid #D8D8D8;

        &:hover {
            background-color: #d2d2d2 !important;
        }

        i {
            margin-right: 5px;
        }

        &:not(:last-child) {
            margin-right: 5px;
        }

        &.disable {
            font-weight: 400;
            pointer-events: none;
            opacity: .6;
        }
    }

    &--primary-ant {
        background-color: $color-button-primary;
        border: 1px solid $color-button-primary;
        color: white;
        font-size: 12px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;

        &:hover {
            background-color: $color-button-primary;
            border: 1px solid $color-button-primary;
            color: white;
            opacity: .8;
        }

        &:focus {
            background-color: $color-button-primary;
            border: 1px solid $color-button-primary;
            color: white;
        }

        &.ghost {
            background-color: white;
            border: 1px solid $color-button-primary;
            color: $color-button-primary;
        }

        &:disabled {
            color: rgba(0, 0, 0, 0.25) !important;
            background-color: #f5f5f5 !important;
            border: 1px solid #d9d9d9 !important;
            text-shadow: none;
            box-shadow: none;
        }
    }

    &--padding-large {
        padding-left: 74px;
        padding-right: 74px;
    }

    &--default-ant {
        background-color: white;
        border: 1px solid rgba(0, 0, 0, .45);
        color: rgba(0, 0, 0, .45);
        font-size: 12px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;

        &:hover {
            background-color: white;
            border: 1px solid rgba(0, 0, 0, .45);;
            color: rgba(0, 0, 0, .45);
        }

        &:focus {
            background-color: white;
            border: 1px solid rgba(0, 0, 0, .45);;
            color: rgba(0, 0, 0, .45);
        }
    }

    &--upload-file {
        display: flex;
        padding-top: 3px;
    }
    &--link {
        border: none;
        background: transparent;
        &:hover {
            background-color: transparent;
            border: none;
            box-shadow: none;
        }
        &:focus {
            background-color: transparent;
            border: none;
            box-shadow: none;
        }
        &:disabled {
            border: none !important;
            background-color: transparent !important;
        }
    }
}

.a-link {
    color: $color-button-primary;
    transition: ease all .3s;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        color: $color-button-primary;
    }

    &:focus {
        text-decoration: none;
    }

    &--uppercase {
        text-transform: uppercase;
        font-weight: 700;
        text-decoration: none;

        i {
            margin-left: 7px;
            font-size: 14px;
        }
    }
}

.resize {
    &-in {
        position: relative;
        width: 16px;
        height: 16px;
        color: #333333;

        &:before {
            content: '\F108';
            font-family: 'Ionicons';
            position: absolute;
            top: -4px;
            right: 2px;
            transform: rotate(-45deg);
            font-size: 12px;
        }

        &:after {
            content: '\F108';
            font-family: 'Ionicons';
            position: absolute;
            bottom: -1px;
            left: -2px;
            transform: rotate(137deg);
            font-size: 12px;
        }
    }

    &-out {
        position: relative;
        width: 16px;
        height: 16px;
        color: #333333;

        &:before {
            content: '\F108';
            font-family: 'Ionicons';
            position: absolute;
            top: -6px;
            right: 2px;
            transform: rotate(135deg);
            font-size: 12px;
        }

        &:after {
            content: '\F108';
            font-family: 'Ionicons';
            position: absolute;
            bottom: 0px;
            left: 0px;
            transform: rotate(-48deg);
            font-size: 12px;
        }
    }
}

.group__button {
  display: flex;
  .a-btn {

    margin-left: 1px;
    margin-right: 1px;
border-radius: 4px;    &:first-child {
      margin-left: 0;
      }
    &:last-child {
      margin-right: 0;

    }
  }
}

.tgl {
    display: none;

    &,
    &:after,
    &:before,
    & *,
    & *:after,
    & *:before,
    &-btn {
        box-sizing: border-box;

        &::selection {
            background: none;
        }
    }

    &-btn {
        outline: 0;
        display: block;
        width: 40px;
        height: 20px;
        position: relative;
        cursor: pointer;
        user-select: none;

        &:after,
        &:before {
            position: relative;
            width: 50%;
            height: 100%;
            content: '';
            display: block;
        }

        &:after {
            left: 0;
            content: "\f00d";
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            color: hsla(218, 13%, 48%, 0.75);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 11px;
        }

        &:before {
            display: none;
        }
    }

    &:checked + .tgl-btn:after {
        left: 50%;
        content: "\F00C";
        font-family: 'Font Awesome 5 Free';
        color: #00875A;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 9px;
    }
}

.tgl-light {
    + .tgl-btn {
        background: hsla(218, 13%, 48%, 0.75);
        border-radius: 2em;
        padding: 2px;
        transition: all .4s ease;

        &.disable {
            pointer-events: none;
            opacity: .2;

            &:hover {
                cursor: default;
            }

            &:after {
                content: '';
            }
        }

        &:after {
            border-radius: 50%;
            background: #fff;
            transition: all .2s ease;
        }
    }

    &:checked + .tgl-btn {
        background: hsla(160, 100%, 26%, 0.9);
    }
}

.gtoogle {
    min-width: 40px;
    height: 20px;

    &::after {
        width: 16px;
        height: 16px;
    }

    &.ant-switch-checked {
        background-color: #00875A;
    }
}

.a-dropdown {
    &--set-field {
        width: 480px;
        @include media-breakpoint-down(md) {
            width: 384px;
        }

        &__menu {
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;
            padding: 20px 25px 50px 32px;

            .close-field-menu {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                margin-bottom: 15px;
            }
        }

        &__item {
            width: 50%;
            margin-bottom: 12px;
            padding: 0;

            &:hover {
                background-color: transparent;
            }
        }
    }
}

.ant-btn-primary {
    background-color: $color-button-primary;
    border: 1px solid $color-button-primary;

    &:hover {
        background-color: $color-button-primary;
        border: 1px solid $color-button-primary;
    }
}