/* ==========================================================================
   # START UTILITIES MARGIN
   ==========================================================================
*/
.m {
  &t {
    &-0 {
      margin-top: 0 !important;
    }
    &-1 {
      margin-top: (1rem * .25) !important;
    }
    &-2 {
      margin-top: (2rem * .25) !important;
    }
    &-3 {
      margin-top: (3rem * .25) !important;
    }
    &-4 {
      margin-top: (4rem * .25) !important;
    }
    &-5 {
      margin-top: (5rem * .25) !important;
    }
  }
  &b {
    &-0 {
      margin-bottom: 0 !important;
    }
    &-1 {
      margin-bottom: (1rem * .25) !important;
    }
    &-2 {
      margin-bottom: (2rem * .25) !important;
    }
    &-3 {
      margin-bottom: (3rem * .25) !important;
    }
    &-4 {
      margin-bottom: (4rem * .25) !important;
    }
    &-5 {
      margin-bottom: (5rem * .25) !important;
    }
  }
  &l {
    &-0 {
      margin-left: 0 !important;
    }
    &-1 {
      margin-left: (1rem * .25) !important;
    }
    &-2 {
      margin-left: (2rem * .25) !important;
    }
    &-3 {
      margin-left: (3rem * .25) !important;
    }
    &-4 {
      margin-left: (4rem * .25) !important;
    }
    &-5 {
      margin-left: (5rem * .25) !important;
    }
  }
  &r {
    &-0 {
      margin-right: 0 !important;
    }
    &-1 {
      margin-right: (1rem * .25) !important;
    }
    &-2 {
      margin-right: (2rem * .25) !important;
    }
    &-3 {
      margin-right: (3rem * .25) !important;
    }
    &-4 {
      margin-right: (4rem * .25) !important;
    }
    &-5 {
      margin-right: (5rem * .25) !important;
    }
  }
}
/* ==========================================================================
   # END UTILITIES MARGIN
   ==========================================================================
*/
.mt-2-md {
  @include a-respond-down(md) {
    margin-top: 2rem !important;
  }
}
.amt-24 {
  margin-top: 24px !important;
}
.mr-22 {
  margin-right: 22px !important;
}
.mt-02 {
  margin-top: 2px !important;
}