.agency-box {
	border: 1px solid #e5e5e5;
	border-radius: 3px;
	background: #fcfcfc;
	&-title {
		border-bottom: 1px solid #e5e5e5;
	}
	.group-line {
		+.group-line {
			border-top: 1px solid #e5e5e5;
		}
		.a--cursor-poiter {
			&:hover {
				color: #007bff;
			}
		}
		.blue-cycle {
			position: relative;
			display: inline-block;
			padding-left: 10px;
			&:before {
				content: "";
				position: absolute;
				top: calc(50% - 3px);
				left: 0;
				width: 6px;
				height: 6px;
				border-radius: 100%;
				background: #bbbbbb;
			}
			&.active:before {
				background: #00e427;
			}
		}
	}
}
.form-order-group {
	.required {
		label:before {
			content: "*";
			color: #ff0000;
			position: absolute; top: 0; right: 0;
		}
	}
}