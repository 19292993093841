.a-purchasod__block--bill__header {
	position: relative;
	z-index: 1;
}
.vote-shop {
	position: relative;
	.vote-list {
		display: none;
		position: absolute;
		top: calc(100%); left: 0;
		padding-top: 5px;
	}
	&:hover .vote-list {
		display: block;
	}
	a {
		display: inline-block;
		border: 1px solid #fff;
		border-radius: 3px;
		position: relative;
		margin: -1px 0;
		&.disliked {
			color: #444444;
			background: #fff3f3;
			border: 1px solid #ffdede;
			padding: 0 5px;
		}
	}
	ul {
		background: #fff;
		border: 1px solid #ddd;
		box-shadow: 1px 0 3px rgba(0,0,0,.1);
		z-index: 9;
		border-radius: 3px;
		width: 240px;
		&.sroller {
			overflow-y: scroll;
			height: 320px;
		}
		&:before {
			content: "";
			position: absolute;
			top: -5px; left: 25px;
			border-top: 5px solid transparent;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-bottom: 5px solid #dddddd;
		}
		li {
			margin-left: 0 !important;
			padding: 5px;
			white-space: normal;
			border-radius: 3px;
			&:hover {
				background: #f5f9ff;
			}
			+li {
				border-top: 1px dashed #ddd;
			}
		}
	}
}