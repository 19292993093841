@import "../../../../resources/scss/settings/setting.color";
@import "../../../../resources/scss/settings/setting.mixin";
.a-table {
  width: 100%;
  th {
    border-bottom: none;
    padding: 0;
    border-top: 0;
  }
  td {
    border-top: none;
  }
  thead {
    border-bottom: 1px solid #ebedf2 !important;
    th {
      border-bottom: none;
      text-transform: uppercase;
      color: #666666;
      font-weight: 500;
      text-align: left;
      font-size: 11px;
      padding-bottom: 15px;
      &:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    border-bottom: 1px solid #ebedf2 !important;
    color: black;
    @include a-respond-down(xs) {
      border-bottom: 0;
    }
    tr {
      height: 100%;
      border-bottom: 1px solid #ebedf2;
      @include a-respond-down(xs) {
        border-bottom: 0;
      }
      td {
        padding-bottom: 20px;
        padding-top: 8px;
        vertical-align: top;
        border-bottom: none ;
        color: #333333;
        @include a-respond-down(xs) {
          padding-bottom: 16px;
          padding-top: 16px;
        }
        &.max-width-300{
          max-width: 300px;
          word-break: break-word;
          @include a-respond-down(md){
            max-width: 100%;
          }
        }
        &:nth-child(1) {
          color: #333333;
          font-size: 13px;
        }
        &:nth-child(2) {
          color: #333333;
          font-size: 13px;
        }
        &:nth-child(3) {
          span {
            font-size: 10px;
            color: #999999;
          }
        }
        &:last-child {
          span {
            font-size: 12px;
            border-radius: 4px;
            padding: 1px 7px;
          }
          a {
            font-size: 14px;
            text-decoration: none;
            color: #0033FF !important;
          }
        }

      }
    }
  }
  &__data-column {
    display: flex;
    flex-direction: column;
  }
}

.a--user-group-btn {
  display: flex;
  align-items: center;
}

.a-table-responsive {
  @include a-respond-down(sm) {
    overflow: hidden;
    thead {
      display: block;
      tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }
    }
    tbody {
      display: block;
      tr {
        border-bottom: 2px solid  #666666;
        &:last-child {
          border-radius: 0 0 3px 3px;
          border-bottom: none;
        }
        td {
          border: none;
          border-bottom: 1px solid #ccc;
          position: relative;
          padding-left: 100px !important;
          width: 100%;
          overflow: hidden;
          word-break: break-word;
          padding-top: 15px;
          padding-bottom: 15px;
          &:last-child {
            border-bottom: none;
            span{
              margin-right: 10px;
              padding: 3px 7px;
            }
              a {
                margin-left: 15px;
              }
          }
          &:nth-child(4) {
            span{
              font-size: 12px;
            }
            &::before {
              top: 12px;
            }
          }
          &::before {
            content: attr(data-title);
            position: absolute;
            left: 0;
            width: 100px;
            white-space: nowrap;
            font-size: 13px;
            color:#888888;
          }
          &:last-child {
            text-align: left;
          }
        }
      }
    }
    th {
      display: block;
    }
    td {
      display: block;
    }
    tr {
      display: block;
    }
  }
}
