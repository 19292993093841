@import "../settings/setting.mixin";
@import "../settings/setting.color";

/*TEXT STATUS*/
.a-text {
    &-end {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }

    &--break-all-normal {
        word-break: break-all;
        white-space: normal;
    }

    &--row-center {
        display: flex;
        align-items: center;
    }

    &--top {
        vertical-align: top;
    }

    &-end {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }

    &--row-center {
        display: flex;
        align-items: center;
    }

    &--font-b {
        color: #b1afaf !important;
    }

    &--top {
        vertical-align: top;
    }

    &--font-primary {
        color: #333333 !important;
    }

    &--red {
        color: $color--red-c !important;
    }

    &--light-red {
        color: $border-color--redf;
    }

    &--green {
        color: $txt-color-primary !important;
    }

    &--bold {
        font-weight: bold !important;
    }

    &--right {
        text-align: right !important;
    }

    &--left {
        text-align: left;
    }

    &--center {
        text-align: center;
    }

    &--font-blue {
        color: $color--primary;
    }

    &--danger {
        color: #f0ad4e !important;
    }

    &--uppercase {
        text-transform: uppercase;
    }

    &--normal {
        text-transform: inherit !important;
    }

    &--break-all {
        word-break: break-all;
    }

    &--white {
        color: white !important;
    }

    &--copy {
        color: $txt-color-primary;
        font-size: 12px;
        font-weight: 700;

        i {
            margin-left: 15px;
            color: #999999;

            &:hover {
                cursor: pointer;
            }
        }

        &.copy-hover {
            display: flex;

            i {
                display: none;

            }

            &:hover {
                i {
                    display: block;
                }
            }
        }
    }

    &--history {
        font-size: 10px;
        color: #999999;

        i {
            margin-right: 3px;
        }
    }

    &--money-refund {
        &-label {
            font-size: 14px;
            color: #999999;
        }

        &-amount {
            font-size: 15px;
            color: #CC0000;
        }
    }

    &--green-new {
        color: $txt-color-primary !important;
        &:visited {
            color: $txt-color-primary !important;
        }
        &:hover {
            opacity: .9;
        }
    }

    &--nowrap {
        white-space: nowrap !important;
    }

    &--decoration-underline {
        text-decoration: underline;
    }

    &--success {
        color: #00AB1E !important;;
    }

    &--warning {
        color: #ffc107;
    }

    &--error {
        color: $color--red-c
    }

    &--gray-new {
        color: rgba(0, 0, 0, .65) !important;
    }

    &--pointer {
        &:hover {
            cursor: pointer;
        }
    }
}

/*STATUS*/

.a-status-border {
    &--WAITING_BUY {
        border-left: 4px solid #8241d7;
    }

    &--WAITING, &--QUEUED {
        border-left: 4px solid #666666;
    }

    &--wating-buy, &--wating {
        border-left: 4px solid #666666;
    }

    &--wating-pai, &--WAITING_PAI {
        border-left: 4px solid #FFD900;
    }

    &--processing, &--PROCESSING {
        border-left: 4px solid #FFD900;
    }

    &--paid, &--PAID {
        border-left: 4px solid #00AB1E;
    }

    &--withdraw {
        border-left: 4px solid #666666;
    }

    &--cancelled, &--CANCELLED {
        border-left: 4px solid #f00;
    }

    &--out-of-stock, &--OUT_OF_STOCK, &--REVOKED, &--revoked, &--failure, &--FAILURE {
        border-left: 4px solid #f00;
    }

    &--wating-pay, &--WAITING_PAY, &--WAITING_RETURN {
        border-left: 4px solid #ffbd00;
    }

    &--bought, &--BOUGHT {
        border-left: 4px solid #00db9c;
    }

    &--after-bought, &--AFTER_BOUGHT {
        border-left: 4px solid #b9b9b9;
    }

    &--overdue-purchase, &--OVERDUE_PURCHASE {
        border-left: 4px solid #ff2725;
    }

    &--seller-delivery, &--SELLER_DELIVERY {
        border-left: 4px solid #00ff9d;
    }

    &--received-from-seller, &--RECEIVED_FROM_SELLER, &--RETURNED {
        border-left: 4px solid #00e427;
    }

    &--success, &--SUCCESS {
        border-left: 4px solid #ff2725;
    }

    &--cross-checking, &--CROSS_CHECKING {
        border-left: 4px solid #00ab1e;
    }
}

.a-status {
    border-radius: 4px;
    padding: 2px 5px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    &--is-new {
        border-radius: 3em !important;
        line-height: 19px !important;
        padding: 1px 15px 0 15px !important;
        font-weight: normal;
        text-transform: capitalize;
    }
    &--none-status-color {
        background: white !important;
        border: gray 1px solid !important;
        color: black !important;
        opacity: 0.3 !important;
    }

    &--WAITING_BUY {
        background-color: #8241d7;
        border: 1px solid #fff;
        color: #fff;
    }

    &--WAITING, &--QUEUED {
        background-color: white;
        border: 1px solid #999999;
        color: #666666;
    }

    &--wating-buy, &--wating {
        background-color: white;
        border: 1px solid #999999;
        color: #666666;
    }

    &--wating-pai, &--WAITING_PAI {
        background-color: #FFD900;
        color: white;
        border: 1px solid #FFD900;
    }

    &--processing, &--PROCESSING {
        background-color: #FFD900;
        color: white;
        border: 1px solid #FFD900;
    }

    &--paid, &--PAID {
        border: 1px solid #00AB1E;
        background-color: #00AB1E;
        color: white;
    }

    &--withdraw {
        border: 1px solid #666666;
        background-color: #666666;
        color: white;
    }

    &--cancelled, &--CANCELLED {
        border: 1px solid #f00;
        background-color: #f00;
        color: white;
    }

    &--out-of-stock, &--OUT_OF_STOCK, &--REVOKED, &--revoked, &--failure, &--FAILURE {
        border: 1px solid #f00;
        background-color: #f00;
        color: white;
    }

    &--wating-pay, &--WAITING_PAY, &--WAITING_RETURN {
        border: 1px solid #ffbd00;
        background-color: #ffbd00;
        color: white;
    }

    &--bought, &--BOUGHT {
        border: 1px solid #00db9c;
        background-color: #00db9c;
        color: #666666;
    }

    &--after-bought, &--AFTER_BOUGHT {
        border: 1px solid #fff;
        background-color: #b9b9b9;
        color: #fff;
    }

    &--overdue-purchase, &--OVERDUE_PURCHASE {
        border: 1px solid #fff;
        background-color: #ff2725;
        color: #fff;
    }

    &--seller-delivery, &--SELLER_DELIVERY {
        border: 1px solid #00ff9d;
        background-color: #00ff9d;
        color: #666666;
    }

    &--received-from-seller, &--RECEIVED_FROM_SELLER, &--RETURNED {
        border: 1px solid #00e427;
        background-color: #00e427;
        color: white;
    }

    &--success, &--SUCCESS {
        border: 1px solid #00e427;
        background-color: #00e427;
        color: white;
    }

    &--cross-checking, &--CROSS_CHECKING {
        border: 1px solid #00ab1e;
        background-color: #00ab1e;
        color: white;
    }

    &--TRANSPORTING, &--WAITING_DELIVERY, &--DELIVERING, &--DELIVERED {
        border: 1px solid #72ac4d;
        background-color: #72ac4d;
        color: white;
    }

    &--active-status-order {
        //color: white;
        //background-color: #0050B3;
    }
}

/*LABEL*/
.a-label {
    font-weight: 500;

    &--success {
        background-color: #519a67;
        color: white;
        padding: 1px 8px;
        font-weight: 500;
        border-radius: 7px;
        font-size: 12px;
    }

    &--danger {
        @extend .a-label--success;
        background-color: #ce3c32;
    }

    &--default {
        border: 1px solid $color--gray-9;
        @extend .a-label--success;
        background-color: white;
        color: $color--font-primary;
    }

    &--warning {
        @extend .a-label--success;
        border: 1px solid $color--warning;
        background-color: $color--warning;
    }

    &--gray {
        @extend .a-label--success;
        border: 1px solid rgba(153, 153, 153, 1);
        background-color: rgba(153, 153, 153, 1);
    }
}

/*BOXQUOTE*/
.a-box-quote {
    padding-left: .5rem;
    border-left: 4px solid #047bf8;

    &-title {
        &--success {
            color: #28a745;
        }

        &--danger {
            color: #dc3545;
        }

        &--warning {
            color: #ffc107;
        }
    }

    &--success {
        border-left-color: #5eb314;
    }

    &--danger {
        border-left-color: #e65252;
    }

    &--warning {
        border-left-color: #fbe4a0;
    }
}

//Theo giao dien moi tu luc su dung ant
.a-text {

    &--fz-10 {
        font-size: 10px !important;
    }

    &--fz-11 {
        font-size: 11px !important;
    }

    &--fz-12 {
        font-size: 12px !important;
    }

    &--fz-13 {
        font-size: 13px !important;
    }

    &--fz-14 {
        font-size: 14px !important;
    }

    &--fz-16 {
        font-size: 16px !important;
    }

    &--fz-18 {
        font-size: 18px !important;
    }

    &--fz-20 {
        font-size: 20px !important;
    }

    &--fz-22 {
        font-size: 22px !important;
    }

    &--color-primary {
        color: $txt-color-primary !important;
    }

    &--color-black {
        color: #0F0F0F !important;
    }

    &--color-black-45 {
        color: rgba(0, 0, 0, .45) !important;
    }

    &--color-black-30 {
        color: rgba(0, 0, 0, .30) !important;
    }

    &--color-black-65 {
        color: rgba(0, 0, 0, .65) !important;
    }

    &--color-black-85 {
        color: rgba(0, 0, 0, .85) !important;
    }

    &--color-warning {
        color: #FF0000 !important;
    }

    &--color-green {
        color: #52C41A !important;
    }

    &--w-medium {
        font-weight: 500 !important;
    }

    &--underline {
        text-decoration: underline !important;
    }

    &--pointer {
        &:hover {
            cursor: pointer;
        }
    }

    &--green-dark {
        color: #006600 !important;
    }

    &--yellow {
        color: #ebe534 !important;
    }
    &--hover-underline {
        &:hover {
            text-decoration: underline;
        }
    }
}
.text-primary {
    color: $txt-color-primary !important;
}

.text-delete{
     color: red;
     &:hover{
        color:rgb(233, 99, 99) ;
     }
}