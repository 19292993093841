/* ==========================================================================
   # START COMPONENTS COPY
   ==========================================================================
*/
.content-copy {
  p {
    span {
      display: none;
    }
    &:hover {
      cursor: pointer;
      span {
        display: inline-block;
      }
    }
  }
  .content-btn-copy {
    position: relative;
    .has-copied {
      position: absolute;
      margin: auto;
      margin-top: -30px;
      float: left;
      left: 0;
      width: 100px;
      opacity: 0.8;
      font-weight: 100;
    }
    .hidden-copied {
      -webkit-animation: cssAnimation 5s forwards;
      animation: cssAnimation 5s forwards;
      @keyframes cssAnimation {
        0% {
          opacity: 1;
        }
        90% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
      @-webkit-keyframes cssAnimation {
        0% {
          opacity: 1;
        }
        90% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    }
  }
}

/* ==========================================================================
   # END COMPONENTS COPY
   ==========================================================================
*/
