@import "../settings/setting.mixin";
@import "../settings/setting.color";
/* ==========================================================================
   # START COMPONENTS 403
   ==========================================================================
*/
.a-403 {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  height: calc(100vh - 200px);
  .icon-forbidden {
    font-size: 200px;
  }
  &--title {
    color: #3f4752;
    text-transform: uppercase;
    margin-left: 20px;
    i {
      font-size: 80px;
    }
    h1 {
      font-size: 100px;
      color: black;
      font-weight: bold;
    }
    p {
      font-size: 18px;
      color: #999;
    }
    .a-btn--redirect_home {
      width: 150px;
      background-color: #2A7EBA;
      color: white;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;
      text-decoration: none;
      text-transform: uppercase;
      span {
        font-size: 13px;
      }
      &:focus {
        outline: none;
        border: none;
      }
      &:hover {
        background-color: #216290;
        cursor: pointer;
      }
    }
  }
}
/* ==========================================================================
   # END COMPONENTS 403
   ==========================================================================
*/

/* ==========================================================================
   # START COMPONENTS 404
   ==========================================================================
*/
.a-404 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 200px);
  .infor {
    display: flex;
    flex-direction: column;
  }
  &--title {
    display: flex;
    color: #3f4752;
    i {
      font-size: 80px;
    }
    h1 {
      font-size: 80px;
      margin-left: 50px;
    }
  }
  p {
    font-size: 18px;
    color: #999;
  }
  .a-btn--redirect_home {
    width: 100%;
    background-color: #2A7EBA;
    color: white;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    text-decoration: none;
    text-transform: uppercase;
    span {
      margin-right: 35%;
      font-size: 13px;
    }
    &:focus {
      outline: none;
      border: none;
    }
    &:hover {
      background-color: #216290;
    }
  }
}
/* ==========================================================================
   # END COMPONENTS 404
   ==========================================================================
*/

/* ==========================================================================
   # START COMPONENTS NOTIFY
   ==========================================================================
*/
.t-notify{
  &-number{
    color: #285acc;
    font-size: 10px;
    font-weight: 700;
  }
  &-block{
    position:absolute;
    right: 15px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    border-width: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -18px;
    box-shadow: 0 0 10px rgba(40, 90, 204, 0.43);
    &.collapsed{
      right:4px;
      margin-top: -26px;
    }
  }
}

/* ==========================================================================
   # END COMPONENTS NOTIFY
   ==========================================================================
*/

.a-block__boxshadow{
  background-color: white;
  border: 1px solid #f2f2f2;
  border-radius: 3px;
  -moz-box-shadow: 0px 3px 5px rgba(228, 228, 228, 0.3);
  -webkit-box-shadow: 0px 3px 5px rgba(228, 228, 228, 0.3);
  box-shadow: 0px 3px 5px rgba(228, 228, 228, 0.3);
}

/* ==========================================================================
   # START COMPONENTS NOTFOUNT
   ==========================================================================
*/
.a-notfound {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
/* ==========================================================================
   # END COMPONENTS NOTFOUNT
   ==========================================================================
*/
