/* ==========================================================================
   # START UTILITIES FONT
   ==========================================================================
*/
.a-font {
  &--10 {
    font-size: 10px !important;
  }
  &--12 {
    font-size: 12px !important;
  }
  &--11 {
    font-size: 11px !important;
  }
  &--14 {
    font-size: 14px !important;
  }
  &--18 {
    font-size: 18px !important;
  }
}
/* ==========================================================================
   # END UTILITIES FONT
   ==========================================================================
*/