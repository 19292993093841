@import "../settings/setting.mixin";
@import "../settings/setting.color";

/* ==========================================================================
   # START COMPONENTS LOADING
   ==========================================================================
*/
.a-loading {
  &--service {
    width: 16px;
    height: 16px;
    @include a-respond-down(xl) {
      width: 24px;
      height: 24px;
    }
  }
  &__page{
    position: absolute;
    top: 70px;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    text-align: center;
    @include a-respond-down(md) {
      top: 51px;
    }
    @include a-respond-down(sm) {
      top:46px;
    }
    &__icon{
      height: 3px;
      width: 100%;
      position: relative;
      overflow: hidden;
      background-color: #ddd;
      &::before{
        display: block;
        position: absolute;
        content: "";
        left: -200px;
        width: 200px;
        height: 3px;
        background-color: rgba(0, 123, 255, 0.34);
        animation: loading 2s linear infinite;
      }
    }
  }
}

/* ==========================================================================
   # END COMPONENTS LOADING
   ==========================================================================
*/
