/* ==========================================================================
   # START COMPONENTS HELP
   ==========================================================================
*/
.a-help {
  &--title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    i {
      font-size: 40px;
    }
    h1 {
      font-size: 35px;
      margin-left: 10px;
    }
  }
  &--body {
    ul {
      margin-top: 10px;
      li {
        margin-bottom: 10px;
        a {
          text-decoration: none;
          -webkit-transition: ease all .3s;
          -moz-transition: ease all .3s;
          -ms-transition: ease all .3s;
          -o-transition: ease all .3s;
          transition: ease all .3s;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

/* ==========================================================================
   # END COMPONENTS HELP
   ==========================================================================
*/
