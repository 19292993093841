@import "../settings/setting.mixin";
@import "../settings/setting.color";

.a-group {
    width: 100%;
    margin-bottom: 1rem;

    &--inline {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;

        &-end {
            justify-content: flex-end;
        }
    }

    input {
        width: 100%;
        padding-left: 10px;
        border: 1px solid $color--border;
        border-radius: 4px;
        font-size: 13px;
        padding-right: 10px;

        &:focus {
            box-shadow: none;
            outline: none;
            background-color: #fff;
            border-color: #80bdff;
        }
    }

    &.complaint__seller {
        input {
            height: 30px;
        }
    }

    textarea {
        border-color: $color--border;
        border-radius: 4px;
    }

    &--horizon {
        display: flex;

        &__left {
            width: 50%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        &__right {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            input {
                margin-top: 0;
                padding-left: 10px;
                margin-left: 10px;
                width: 100px;
                height: 30px !important;
                font-size: 13px;

                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    font-size: 13px;
                    font-weight: 400;
                }

                &::-moz-placeholder { /* Firefox 19+ */
                    font-size: 13px;
                    font-weight: 400;
                }

                &:-ms-input-placeholder { /* IE 10+ */
                    font-size: 13px;
                    font-weight: 400;
                }

                &:-moz-placeholder { /* Firefox 18- */
                    font-size: 13px;
                    font-weight: 400;
                }
            }
        }
    }
}

.label {
    padding-right: 10px;
    color: #666;
    font-weight: 500;

    &--required {
        position: relative;

        &::before {
            position: absolute;
            top: 0;
            right: 0;
            content: '*';
            color: red;
        }
    }
}

.a-form {
    &--dropdown {
        &--order {
            padding: 10px 7px;
            margin-top: -4px !important;
            margin-bottom: -4px !important;

            label {
                color: #333333;
                font-size: 15px;

                span {
                    color: #333333;
                    font-size: 15px;
                }
            }
        }

        &--special {
            width: 285px;
        }

        &--atlaskit {
            min-width: 260px;
            margin-top: -4px;
            margin-bottom: -4px;
            padding: 10px;
            overflow-y: inherit !important;

            &::-webkit-scrollbar {
                width: 2px;

                &:horizontal {
                    height: 2px;
                }
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #999999;
                border-radius: 10px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #999999;
                cursor: pointer;
            }

            &__block {
                padding-bottom: 15px;

                &__title {
                    font-weight: 700;
                    padding: 0px 5px 5px 0;

                    p {
                        font-size: 15px;
                    }
                }

                &.select_time {
                    div[role=presentation] {
                        div {
                            div {
                                > div {
                                    min-height: 35px !important;
                                    border-width: 1px;

                                    div {
                                        min-height: auto !important;

                                        &:last-child {
                                            min-height: auto !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &--input {
        &--order {
            position: relative;

            i {
                position: absolute;
                top: 28px;
                right: 9px;
                font-size: 18px;
                color: #666666;
            }

            input {
                border: 1px solid $color--border;
                margin-top: 0;
                height: 30px !important;
                padding-left: 10px;
                padding-right: 30px;
                width: 100%;
                border-radius: 4px;
                box-sizing: border-box;

                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    font-size: 13px;
                }

                &::-moz-placeholder { /* Firefox 19+ */
                    font-size: 13px;
                }

                &:-ms-input-placeholder { /* IE 10+ */
                    font-size: 13px;
                }

                &:-moz-placeholder { /* Firefox 18- */
                    font-size: 13px;
                }

                &:focus {
                    outline: none;
                }
            }

            &__title {
                font-weight: 500;
                color: #333333;
                width: 100%;
                padding-left: 15px;
                margin-bottom: 6px;
            }

            .dNLJvp {
                background-color: white;
                border: 1px solid #CCCCCC;
            }
        }

        &--border-b {
            border-bottom: 1px solid $border-color--grayd8;

        }

        &__inline {
            display: flex;

            &__complaint_solution {
                &:not(:last-child) {
                    margin-bottom: 8px;
                }

                display: flex;

                label {
                    width: 20%;
                    margin-right: 20px;
                    color: #666666;
                    text-align: right;
                    @include a-respond-only(md, lg) {
                        width: 30%;
                    }
                }

                p {
                    width: 80%;
                    word-break: break-word;
                    font-weight: 500;
                    @include a-respond-only(md, lg) {
                        width: 70%;
                    }
                }
            }

            input {
                width: 100%;
                height: 30px;
                color: #495057;
                padding-left: 15px;
                border: 1px solid $color--border;
                border-radius: 2px;
                box-sizing: border-box;
                font-size: 13px;

                &:focus {
                    box-shadow: none;
                    outline: none;
                    background-color: #fff;
                    border-color: #80bdff;
                }
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 28px;
                margin-left: -1px;
                padding-left: 15px;
                padding-right: 15px;
                font-weight: 500;
                font-size: 13px;
                font-style: normal;
                border: 1px solid $color--border;
                color: #333333 !important;
                text-decoration: none;
                background-color: #e4e4e4 !important;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                border-radius: 0 2px 2px 0;
                text-transform: uppercase;

                &:hover {
                    background-color: #d2d2d2 !important;
                }
            }
        }
    }

    &__inline_table {
        &__complaint_solution {
            &:not(:last-child) {
                margin-bottom: 8px;
            }

            display: flex;

            label {
                width: 40%;
                margin-right: 20px;
                color: #666666;
                text-align: right;
                @include a-respond-only(md, lg) {
                    width: 40%;
                    white-space: nowrap;
                }
            }

            p {
                width: 80%;
                min-width: 285px;
                word-break: break-word;
                font-weight: 500;
                @include a-respond-only(md, lg) {
                    width: 60%;
                }
            }
        }
    }

    &__inline {
        display: flex;

        input {
            width: 100%;
            height: 28px;
            color: #495057;
            padding-left: 15px;
            border: 1px solid $color--border;
            border-radius: 2px;
            box-sizing: border-box;
            font-size: 13px;

            &:focus {
                box-shadow: none;
                outline: none;
                background-color: #fff;
                border-color: #80bdff;
            }
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 28px;
            margin-left: -1px;
            padding-left: 15px;
            padding-right: 15px;
            font-weight: 500;
            font-size: 13px;
            font-style: normal;
            border: 1px solid $color--border;
            color: #333333 !important;
            text-decoration: none;
            background-color: #e4e4e4 !important;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0 2px 2px 0;
            text-transform: uppercase;

            &:hover {
                background-color: #d2d2d2 !important;
            }
        }
    }

    &--ant-item {
        .ant-form-item-label {
            line-height: 24px;

            label {
                font-size: 12px;
                color: rgba(0,0,0,.65);
                line-height: 16px;
                &::after{
                    content: '';
                }
            }
        }
        .a-group--input{
            margin-top: 4.5px;
        }
    }
  &__block_input{
    position: relative;
    textarea{
      width: 100%;
      color: #495057;
      padding-left: 15px;
      border: 1px solid $color--border;
      border-radius: 4px;
      box-sizing: border-box;
      font-size: 13px;
      &:focus {
        box-shadow: none;
        outline: none;
        background-color: #fff;
        border-color: #80bdff;
      }
    }
    input{
      width: 100%;
      color: #495057;
      padding-left: 15px;
      border: 1px solid $color--border;
      border-radius: 4px;
      box-sizing: border-box;
      font-size: 13px;
      &:focus {
        box-shadow: none;
        outline: none;
        background-color: #fff;
        border-color: #80bdff;
      }
    }
    &__action{
      position: absolute;
      top: calc(100% - 4px);
      right: 0;
      display: flex;
      padding: 1px;
      box-shadow: 0 0 16px rgba(0,0,0,.05);
      -webkit-box-shadow: 0 0 16px rgba(0,0,0,.05);
      -moz-box-shadow: 0 0 16px rgba(0,0,0,.05);
      -o-box-shadow:0 0 16px rgba(0,0,0,.05);
      .action__save{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 25px;
        font-size: 16px;
        background-color: white;
        color: #333333;
        border: 1px solid #CCCCCC;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
      }
      .action__cancel{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 25px;
        font-size: 16px;
        margin-left: 2px;
        background-color: white;
        color: #333333;
        border: 1px solid #CCCCCC;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
      }
    }
  }

    &__group {
        display: flex;
        flex-direction: column;
        padding-bottom: 14px;
        &__label {
            margin-bottom: 4px;
            font-size: 12px;
            line-height: 16px;
            color: #898989;
        }
        &__value {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 19px;
            color: #262626;

        }
        &--title-order {
            display: flex;
            img {
                width: 40px;
                height: 40px;
                margin-right: 12px;
            }
        }
    }
}

.a-form--dropdown--atlaskit {
    overflow-y: auto;

}

.a-form {
    &--antd {
        &__item {
            margin-bottom: 16px;
            .ant-form-item-label {
                line-height: 16px;
                label {
                    font-size: 12px;
                    line-height: 16px;
                    color: rgba(0, 0, 0, .65);
                }
            }
        }
    }
}
