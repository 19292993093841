/* ==========================================================================
   # START COMPONENTS CHAT
   ==========================================================================
*/
.a-chat {
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  border: 1px solid rgba(242, 242, 242, 1);
  border-radius: 3px;
  -moz-box-shadow: 0px 3px 5px rgba(228, 228, 228, 0.349019607843137);
  -webkit-box-shadow: 0px 3px 5px rgba(228, 228, 228, 0.349019607843137);
  box-shadow: 0px 3px 5px rgba(228, 228, 228, 0.349019607843137);
  .react-file-reader-input {
    display: none;
  }
  &__picture {
    position: relative;
    height: 26px;
    display: flex;
    align-items: center;
    &:hover {
      .a-chat__picture__option {
        display: block;
      }
    }
    &__option {
      display: none;
      position: absolute;
      top: 22px;
      right: 0;
      flex-wrap: wrap;
      padding: 5px 10px 0 10px;
      background-color: white;
      -webkit-box-shadow: -6px 1px 15px 1px rgba(69, 65, 78, 0.08);
      box-shadow: -6px 1px 15px 1px rgba(69, 65, 78, 0.08);
    }
    &__item {
      display: flex;
      width: 100%;
      margin-bottom: 5px;
      &:hover {
        a {
          color: #666666;
        }
      }
      a {
        white-space: nowrap;
        color: #333333;
        text-decoration: none;
        font-size: 11px;
        -webkit-transition: ease all .2s;
        -moz-transition: ease all .2s;
        -ms-transition: ease all .2s;
        -o-transition: ease all .2s;
        transition: ease all .2s;
      }
    }
  }
  &:last-child {
    @include a-respond-down(md) {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
  &__group {
    .label-checkbox {
      font-size: 10px;
      color: #999999;
      font-weight: 700;
      margin-top: 2px;
      margin-left: -8px;
    }
    .a-upload__image {
      color: #666666;
    }
    &-ml--5 {
      margin-left: -5px;
    }
  }
  &__top {
    margin-bottom: 5px;
    @include a-respond-down(xl) {
      padding: 10px 10px 0 10px;
    }
  }
  &__filter {
    padding: 5px 10px;
    border-bottom: 1px solid #d5d5d5;
  }
  &__form_comment {
    padding: 10px;
    border-bottom: 1px solid #d5d5d5;
    flex-direction: column !important;
    position: relative;
    background: #fafafa;
  }

  &__purchasod {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100vh;
    .a-chat {
      height: calc(50% - 6px);
      &:last-child {
        margin-top: 12px;
      }
    }
  }
  &__body {
    overflow-y: scroll;
    padding: 0 10px 0 10px;
    transition: linear all .5s;
    margin-top: 5px;
    &.private {
      height: calc(100% - 80px);
    }
    &.public {
      height: calc(100% - 125px);
    }
    &.complaint-seller {
      height: calc(100vh - 90px);
      max-height: calc(100vh - 90px);
    }
    &::-webkit-scrollbar {
      width: 4px;
      &:horizontal {
        height: 4px;
      }
    }
    &::-webkit-scrollbar-thumb {
      background: #999999;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #999999;
      cursor: pointer;
    }
    &.collapsed {
      height: calc(100vh - 450px);
      max-height: calc(100vh - 450px);
      transition: linear all .5s;
    }
  }
  &__switch_action {
    display: flex;
    margin-bottom: 3px;
    &__button {
      width: 50%;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      background-color: #f2f2f2;
      font-weight: 500;
      font-size: 11px;
      color: #666666;
      border: 1px solid #f2f2f2;
      &:focus {
        color: #666666;
      }
      &.left {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      &.right {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
      &.active {
        background-color: #007bff;
        border: 1px solid #007bff;
        color: white;
      }
    }
  }
  &__block {
    border-radius: 16px;
    margin-bottom: 8px;
    clear: both;
    &.system {
      opacity: .6;
      .a-chat__info--author {
        color: grey !important;
      }
    }
  }
  &__comment {
    background: #f2f8fb;
    border-radius: 5px;
    padding: 5px;
    &.customer {
      background: #f7f7f7;
    }
    p {
      word-break: break-word;
      font-weight: 400;
      color: #111111;
      font-size: 12px;
      line-height: 18px;
      padding: 2px 0px;
      &.system {
        opacity: .6;
      }
      a {
        color: $txt-color-primary;
        font-weight: bold;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &__info {
    &--author {
      color: rgba(0,0,0,.45);
      font-size: 12px;
      &.system {
        color: #666666;
      }
      &.customer {
        position: relative;
        padding-left: 20px;
        img {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    &--icon-customer{
      i {
        color: #ff9a00;
      }
    }
    &--customer-icon {
      max-width: 16px;
      margin-right: 3px;
    }
    &--content{
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      margin-bottom: 5px;
    }
    &--avatar {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      float: left;
      margin-top: 5px;
      font-size: 32px;
      color: #cce5ff;
      img {
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
      }
    }
    &--time {
      color: #999999;
      font-size: 10px;
      span {
        margin-left: 5px;
        color: #c7c7c7;
      }
    }
  }
  &__icon {
    &--public {
      color: $txt-color-primary;
    }
    &--private {
      color: #CA5201;
    }
  }
  &--content--collapsed {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    color: $txt-color-primary;
    font-weight: 400;
    text-decoration: underline;
    font-size: 12px;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  &__input {
    position: relative;
    width: 100%;
    &--chat {
      background: #fff !important;
      border-radius: 0px !important;
      border: 1px solid #e0e0e0 !important;
    }
    &--file {
      position: absolute;
      right: 2px;
      top: 3px;
    }
    &--label {
      width: 100%;
      font-weight: bold;
      margin-bottom: 5px;
    }
    &__attach_file {
      position: absolute;
      top: 4px;
      right: 10px;
      height: 15px;
      &__selected {
        border: 1px solid #f18383;
      }
      &__icon_close {
        font-size: 9px;
        position: absolute;
        right: -6px;
        top: -3px;
        cursor: pointer;
      }
    }
    &__icon_loading {
      position: absolute;
      top: 0;
      right: 20px;
      vertical-align: middle;
      svg {
        width: 16px;
        height: 16px;

        position: absolute;
        top: 3px;
        right: 3px;
      }
    }
  }
}

/* ==========================================================================
   # END COMPONENTS CHAT
   ==========================================================================
*/
