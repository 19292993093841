@import "../settings/setting.mixin";
@import "../settings/setting.color";

.a-table {
  display: table;
  width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border-collapse: collapse;
  text-align: left;

  thead {
    tr {
      th {
        position: relative;
        padding: 16px 16px 13px 16px;
        font: {
          size: 12px;
          weight: 400;
        }
        white-space: nowrap;
        border-bottom: .1rem solid #E8E8E8;
        background-color: #F5F5F5;
        color: rgba(0, 0, 0, .85);

        .sort {
          position: absolute;
          top: 50%;
          display: flex;
          flex-direction: column;
          right: 6px;
          width: 14px;
          height: 17px;
          margin-top: -8.5px;
          color: #BFBFBF;
          -webkit-transition: all .3s;
          transition: all .3s;

          i {
            &:not(:first-child) {
              margin-top: .4rem;
            }

            font-size: 12px;
            display: block;
            height: .4rem;
            line-height: .4rem;
            -webkit-transition: all .3s;
            transition: all .3s;

            &.active {
              color: blue;
            }
          }

          &:hover {
            cursor: pointer;
          }
        }

        &:first-child {
          &.sticky-left {
            position: sticky !important;;
            background-color: #f5f5f5;
            left: 0;
            z-index: 10;
          }
        }

        &:last-child {
          &.sticky-right {
            position: sticky !important;;
            background-color: #f5f5f5;
            right: 0;
            z-index: 10;
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 16px 16px 13px 16px;
        font: {
          size: 14px;
        }
        color: rgba(0, 0, 0, .85);
        border-bottom: .1rem solid #E8e8e8;
        transition: all .3s, border 0s;
      }

      &:hover {
        td {
          background-color: #f8f8f8;
        }
      }
    }
  }

  &__scroll-x {
    overflow-x: auto;
    @include a-respond-down(md) {
      padding-bottom: 10px;
    }

    &::-webkit-scrollbar {
      width: 3px;

      &:horizontal {
        height: 3px;
      }
    }

    &::-webkit-scrollbar-thumb {
      background: #e2e5ec;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #e2e5ec;
      cursor: pointer;
    }
  }

  &--order_bill_no_sync {
    thead {
      tr {
        th {
          text-align: left;
          font-size: 12px;
          color: #666666;
          text-transform: uppercase;
          border-top: 0;
          border-bottom: 0;

          &:first-child {
            width: 24px;
            padding: 0.6rem 0;
          }

          @include a-respond-down(sm) {
            white-space: nowrap;
          }
        }
      }
    }

    tbody {
      tr {
        &.not-border {
          td {
            border: 0 !important;
          }
        }

        td {

          &:first-child {
            width: 24px;
            padding: 7px 0 0px 0;
          }

          &:nth-child(4) {
            width: calc(27% - 24px);
          }

          &:nth-child(2) {
            width: 25%;
          }

          &:nth-child(3) {
            width: 23%;
            @include a-respond-down(sm) {
              white-space: nowrap;
            }
          }

          &:nth-child(5) {
            width: 25%;
          }

          .code__order {
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
            text-decoration: none;
          }

          &:last-child {
            a {
              color: #0000FF;
              font-size: 14px;
              text-decoration: none;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  &--sale_figures {
    td {
      border: 0px solid #E0E0E0;
      border-bottom: .5px solid #dee2e6 !important;
    }

    th {
      border: 0.5px solid white;
      text-align: left;
      color: #414047;
      font-weight: 500;
      font-style: normal;
      border-bottom: .5px solid #dee2e6 !important;

      &:last-child {
        text-align: center;
      }
    }

    thead {
      tr {
        background-color: white;

      }
    }

    tbody {
      tr {
        td {
          color: #696969;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;

          &:first-child {
            a {
              text-decoration: none;
            }
          }

          &:last-child {
            text-align: center;
          }
        }

        &.not-download {
          background-color: rgba(0, 102, 255, 0.03);
        }
      }
    }
  }

  &--deal_alipay {
    margin-top: 10px;

    thead {
      tr {
        th {
          border: none;
          border-bottom: 1px solid #f2f2f2;
          text-align: left;
          text-transform: uppercase;
          font-size: 12px;
          color: #BCBCBC;
          white-space: nowrap;
        }
      }
    }

    tbody {
      tr {
        td {
          border: none;
          border-bottom: 1px solid #f2f2f2;
          font-size: 12px;
          color: #333333;

          &.check__complaint {
            padding-left: 9px;
            padding-right: 0;
            width: 16px;
            height: 16px;

            label {
              width: 16px;
              height: 16px;
              margin-top: -6px;
              margin-left: -2px;
            }
          }

          &:nth-child(2) {
            span {
            }
          }
        }
      }
    }
  }

  &--complaint_solution {
    &__remove_package {
      color: $txt-color--gray3;
    }

    font-size: 13px;

    thead {
      tr {
        background-color: #2d7acc;
        color: $txt-color--white;

        th {
          text-align: left;
          border-bottom: 0;
        }
      }
    }

    tbody {
      tr {
        td {
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }

  &--list_complaint_solution {
    thead {
      tr {
        background-color: #ffffff;

        th {
          border-bottom: none;
          border-top: none;
          font-size: 12px;
          text-align: left;
          white-space: nowrap;
          padding: .5rem .75rem;

          &:nth-child(4) {
            padding-left: 12%;
            @include a-respond-down(xxl) {
              padding-left: 5%;
            }
            @include a-respond-only(xl, xxl) {
              padding-left: 10%;
            }
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-child(even) {
          background-color: #F9F9F9;
        }

        td {
          border-top: 1px solid #f2f2f2;
          padding: .5rem .75rem;

          &:nth-child(2) {
            white-space: nowrap;
          }

          &:nth-child(3) {
            p {
              margin-bottom: 4px;
            }

            span {
              margin-top: 5px;
              font-size: 12px;
              font-style: italic;
              color: #666666;
            }
          }

          &:last-child {
            display: flex;
            flex-wrap: wrap;

            a {
              &:not(:last-child) {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }

  &--responsive {
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 6px;

      &:horizontal {
        height: 6px;
      }
    }

    &::-webkit-scrollbar-thumb {
      background: #6c757d;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #6c757d;
      cursor: pointer;
    }
  }
  &--antd {

    table {
      thead {
        tr {
          th {
            white-space: nowrap;
            color: rgba(0, 0, 0, .85);
            padding: 14px 14px ;
            font-size: 14px;
            line-height: 19px;
            background-color: #F5F5F5;
            .sticky-left {
              position: sticky;
              background-color: transparent;
              left: 0;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            color: rgba(0, 0, 0, .85);
            padding: 14px 14px ;
            .a-action-hover-show {
              opacity: 0;
            }
          }

          &.row-show-item-hover {
            .action-row-show-hover {
              opacity: 0;
            }

            &:hover {
              .action-row-show-hover {
                opacity: 1;
                -webkit-animation: opacity-shadow .3s linear;
                -o-animation: opacity-shadow .3s linear;
                animation: opacity-shadow .3s linear;
              }
            }
          }

          &:hover {
            td {
              .a-action-hover-show {
                opacity: 1;
                -webkit-animation: opacity-shadow .3s linear;
                -o-animation: opacity-shadow .3s linear;
                animation: opacity-shadow .3s linear;
              }
            }
          }
        }
      }
    }

    &__small {
      table {
        thead {
          tr {
            th {
              padding: 8px 14px ;
              }
            }
          }
        tbody {
          tr {
            td {
              padding: 8px 14px ;
              }
            }
        }
      }
    }
  }
  &--description {
    table {
      tbody {
        border-bottom: 1px solid #e8e8e8;
        tr {
          td {
            border-bottom: 0;
            border-top: 1px solid #e8e8e8;
            vertical-align: top;
            .description-trade {
              //padding-left: 28px;
              padding-bottom: 10px;
            }
          }

          &.ant-table-expanded-row {
            background-color: white;
            td {
              padding: 0 16px 0 14px;
              border-top: 0;
            }
          }
          &:hover {
            $b1 :attr(data-row-key);

          }
        }
      }
    }
  }

  &--small-row {
    table {
      thead {
        tr {
          th {
            padding-top: 8px;
            padding-bottom: 8px;
            font-size: 12px;
          }
        }
      }

      tbody {
        tr {
          td {
            padding-top: 8px;
            padding-bottom: 8px;
            font-size: 12px;
          }
        }
      }
    }
  }
  &--account-employee {
    table {
      tbody {
        tr {
          td {
          }
        }
      }
    }
  }
}

.ant-table-placeholder {
  border-bottom: none;
}
.ant-table-thead > tr > th .ant-table-column-sorter-up.on, .ant-table-thead > tr > th .ant-table-column-sorter-down.on {
  color: $color--primary;
}

.ant-table-scroll {
  @include scrollcustom;
  .ant-table-body {
    @include scrollcustom;
  }
}
