@import "./../settings/setting.mixin";
@import "./../settings/setting.color";

/* ==========================================================================
   # START COMPONENTS SIDEBAR
   ==========================================================================
*/

.a_sidebar {
  font-size: 14px;
  @include a-respond-up(md) {
    width: 218px;
    height: 100%;
  }
  @include a-respond-down(md) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vh;
  }
  .a_sidebar__fixed {
    background-color: $color--primary;
    @include a-respond-up(md) {
      height: 100vh;
      position: fixed;
      width: 218px;
      transition: ease all .3s;
      box-shadow: 0 0 46px rgba(0, 0, 0, 0.1);
    }
    @include a-respond-down(md) {
      height: 50px;
      width: 100%;
    }
  }
  &.collapsed {
    transition: ease all .3s;
    @include a-respond-up(md) {
      width: 60px;
    }
    @include a-respond-down(md) {
      width: 100vw;
      height: 50px;
      position: fixed;
      .a_sidebar__body {
        transform: translateX(-100%);
      }
    }
    .a_sidebar__toggle {
      &:hover {
        cursor: pointer;
        span {
          background-color: white;
          &:before, &:after {
            width: 100%;
            background-color: white;
          }
        }
      }
    }
    .a_sidebar__fixed {
      width: 60px;
      @include a-respond-down(md) {
        width: 100%;
      }
      @include a-respond-up(md) {
        .a_sidebar__logo {
          justify-content: center;
          img, h2 {
            display: none;
          }
        }
      }
    }
    .a_sidebar__body__group {
      position: relative;
      padding-bottom: 0;
      ul {
        display: none;
      }
    }
    .a_sidebar__item {
      display: flex;
      align-items: center;
      justify-content: center;
      &:after {
        display: none;
      }
      .notify__number {
        right: 1px;
        top: 13px;
      }
      .a_sidebar__link {
        @include a-respond-up(md) {
          justify-content: center;
          span {
            display: none;
          }
        }
      }
    }
  }
  .a_sidebar__logo {
    display: flex;
    align-items: center;
    height: 50px;
    background-color: $color--primary;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 3;
    @include a-respond-up(md) {
      justify-content: space-between;
    }
    @include a-respond-down(md) {
      padding-left: 60px;
    }
    img {
      width: 35px;
      height: 35px;
      object-fit: cover;
      border-radius: 50%;
      @include a-respond-up(md) {
        margin-left: 10px;
      }
    }
    h2 {
      font-size: 22px;
      margin-left: 5px;
      color: white;
    }
  }
  .a_sidebar__toggle {
    width: 50px;
    height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @include a-respond-down(md) {
      border-right: 1px solid rgba(255,255,255,.2);
      position: absolute;
      top: 0;
      left: 0;
    }
    @include a-respond-up(md) {
      &:hover {
        cursor: pointer;
        span {
          background-color: white;
          &:before, &:after {
            width: 100%;
            background-color: white;
          }
        }
      }
    }
    &.disable {
      pointer-events: none;
      opacity: .8;
    }
    span {
      display: block;
      position: relative;
      height: 1px;
      width: 26px;
      border-radius: 0px;
      background-color: rgb(244, 245, 247);
      -webkit-transition: all .3s ease;
      transition: all .3s ease;
      &:before {
        position: absolute;
        content: '';
        width: 50%;
        height: 1px;
        background-color: white;
        top: -7px;
        right: 0;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
      }
      &:after {
        position: absolute;
        content: '';
        width: 70%;
        height: 1px;
        background-color: white;
        top: 7px;
        right: 0;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
      }
    }
  }
  .a_sidebar__body {
    @include a-respond-up(md) {
      padding: 15px 0;
      height: calc(100vh - 80px);
    }
    @include a-respond-down(md) {
      height: calc(100vh - 50px);
      top: 50px;
      overflow: hidden;
      position: fixed;
      background-color: $color--primary;
      transform: translateX(0);
      transition: all .3s;
      border-top: 1px solid rgba(255,255,255,.1);
    }
    .a_sidebar__body__overflow {
      overflow-y: scroll;
      overflow-x: hidden;
      @include a-respond-up(md) {
        height: calc(100vh - 170px);
      }
      @include a-respond-down(md) {
        height: calc(100vh - 140px);
      }
      &::-webkit-scrollbar {
        width: 3px;
        &:horizontal {
          height: 3px;
        }
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(255,255,255,.4);
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: rgba(255,255,255,.6);
        cursor: pointer;
      }
      @include a-respond-down(md) {
        &::-webkit-scrollbar {
          width: 3px;
          &:horizontal {
            height: 3px;
          }
        }
        &::-webkit-scrollbar-thumb {
          background: #e2e5ec;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #e2e5ec;
          cursor: pointer;
        }
      }
    }
    .a_sidebar__body__group {
      padding-bottom: 5px;
      &.a-fixed-botom {
        box-shadow: 0 -8px 15px rgba(0, 0, 0, 0.1);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
      &:last-child {
        margin-bottom: 0;
      }
      p {
        text-transform: uppercase;
        color: hsla(0,0%,100%,.65);
        font-weight: 500;
        font-size: 13px;
      }
      @include a-respond-down(md) {
        margin-bottom: 0;
        padding-bottom: 0;
        &:nth-child(n+2) {
          padding-top: 0 !important;
        }
      }
      ul {
        overflow: hidden;
        max-height: 0px;
        transition: all .4s ease;
      }
      &.active {
        p.a_sidebar__item {
          &:after {
            border-right: 1px solid rgba(255, 255, 255, 1);
            border-bottom: 1px solid rgba(255, 255, 255, 1);
          }
          .a_sidebar__link {
            color: #ffffff;
            i {
              margin-right: 0;
              color: #ffffff;
            }
            span {
              color: #ffffff;
            }
          }
        }
      }
      &.expand {
        p.a_sidebar__item:after {
          transform: rotate(-135deg);
          top: 19px;
        }
        ul {
          max-height: 800px;
        }
      }
    }
  }
  p.a_sidebar__item {
    text-transform: uppercase;
    font-weight: 500;
    padding: 0;
    &:after {
      content: "";
      width: 6px;
      height: 6px;
      border-right: 1px solid rgba(255, 255, 255, 0.6);
      border-bottom: 1px solid rgba(255, 255, 255, 0.6);
      position: absolute; top: 16px; right: 12px; z-index: 1;
      transform: rotate(45deg);
      transition: all .3s;
      display: block;
    }
    &.not-icon-hover {
      &:after {
        display: none;
      }
    }
  }
  .a_sidebar__item {
    position: relative;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    overflow: hidden;
    text-overflow: clip;
    width: 100%;
    color: rgba(255, 255, 255, 0.6);
    height: 45px;
    padding: 0 20px;
    &.active {
      background-color: #07346d;
      .a_sidebar__link {
        color: #ffffff;
        i {
          margin-right: 0;
          color: #ffffff;
        }
        span {
          color: #ffffff;
        }
      }
    }
    &:hover {
      cursor: pointer;
      background-color: #0052CC;
      &:after {
        border-right: 1px solid rgba(255, 255, 255, 1);
        border-bottom: 1px solid rgba(255, 255, 255, 1);
      }
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 3px;
        height: 100%;
        background-color: white;
        animation: fullHeight linear .3s;
      }
      @include a-respond-down(md) {
        &::before {
          width: 0px;
        }
      }
      .a_sidebar__link {
        color: white;
        transition: none;
        -webkit-transition: none;
        i {
          margin-right: 0;
          color: white;
        }
      }
    }
    &:focus {
      @include a-respond-down(md) {
        transition: none;
        -webkit-transition: none;
        background-color: #f4f5f7;
        a {
          background-color: transparent;
        }
      }
    }
    .notify__number {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 4px;
      top: 12px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.27);
      span {
        font-size: 10px;
      }
    }
  }
  .a_sidebar__link {
    text-decoration: none;
    color: rgba(255,255,255,.6);
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 12px 0 12px 12px;
    box-sizing: border-box;
    i {
      width: 20px;
    }
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:focus {
      text-decoration: none;
      @include a-respond-down(md) {
        background-color: #0052CC;
        outline: none;
      }
    }
    &:visited {
      text-decoration: none;
      color: #ffffff;
      @include a-respond-down(md) {
        background-color: #0052CC;
        outline: none;
      }
    }
  }
}

/* ==========================================================================
   # END COMPONENTS SIDEBAR
   ==========================================================================
*/
/* ==========================================================================
   # START COMPONENTS CONTENT IS SIDEBAR
   ==========================================================================
*/
.a-content {
  &__is_sidebar {
    display: flex;
    justify-content: center;
    position: relative;
    .a-content__right__center {
      width: calc(100% - 264px);
    }
    .open_c_sidebar {
      @include a-respond-down(md) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.55);
        z-index: 1023;
      }
    }
  }
  &__sidebar {
    width: 240px;
    padding-right: 15px;
    min-height: calc(100vh - 143px);
    .button_toggle {
      display: none;
    }
    @include a-respond-down(md) {
      position: fixed;
      left: -241px;
      top: 50px;
      height: 100vh;
      transition: ease all .5s;
      z-index: 1024;
      &.c_sidebar__collapse {
        left: -2px;
        transition: ease all .5s;
      }
      .button_toggle {
        width: 40px;
        height: 40px;
        background-color: white;
        color: #555555;
        position: absolute;
        right: -41px;
        box-shadow: 4px 3px 20px rgba(0, 0, 0, 0.1);
        top: 95px;
        box-sizing: border-box;
        padding-top: 10px;
        padding-left: 11px;
        border: 1px solid #f2f2f2;
        display: block;
        &:focus {
          background-color: white;
        }
        i {
          font-size: 18px;
        }
      }
    }
    .c_sidebar__title {
      font-size: 13px;
      font-weight: 700;
      color: #666666;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
    > ul {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      li {
        margin-bottom: 5px;

        border-radius: 4px;
        &.active {
          background-color: rgba(242, 242, 242, 1);
          a {
            font-weight: bold;
          }
        }
        &:hover {
          cursor: pointer;
          background-color: rgba(242, 242, 242, 1);
        }
        a {
          padding: 8px 5px 8px 10px;
          text-decoration: none;
          color: #666666;
          font-size: 13px;
          display: flex;
        }
      }
    }
  }
  &__sidebar {
    width: 240px;
    padding-right: 15px;
    .c_sidebar__title {
      font-size: 14px;
      font-weight: 700;
      color: #999999;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    > ul {
      li {
        margin-bottom: 5px;
        padding: 6px;
        border-radius: 4px;
        &.active {
          background-color: rgba(242, 242, 242, 1);
          a {
            font-weight: bold;
          }
        }
        &:hover {
          cursor: pointer;
          background-color: rgba(242, 242, 242, 1);
        }
        a {
          text-decoration: none;
          color: #333333;
        }
      }
    }
  }
}