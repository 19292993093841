/* ==========================================================================
   # START COMPONENTS MODAL
   ==========================================================================
*/
.a-modal {
  &__title {
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ebedf2;
    color: #666666;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
  }
  &--cfprice--left {
    padding-left: 15px;
    p {
      margin-top: 10px;
    }
  }
  &--cfprice--right {
    padding-right: 15px;
    input {
      margin-top: 10px;
    }
  }
  &--cfprice--body {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 10px;
  }
  &--violate__body {
    margin-top: 10px;
    .react-select--violate_modal__single-value +div {
      padding-top: unset;
    }
    .react-select--violate_modal__indicators div {
      padding-top: unset;
      padding-bottom: unset;
    }
  }
  &__complaintseller{
    box-sizing: border-box;
    &__detail{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 15px;
    }
    &__action{
      align-items: flex-end;
      .a-btn{
        height: 30px !important;
      }
    }
  }
  &--list-error-upload-file{
    .ant-modal-body{
      max-height: 400px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 6px;

        &:horizontal {
          height: 6px;
        }
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, .45);
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, .45);
        cursor: pointer;
      }
    }

  }
  &--list-trade {
    .ant-modal-header {
      background-color: #F3F3F3;
      .ant-modal-title {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  &--confirm-trade {
    .ant-modal-confirm-btns {
      .ant-btn {
        border: 1px solid $color--primary;
        color: $color--primary;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;

      }
      .ant-btn-primary {
        background-color: $color--primary;
        border: 1px solid $color--primary;
        color: white;
        font-size: 12px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;

        &:hover {
          background-color: white;
          border: 1px solid $color--primary;
          color: $color--primary;
        }

        &:focus {
          background-color: $color--primary;
          border: 1px solid $color--primary;
          color: white;
        }

      }

    }
  }
}

.ant-modal-header{
  background-color: #f2f3f8;
}
/* ==========================================================================
   # END COMPONENTS MODAL
   ==========================================================================
*/

