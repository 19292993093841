@import "./../../../../../resources/scss/settings/setting.mixin";
@import "./../../../../../resources/scss/settings/setting.color";
//Tab trong danh sach filter san phẩm KO-2
.effect-line {
  position: relative;
  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-image: linear-gradient(to right, rgba(0, 82, 204, 0.6) 0%, rgba(0, 82, 204, 0.3) 20%, rgba(0, 82, 204, 1) 35%, rgba(0, 82, 204, .7) 100%);
    background-size: 300% 10px, 10px 300%;
    animation: Gradient 5s infinite linear;
  }
}
.a-tab-order {
  clear: both;
  &__title {
    ul {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      @include a-respond-down(md) {
        display: -webkit-box;
        width: 100%;
        overflow-y: auto;
        padding-bottom: 7px;
      }
      &::-webkit-scrollbar {
        width: 4px;
        &:horizontal {
          height: 4px;
        }
      }
      &::-webkit-scrollbar-thumb {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: transparent;
      }
      li {
        width: 100%;
        list-style-type: none;
        border: 1px solid rgba(242, 242, 242, 1);
        border-right: 0;
        padding: 10px 20px 10px 20px;
        margin: 0;
        position: relative;
        transition: ease all .5s;
        @include a-respond-down(md) {
          width: 33.333333%;
          border-right: 1px solid rgba(242, 242, 242, 1);
          padding: 5px 10px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
          margin-right: 10px;
        }
        a {
          span {
            opacity: .35;
          }
        }
        &.active {
          position: relative;
          a {
            span {
              opacity: 1;
            }
          }
          &::before {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-image: linear-gradient(to right, rgba(0, 82, 204, 0.6) 0%, rgba(0, 82, 204, 0.3) 20%, rgba(0, 82, 204, 1) 35%, rgba(0, 82, 204, .7) 100%);
            background-size: 300% 10px, 10px 300%;
            animation: Gradient 5s infinite linear;
          }
          &.danger {
            position: relative;
            &::before {
              position: absolute;
              content: '';
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background: linear-gradient(to right, rgba(255, 0, 0, 0.6) 0%, rgba(255, 0, 0, 0.3) 25%, rgba(255, 0, 0, 1) 40%, rgba(255, 0, 0, .7) 100%);
              background-size: 300% 10px, 10px 300%;
              animation: Gradient 5s infinite linear;
            }
          }
          &.bought {
            position: relative;
            &::before {
              position: absolute;
              content: '';
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background-image: linear-gradient(to right, rgba(0, 82, 204, 0.6) 0%, rgba(0, 82, 204, 0.3) 20%, rgba(0, 82, 204, 1) 35%, rgba(0, 82, 204, .7) 100%);
              background-size: 300% 10px, 10px 300%;
              animation: Gradient 5s infinite linear;
            }
          }
        }
        &.active-no-border {
          position: relative;
          a {
            cursor: default;
            span {
              opacity: 1;
            }
          }
          &::before {
            display: none;
          }
          &:hover {
            cursor: default;
          }
        }
        &.danger {
          a {
            span {
              color: red;
            }

          }
          &:hover {
            a {
              span {
                opacity: 1;
              }
            }
            &::before {
              position: absolute;
              content: '';
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background: linear-gradient(to right, rgba(255, 0, 0, 0.6) 0%, rgba(255, 0, 0, 0.3) 25%, rgba(255, 0, 0, 1) 40%, rgba(255, 0, 0, .7) 100%);
              animation-name: fullwidth;
              animation-duration: .5s;
              animation-iteration-count: inherit;
            }
          }
        }
        &.bought {
          a {
            span {
              color: #0052CC;
            }

          }
          &:hover {
            a {
              span {
                opacity: 1;
              }
            }
            &::before {
              position: absolute;
              content: '';
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background-image: linear-gradient(to right, #021B79 0%, #0052CC 30%, rgba(0, 82, 204, 0.8) 60%, rgba(0, 82, 204, 0.3) 100%);
              animation-name: fullwidth;
              animation-duration: .5s;
              animation-iteration-count: inherit;
            }
          }
        }
        &:hover {
          cursor: pointer;
        }
        &:last-child {
          border-right: 1px solid rgba(242, 242, 242, 1);
        }
        &:hover {
          opacity: 1;
          &::before {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-image: linear-gradient(to right, #021B79 0%, #0052CC 30%, rgba(0, 82, 204, 0.8) 60%, rgba(0, 82, 204, 0.3) 100%);
            animation-name: fullwidth;
            animation-duration: .5s;
            animation-iteration-count: inherit;
          }
        }
        a {
          display: flex;
          flex-direction: column;
          text-decoration: none;
          span {
            font-size: 15px;
            color: #333333;
            @include a-respond-down(xl) {
              font-size: 11.5px;
            }
            &:first-child {
              font-size: 22px;
              margin-bottom: 5px;
              font-weight: 500;
              @include a-respond-down(xl) {
                font-size: 18px;
              }
            }
          }
          &:hover {
            text-decoration: none;
          }
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
  }
}

//Quick filter sản phẩm trong KO-2
.a-sort {
  width: 100%;
  margin-top:15px;
  @include a-respond-down(md) {
    margin-bottom: -10px;
  }
  ul {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    box-sizing: border-box;
    @include a-respond-down(sm) {
      flex-direction: row;
    }
    @include a-respond-down(md) {
      flex-wrap: wrap;
    }
    li {
      list-style-type: none;
      @include a-respond-down(sm) {
        width: 50% !important;
      }
      &.hidden {
        display: none;
      }

      &.show {
        display: block;
      }

      &:first-child {
        width: 24px;
        display: flex;
        align-items: center;
        @include a-respond-down(md) {
          order: 6;
          margin-top: 10px;
          margin-left: -17px;
        }
        @include a-respond-down(sm) {
          order: 5;
          margin-left: 1px;
          width: calc(50% - 2px) !important;        }
      }
      &:nth-child(2) {
        width: calc(34% + 13px);
        padding-left: 10px;
        @include a-respond-down(xl) {
          width: calc(32% + 6px);
        }
        @include a-respond-only(xl, xxl) {
          width: calc(32% + 42px);
        }
        @include a-respond-down(md) {
          padding-left: 0;
          width: 25%;
          order: 1;
        }
        @include a-respond-down(sm) {
          order: 1;
        }
      }
      &:nth-child(3) {
        width: calc(21%);
        @include a-respond-down(md) {
          width: 25%;
          order: 3;
          display: flex;
          justify-content: center;
        }
        @include a-respond-down(sm) {
          order: 2;
          justify-content: flex-end;
        }
      }
      &:nth-child(4) {
        width: 20%;
        @include a-respond-down(xl) {
          width: 19%;
        }
        @include a-respond-down(md) {
          width: 25%;
          order: 2;
          display: flex;
        }
        @include a-respond-down(sm) {
          order: 3;
        }
      }
      &:nth-child(5) {
        width: calc(21% - 124px);
        align-items: center;
        display: flex;
        @include a-respond-down(xl) {
          width: calc(25% - 124px);
        }
        @include a-respond-down(md) {
          width: 25%;
          order: 4;
          justify-content: flex-end;
          margin-bottom: 3px;
        }
        @include a-respond-down(sm) {
          order: 4;
        }
      }
      &.seach-pre-next {
        display: inline-flex;
        align-items: center;
        min-width: 165px;
        @include a-respond-down(md) {
          order: 5;
          width: calc(100% - 24px);
          margin-top: 10px;
          justify-content: flex-start;
        }
        @include a-respond-down(sm) {
          order: 6;
          justify-content: flex-end;
        }
      }
      &.quick-sort {
        button {
          font-size: 13px;
          &:nth-child(3) {
            position: relative;
            &::before {
              position: absolute;
              content: '';
              top: 3px;
              left: -2px;
              width: 1px;
              height: 74%;
              background-color: $color--primary;
            }
          }
          &:nth-child(4) {
            position: relative;
            &::before {
              position: absolute;
              content: '';
              top: 3px;
              left: -2px;
              width: 1px;
              height: 74%;
              background-color: $color--primary;
            }
            &::after {
              position: absolute;
              content: '';
              top: 3px;
              right: 0;
              width: 1px;
              height: 74%;
              background-color: $color--primary;;
            }
          }
          &.active {
            font-weight: bold;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &.quick-sort > span {
        color: #999999;
        margin-right: 10px;
        font-size: 13px;
      }
      a {
        display: flex;
        align-items: center;
        color: #666666;
        &:hover {
          cursor: pointer;
          text-decoration: none;
          color: $color--primary;
        }
      }
    }
  }
}

// Sản phẩm trong KO-2
.a-item {
  border: 1px solid $color--boder-cp;
  margin-bottom: 10px;
  position: relative;
  -webkit-box-shadow: 0 5px 7px 0 rgba(62, 28, 131, 0.03);
  -o-box-shadow: 0 5px 7px 0 rgba(62, 28, 131, 0.03);
  box-shadow: 0 5px 7px 0 rgba(62, 28, 131, 0.03);
  background-color: white;
  @include a-respond-down(md) {
    -webkit-box-shadow: 0 0px 8px rgba(62, 28, 131, 0.1);
    -o-box-shadow: 0 0px 8px rgba(62, 28, 131, 0.1);
    box-shadow: 0 0px 8px rgba(62, 28, 131, 0.1);
    border-radius: 5px;
  }
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 0;
    opacity: 0;
    -webkit-transition: ease all .5s;
    -moz-transition: ease all .5s;
    -ms-transition: ease all .5s;
    -o-transition: ease all .5s;
    transition: ease all .5s;
  }
  &:hover {
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 3px;
      height: 100%;
      background-color: $color--red-c;
      opacity: 1;
    }
  }
  &__top {
    padding: 11px 5px 5px 5px;
    @include a-respond-down(md) {
      padding: 15px 5px 5px 10px;
    }
    @include a-respond-down(sm) {
      padding: 0 5px 5px 5px;
    }
    ul {
      @include dislay-flex(flex-start, row);
      @include a-respond-down(md) {
        flex-wrap: wrap;
      }
      @include a-respond-down(sm) {
        flex-direction: column;
      }
      li {
        @include a-respond-down(xs) {
          width: 100% !important;
        }
        &.order {
          &--1 {
            @include a-respond-down(md) {
              order: 1;
            }
          }
          &--2 {
            @include a-respond-down(md) {
              order: 2;
            }
          }
          &--3 {
            @include a-respond-down(md) {
              order: 3;
            }
          }
          &--4 {
            @include a-respond-down(md) {
              order: 4;
            }
          }
          &--5 {
            @include a-respond-down(md) {
              order: 5;
            }
          }
          &--6 {
            @include a-respond-down(md) {
              order: 6;
            }
          }
          &--7 {
            @include a-respond-down(md) {
              order: 7;
            }
          }

        }
        &.w_100-30__lg {
          @include a-respond-down(md) {
            width: calc(100% - 30px) !important;
          }
          @include a-respond-down(sm) {
            width: 100% !important;
          }
        }
        &.w_50__lg {
          @include a-respond-down(md) {
            width: 50% !important;
          }
          @include a-respond-down(sm) {
            width: 100% !important;
          }
        }
        &.w_50-30__lg {
          @include a-respond-down(md) {
            width: calc(50% - 30px) !important;
          }
          @include a-respond-down(sm) {
            width: 100% !important;
          }
        }
        &.w_100__lg {
          @include a-respond-down(md) {
            width: 100% !important;
          }
          @include a-respond-down(sm) {
            width: 100% !important;
          }
        }
        &:first-child {
          width: 30px;
          @include a-respond-down(sm) {
            order: 1 !important;
            margin-bottom:15px;
          }
        }
        &:nth-child(2) {
          width: 34%;
          &.block-paing {
            width: 33%;
          }
          @include a-respond-down(md) {
            margin-bottom: 10px;
          }
          @include a-respond-down(xl) {
            width: 31.5%;
          }
          @include a-respond-down(sm) {
            margin-bottom: 5px;
            order: 2 !important;
          }
        }
        &:nth-child(3) {
          width: 20%;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding-right: 20px;
          &.block-paing {
            font-size: 12px;
            margin-right: 15px;
            @include a-respond-down(md){
              margin-right: 0;
            }
          }
          @include a-respond-down(md) {

            margin-bottom: 15px;
          }
          @include a-respond-down(sm) {
            order: 3 !important;
          }
        }
        &:nth-child(4) {
          width: 20%;
          @include a-respond-down(md) {
            margin-bottom: 15px;
            padding-left: 0;
          }
          @include a-respond-down(sm) {
            margin-bottom: 5px;
          }
        }
        &:nth-child(5) {
          width: 23%;
          display: flex;
          justify-content: flex-end;
          @include a-respond-down(md) {
            text-align: left;
          }
          @include a-respond-down(md) {
            display: flex;
            justify-content: flex-start;
            align-items: inherit;
            padding-bottom: 10px;
          }
        }
      }
    }
    .header_partner {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
      text-decoration: none;
      font-weight: 600;
      position: relative;
      color: white;
      background-color: #326eea;
      margin-left: 0 !important;
      &:hover {
        cursor: default;
      }
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

      }
      &--nhaphang {
        background-color: #0052CC;
        color: white;

      }
      &--ongvang {
        background-color: #e6b21d;
        color: white;

      }
      &--honghac {
        background-color: #e94745;
        color: white;
      }
    }
  }
  &__account {
    @include dislay-flex(flex-start, row);
    img {
      width: 52px;
      height: 52px;
      object-fit: cover;
    }
    &__infor {
      margin-left: 15px;
    }
    &__name {
      @include dislay-flex(flex-start, row);
      align-items: center;
      color: $color--primary;
      font-weight: bold;
      font-size: 14px;
      padding-right: 20px;
      &:hover {
        .copy {
          opacity: 1;
          margin-left: 10px;
          cursor: pointer;
          color: #999999;
        }
      }
      .copy {
        opacity: 0;
        width: 11px;
        font-size: 13px;
        margin-left: 10px;
        @include a-respond-down(md) {
          margin-left: -11px;
        }
      }
      a {
        text-decoration: none;
        color: #0052CC;
        &:active {
          text-decoration: underline !important;
        }
        &:focus {
          text-decoration: underline !important;
        }
      }
      span {
        @include dislay-flex(flex-start, row);
        align-items: center;
        color: #666666;
        font-weight: 400;
        font-size: 11px;
        margin-left: 15px;
        i {
          font-size: 16px;
          color: #333333;
          margin-right: 5px;
        }
      }
    }
    &__username {
      font-size: 10px;
    }
    &__time {
      &-ordering {
        margin-top: 2px;
        @include dislay-flex(flex-start, row);
        align-items: center;
        font-size: 14px;
      }
      &-detail {
        color: #666666;
        margin-left: 5px;
        font-size: 10px;
      }
    }
  }
  &__block {
    p {
      @include dislay-flex(flex-start, row);
      color: #666666;
      font-size: 12px;
      word-break: break-word;
      img {
        margin-right: 5px;
      }
      span {
        margin-left: 5px;
        margin-right: 5px;

      }
    }
    &__total {
      font-size: 14px;
      @include a-respond-down(md) {
        font-size: 13px;
      }
      span {
        color: $color--font-primary;
        font-weight: 700;
      }
      &--money {
        font-size: 14px;
        @include a-respond-down(md) {
          font-size: 13px;
        }
        span {
          color: #FF6600;
        }
      }
    }
    &__status {
      font-size: 12px;
      color: #333333;
      padding: 3px 8px;
    }
    &__time__waiting {
      line-height: 42px;
      font-size: 10px;
      color: #666666;
    }
    &__purchase-2{
      display: flex;
      flex-wrap: wrap;
      @include a-respond-only(md,lg){
        justify-content: flex-end;
      }
    }
    &__shop {
      display: flex;
      align-items: center;
    }
    &__is_complaintseller{
      display: flex;
    }
    &__complaintseller {
      position: relative;
      a {
        text-decoration: none;
        background-color: #CCCCCC;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
        padding: 2px 7px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        &:focus {
          background-color:#0052CC;
        }
      }
      span {
        position: absolute;
        top: -8px;
        right: -10px;
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background-color: $color--red-c;
        font-size: 11px;
        color: white;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  &__bottom {
    border-top: 1px solid $color--boder-cp;
    padding: 5px;
    @include a-respond-down(md) {
      display: flex;
      padding-left: 10px;
      &.purchase {
        flex-direction: row;
        justify-content: flex-end;
      }
    }
    @include a-respond-down(sm) {
      flex-direction: column;
    }
    &__block_purchase {
      @include a-respond-down(md) {
        display: flex;
        align-items: flex-start !important;

      }
    }
    &__select_purchase {
      @include a-respond-down(md) {
        align-items: flex-start !important;
        width: calc(100% - 22px);
        justify-content: space-between;
        font-size: 10px;
      }
    }
    &__cancel_purchase {
      @include a-respond-down(md) {
        min-width: 55px;
        display: flex;
        justify-content: flex-end;
        margin-right: 10px;
      }
    }
    &__timing {
      font-size: 11px;
      @include a-respond-down(md) {
        order: -1;
        width: calc(50% - 31px);
        padding-left: 0 !important;
        .lost__deadline{
          padding-left: 2px;
          span{
            padding-left: 5px;
          }
        }
      }
    }
    &__left {
      width: 315px;
      display: flex;
      align-items: center;
      font-size: 11px;
      @include a-respond-down(md){
        align-items: flex-start;
        order: 2;
        margin-top: 6px;
        justify-content: flex-end;
        width: 100%;
        .lost__deadline{
          padding-left: 2px;
          span{
            padding-left: 5px;
          }
        }
      }

    }
    span {
      min-width: 52px;
      padding-left: 5px;
      text-align: center;
      vertical-align: middle;
      @include a-respond-down(md) {
        text-align: left;
        padding-left: 28px;
      }
    }
    &__time {
      margin-left: 30px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      font-size: 10px;
      border-radius: 20px;
      img {
        margin-right: 5px;
        margin-top: -3px;
      }
      i {
        margin-right: 5px;
      }

    }
    @include dislay-flex(space-between, row);
    ul {
      @include dislay-flex(flex-end, row);
      width: 100%;
      @include a-respond-down(md) {
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      li {
        margin-left: 40px;
        font-size: 11px;
        @include a-respond-down(md) {
          margin-left: 0;
          width: 100%;
          margin-top: 5px;
          b {
            margin-right: 0 !important;
          }
        }
        p {
          font-weight: bold;
        }
        span {
          font-weight: normal;
          color: $color--gray-9;
          margin-left: 10px;
          @include a-respond-down(md) {
            margin-left: 0;
          }
        }
      }
    }
    &__sale {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 28px;
      @include a-respond-down(md) {
        width: calc(50% - 30px);
        justify-content: flex-end;
      }
      p {
        text-transform: uppercase;
        color: #666666;
        font-size: 11px;
        font-weight: 700;
      }
      img {
        margin-left: 15px;
        max-width: 23px;
        max-height: 13px;
      }
    }
  }
  &--group{
    display: flex;
    width:100%;
    margin-bottom: .4rem;
    &--label{
      width: 37%;
      color:#b1afaf ;
      @include a-respond-up(xxl){
        width: 33%;
      }
      @include a-respond-only(lg,xl){
        width: 40%;
        font-size: 11px;
      }
      @include a-respond-down(md){
        width: 25%;
      }
    }
    &--value{
      width: 63%;
      p{
        color:#333333;
        font-weight: 500;
        max-width: calc(100% - 24px);
        word-break: break-word;
        margin-top: 5px;
      }
      @include a-respond-up(xxl){
        width: 67%;
      }
      @include a-respond-only(lg,xl){
        width: 60%;
      }
      @include a-respond-down(md){
        width: 80%;
      }
    }
  }
}

//Thêm filter trong K0-2
.a-filter {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @include a-respond-down(xs) {
    display: flex;
    flex-direction: column-reverse;
  }
  &__purchase {
    @include a-respond-down(md) {
      display: flex;
      flex-direction: row;
    }
    @include a-respond-down(sm) {
      flex-direction: column;
    }
    .group_btn_purchase {
      @include a-respond-down(md) {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        align-items: center;
        width: 100%;
      }
      @include a-respond-down(sm) {
        margin-top: 10px;
      }
      button {
        -webkit-border-radius: 2px !important;
        -moz-border-radius: 2px !important;
        border-radius: 2px !important;
      }
    }
  }
  .btn-reset {
    text-decoration: none;
    color: #333333;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    .icon-reset {
      font-size: 10px;
    }
  }
  .btn-disabled {
    pointer-events: none;
    background-color: #999999;
  }
  &.unactive {
    display: none;
  }
  &--ordering {
    @include dislay-flex(space-between, row);
    align-items: center;
    margin-bottom: 15px;
    clear: both;
    @include a-respond-down(xs) {
      @include dislay-flex(flex-start, column);
      align-items: flex-start;
    }
    &--select {
      min-width: 300px;
      @include a-respond-down(xs) {
        min-width: 100%;
        width: 100%;
      }
      .dxbvWE {
        display: flex;
        @include a-respond-down(xs) {
          display: flex;
        }
      }
      &:focus {
        outline: none;
        box-shadow: none;
        border: none;
      }
    }
    &--form-group {
      margin-right: 20px;
      .jYaUrc {
        background-color: white;
        border: 1px solid $color--border;
        min-height: 35px;
        &:focus {
          outline: none;
          box-shadow: none;
          border: none;
        }
      }
      .jYoBBl {
        margin: 0;
        &:focus {
          outline: none;
          border: none;
          border: none;
        }
      }
      .dvJJgS {
        width: 100%;
      }
      .gxhTfp {
        border-width: 1px !important;
      }
      input {
        height: auto !important;

        &:focus {
          outline: none;
          box-shadow: none;
          border: none;
        }
      }
    }
    .cECdMw {
      margin-top: 0;
    }
    .rrkvw {
      margin-top: 0;
    }
    .jrFCgE {
      padding-top: 0;
    }
    .css-1k4398s {
      top: 54%;
    }
    .jgZyAb {
      padding-left: 5px;
    }

  }
}

// Block kết quả trả về sau khi chọn filter KO-2
.a-result {
  margin-bottom: 20px;
  margi-top: 20px;
  display: inline-table;
  &__block {
    float: left;
    margin-right: 15px;
    margin-bottom: 10px;
    padding-top: 1.5px;
    padding-bottom: 1.5px;
    background-color: #3a72fe;
    color: white;
    @include dislay-flex(space-between, row);
    align-items: center;
    max-width: 200px;
    padding-left: 15px;
    transition: all linear .5s;
    border-radius: 4px;
    overflow: hidden;
    opacity: 1;
    &.delete {
      opacity: 0;
      display: none;
    }
    p {
      font-size: 13px;
      text-transform: uppercase;
      width: 90%;
      overflow: hidden;
      @include dislay-flex(flex-start, row);
      span {
        text-transform: none;
        margin-left: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    a {
      color: white;
      &:hover {
        cursor: pointer;
      }
    }
    &:hover {
      background-color: $color--primary;
    }
  }
}

//Kết quả trả về main KO-2
.a--ordering-main {
  width: 100%;
  @include a-respond-down(xs) {
    display: flex;
    flex-direction: column;
  }
  &.unactive {
    display: none;
  }
  &__result {
    width: 90%;
    @include a-respond-down(xs) {
      width: 100%;
    }
  }
}

.fbFHGe {
  background-color: white;
  border: 1px solid #CCCCCC;
}

.czFgGV {
  border-width: 1px;
}

.max-width-200 {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.max-width-100 {
  max-width: 100px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.a-perform-action {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
}

.a-group {
  .a-label-success {
    color: #339933 !important;
  }
  .a-label-error {
    color: #ff0000;
  }
}

.a-modal__title {
  &.order-assign {
    font-size: 14px !important;
  }
}


