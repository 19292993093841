.a-filter {
  &--group-collapsed {
    background-color: white;
    font-size: 1em;
    &:not(:last-child) {
      border-right: 1px solid #ced4da;
    }
    &:hover {
      opacity: 1;
      &::before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-image: linear-gradient(to right, #021B79 0%, #0052CC 30%, rgba(0, 82, 204, 0.8) 60%, rgba(0, 82, 204, 0.3) 100%);
        animation-name: fullwidth;
        animation-duration: .5s;
        animation-iteration-count: inherit;
      }
    }
    &.active {
      position: relative;
      &::before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-image: linear-gradient(to right, rgba(0, 82, 204, 0.6) 0%, rgba(0, 82, 204, 0.3) 20%, rgba(0, 82, 204, 1) 35%, rgba(0, 82, 204, .7) 100%);
        background-size: 300% 10px, 10px 300%;
        animation: Gradient 5s infinite linear;
      }
    }
  }
  &--group-expanded {
    &:not(:last-child) {
      border-right: 1px dashed #ced4da  ;
    }
  }
}
.a-block {
  &--status {
    height: 80px;
    border: 2px dashed #0050b3;
    padding: 0 1.2em;
    background-color: white;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  &--status-purchases {
    border: 0;
  }
}
.list-order-form-search {
  .title-search-col {
    position: relative;
    display: inline-block;
    line-height: 20px;
    &:before {
      content: "";
      position: absolute;
      top: 6px;
      right: -12px;
      width: 6px;
      height: 6px;
      border-bottom: 1px solid $txt-color-primary;
      border-right: 1px solid $txt-color-primary;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transition: all .3s;
      -webkit-transition: all .3s;
    }
  }
  .active {
    .title-search-col {
      &:before {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        top: 9px;
      }
    }
  }
  .a-filter--group-collapsed {
    .body-search-col {
      position: absolute; top: -1px; left: 1px;
      width: calc(100% - 1px);
      border: 1px solid $txt-color-primary;
      padding-top: 38px;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      background: #ffffff;
      z-index: 1;
      .form-search-col {
        padding: 0 15px 15px 15px;
      }
    }
  }
}

.list-order-stats {
  .list-order-stats-nav {
    flex-wrap: wrap;
    span.a--cursor-poiter {
      transition: all .3s;
      &:hover {
        color: #000 !important;
      }
    }
    li{
      border: 1px solid #ccc;
      border-radius: 20px;
      margin: 5px 0 5px 5px;
      padding: 2px 10px !important;
      font-size: 13px;
      @include a-respond-down(sm) {
        width: calc(33% - 6px);
        text-align: center;
        margin: 5px 3px;
      }
    }
    li.active {
      background: $txt-color-primary;
      color: #ffffff !important;
    }
    div+div {
      .a-block--status-purchases {
        border-left: 1px solid #ddd;
      }
    }
  }
  .list-order-orderby {
    display: flex;
    align-items: center;
    @include a-respond-up(sm) {
      justify-content: flex-end;
      margin: 6px 10px 0 0;
    }
    @include a-respond-down(sm) {
      margin: 5px 0 10px 0;
      justify-content: center;
    }
  }
}

.list-order-distributed {
  border-top: 1px solid #ced4da;
  padding: 5px 10px 5px 35px;
  @include a-respond-up(sm) {
    display: flex;
    align-items: center;
  }
  @include a-respond-down(sm) {
    padding: 10px;
  }
  .list-order-distributed-left {
    display: flex;
    @include a-respond-down(sm) {
      justify-content: center;
    }
  }
  .list-order-distributed-right {
    display: flex;
    align-items: center;
    @include a-respond-up(sm) {
      justify-content: flex-end;
    }
    @include a-respond-down(sm) {
      margin-top: 10px;
      justify-content: center;
    }
  }
}

.new-list-orders {
  .order-item {
    padding: 10px 15px;
    margin: 15px 20px;
    background: #f5f5f5;
    border-radius: 3px;
    transition: all .5s;
    -webkit-transition: all .5s;
    @include a-respond-down(md) {
      padding: 10px;
      margin: 10px;
    }
    @include a-respond-up(md) {
      .order-item-total {
        margin-top: 5px;
      }
      .order-item-ttv {
        margin-top: 2px;
      }
    }
    &:hover {
      background: #e7f5ff;
    }
  }
  .stats-box {
    .ant-col-xl-4 {
      width: 20%;
    }
  }
  .stats-box-has-waiting-pay {
    .ant-col-xl-5 {
      width: 20%;
    }
  }
}

.select_teller_ddl {
  .ant-select-selection {
    height: 26px;
    .ant-select-selection__rendered {
      height: 24px;
      line-height: 24px;
    }
  }
}