/* ==========================================================================
   # START COMPONENTS CONTENT-EDITABLE
   ==========================================================================
*/
.a-content-editable {
  position: relative;
  width: 100%;
  height: 100%;
  .inline-left {
    flex-direction: row-reverse;
    padding-left: 0px !important;
    .a-content-editable--title {
      padding-right: 0 !important;
    }
  }
  &--title {
    line-height: 22px;
    padding: 0 6px;
  }
  label {
    display: inline-flex;
    border: 1px solid transparent;
    transition: ease all .3s;
    align-items: center;
    max-width: 100%;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    &:hover {
      cursor: pointer;
      border: 1px solid rgba(228, 228, 228, 1);
      i {
        opacity: 1;
        background-color: rgba(188, 188, 188, 1);
      }
    }
    i {
      transition: ease all .3s;
      opacity: 0;
      font-size: 12px;
      color: #333333;
      width: 22px;
      height: 22px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      @include dislay-flex(center, row);
      align-items: center;
    }
  }
  &--action {
    width: 60px;
    -moz-box-shadow: 0px 2px 10px rgba(210, 210, 210, 0.349019607843137);
    -webkit-box-shadow: 0px 2px 10px rgba(210, 210, 210, 0.349019607843137);
    box-shadow: 0px 2px 10px rgba(210, 210, 210, 0.349019607843137);
    float: right;
    margin-top: 5px;
  }
  &--account {
    height: 24px;
    margin: -2px 0;
    position: relative;
  }
  &--group {
    button {
      margin-top: 1px;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      background-color: white;
      display: inline-flex;
      border: 1px solid white;
      padding: 2px;
      &:focus {
        outline: none;
      }
      i {
        color: #333333;
        width: 22px;
        height: 21px;
        border: 1px solid rgba(221, 221, 221, 1);
        @include dislay-flex(center, row);
        align-items: center;
        background-color: #f2f2f2;
      }
    }
  }
}

.a-group--icon-edit {
  display: flex;

  .info {
    flex: 1;
  }

  .icon-edit {
    width: 19px;
    height: 19px;
    opacity: 0;
    margin-left: 4px;
    i {
      color: $color--primary;
    }
  }

  &:hover {
    .icon-edit {
      cursor: pointer;
      opacity: 1;
    }
  }
}
/* ==========================================================================
   # END COMPONENTS CONTENT-EDITABLE
   ==========================================================================
*/
/* ==========================================================================
   # START INLINE-EDIT COMPONENT
   ==========================================================================
*/
.a-inline-edit{
  display: flex;
  flex-wrap: wrap;
  &--label{
    width: 100%;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 16px;
    color: #898989;
    .icon-inline{
      margin-left: .5rem;
      animation: opacity-shadow .3s linear;
      font:{
        size: 14px;
      }
      color: $txt-color-primary;
      &:hover{
        cursor: pointer;
      }
    }
  }
  &--error-text{
    margin-top:3px;
    font-size: 12px;
    color: $txt-color--redc;
    animation: show-error-text .2s linear;
    margin-bottom: 0;
  }
  &--value{
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    color: #262626;
    animation: opacity-shadow .3s linear;
  }
  &--input{
    position: relative;
    width: 100%;
    animation: show-input-inline .2s linear;
    .icon-loading{
      position: absolute;
      right: 5px;
      top: 10px;
    }
  }
}
.icon-loading {
  z-index: z-index(dropdown);
  svg {
    animation: circle .5s linear reverse infinite;
  }
}
/* ==========================================================================
   # END INLINE-EDIT COMPONENT
   ==========================================================================
*/