@import "./../../../../resources/scss/settings/setting.mixin";
@import "./../../../../resources/scss/settings/setting.color";

.a_salefigures {
  &.seach-pre-next {
    display: inline-flex;
    align-items: center;
    min-width: 165px;
    @include a-respond-down(md) {
      order: 5;
      width: calc(100% - 24px);
      margin-top: 10px;
      justify-content: flex-start;
    }
  }
  &__title {
    text-align: center;
    margin-bottom:10px;
    span {
      font-size: 22px;
      color: #333333;
      text-transform: uppercase;
      font-weight: bold;
      @include a-respond-down(md) {
        font-size: 17px;
      }
    }
  }
  &__group {
    display: flex;
    align-items: center;
    &__datetime {
      display: flex;
    }
  }
  &__action {
    display: flex;
    padding: 10px 10px 20px 10px;
    background-color: rgba(242, 242, 242, 1);
    margin-bottom:30px;
    margin-left: -10px;
    &__left {
      display: flex;
      flex-direction: row;

    }
    &__right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @include a-respond-down(xl) {
        width: 15%;
      }
    }
    &__height_sp{
      height: 48px;
      @include a-respond-down(md) {
        height: auto;
      }
    }
    &__select_year{
      @include a-respond-only(md, lg) {
        height: auto;
        margin-left: 0 !important;
        margin-top: 10px;
        margin-bottom: 15px;
      }
      @include a-respond-down(md){
        height: auto;
        margin-left: 0 !important;
        margin-top: 10px;
        margin-bottom: 15px;
      }
    }
    &__select_month{
      @include a-respond-down(sm) {
        margin-left: 0 !important;
        margin-top: 10px ;
        width: 100% ;
        margin-right: 0 !important;
      }
    }
    &__select_user{
      @include a-respond-down(sm) {
        margin-right: 0 !important;
        width: 100%;
      }
    }
  }
  &__label {
    color:#666666;
    font-weight: 400 !important;
    font-size: 12px;
  }
  &__top {
    display: flex;
    justify-content: center;
  }
  &__history {
    &_text {
      font-size: 12px;
      color: #333333;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: bold;
      i {
        color: #666666;
        margin-right: 10px;
      }
    }
    &_list {
      margin-top: 15px;
      li {
        display: flex;
        margin-bottom: 10px;
        .history__left {
          @include a-respond-down(md){
            width: 100%;
          }
          &__top {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            position: relative;
            .history_is_dropdown {
              display: flex;
              position: relative;
              .history__left__top__detail {
                position: absolute;
                min-width: 200px;
                top: 120%;
                left: 100%;
                padding: 10px;
                background-color: white;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
                max-height: 252px;
                overflow-y: auto;
                z-index: 1024;
                &::-webkit-scrollbar {
                  width: 4px;
                  &:horizontal {
                    height: 3px;
                  }
                }
                &::-webkit-scrollbar-thumb {
                  background: #c4c4c4;
                  border-radius: 10px;
                }
                &::-webkit-scrollbar-thumb:hover {
                  background: #c4c4c4;
                  cursor: pointer;
                }
                li {
                  margin-bottom: 5px;
                  span {
                    white-space: nowrap;
                    color: #444444;
                    font-size: 13px;
                  }
                }
                @include a-respond-down(sm) {
                  left: 0;
                  top: 100%;
                }
              }
              a {
                font-size: 14px;
                text-decoration: none;
              }
              > span {
                display: flex;
                height: 14px;
                padding: 0 2px;
                align-items: center;
                justify-content: center;
                color: white;
                font-size: 12px;
                background-color: #66FFCC;
                font-weight: bold;
                margin-left: 10px;
              }
            }

          }
          &__bottom {
            display: flex;
            align-items: center;
            i {
              font-size: 12px;
              color: #999999;
            }
            span {
              font-size: 10px;
              color: #999999;
              margin-left: 2px;
            }
          }

        }
        .history__right {
          display: flex;
          margin-left: 20px;
          @include a-respond-down(md){
            margin-right: 20px;
          }
          i {
            color: #999999;
            font-size: 12px;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  &__content {
    margin-top: 20px;
    padding: 10px 0;
  }
  &__form_search{
    display: flex;
    @include a-respond-only(md, lg) {
      flex-wrap: wrap;
    }
    @include a-respond-down(md) {
      flex-wrap: wrap;
    }
  }
  .date_time__sale_figure {
    background-color: white !important;
    border: 1px solid #a8a8a8;
  }
  .a_salefigures__datetime {
    margin-right: 10px;
  }
  .progress__group {
    display: flex;
    flex-wrap: wrap;
    .max-width-progress{
      width: 100%;
      justify-content: center;
    }
    &__top {
      position: relative;
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      height: 40px;
      justify-content: center;
      @include a-respond-down(md) {
        height: auto;
        flex-wrap: wrap;
        flex-direction: column-reverse;
      }
      .progress__total_person{
        height: 18px;
        margin-top: 12px;
        position: absolute;
        left: 0;
        > a {
          text-decoration: none;
        }
        @include a-respond-down(md) {
          position: relative;
          left: auto;
        }
      }
      .progress__total_person__detail {
        position: absolute;
        min-width: 200px;
        top: 120%;
        left: 100%;
        padding: 10px;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
        max-height: 252px;
        overflow-y: auto;
        z-index: 1024;
        &::-webkit-scrollbar {
          width: 4px;
          &:horizontal {
            height: 3px;
          }
        }
        &::-webkit-scrollbar-thumb {
          background: #c4c4c4;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #c4c4c4;
          cursor: pointer;
        }
        @include a-respond-down(md) {
          left: 0;
          top: 100%;
        }
        li{
          margin-bottom: 5px;
          span{
            white-space: nowrap;
            color: #444444;
            font-size: 13px;
          }
        }
      }
      .process__text {
        font-size: 32px;
        color: #333333;
        @include a-respond-down(md) {
          font-size: 17px;
          display: flex;
          justify-content: center;
          margin-bottom: 10px;
        }
        &.percent{
          left:47%;
        }
        &.text-progress-success {
          font-size: 22px;
          font-weight: bold;
        }
      }

    }
    &__bottom {
      font-size: 10px;
      color: #666666;
      margin-top: 7px;
      width: 100%;
      display: flex;
      justify-content: center;
      a {
        font-size: 14px;
        text-decoration: none;
        i {
          color: #CCCCCC;
          margin-left: 5px;
          font-size: 15px;
        }
      }
    }
    .a-progress {
      font-size: 12px;
      background-color: #f2f1f1;
      border-radius: 20px;
      height: 19px;
      max-width: 1000px;
      .bg--blue {
        background-color: #66FFCC;
        height: 100%;
      }
      &__bar{

        animation-name: fullwidth;
        animation-duration: 1s;
        animation-iteration-count: 1;
      }
    }
    .icon-progress-stop {
      color: #cd3a25;
      font-size: 18px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .sale_figures_remove_sort{
    white-space: nowrap;
    align-items: flex-end;
    margin-left: 2rem;
    @include a-respond-only(md, lg) {
      margin-bottom: 21px !important;
    }
    @include a-respond-down(md) {
      margin-bottom: 22px !important;
    }
    @include a-respond-down(sm) {
      width: 100%;
      margin-left: 2px !important;
    }
  }
}
