/* ==========================================================================
   # START COMPONENTS PAGINATIOn
   ==========================================================================
*/
.a_navigation{
  justify-content: flex-end;
}
.a-pagination{
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  background-color: white;
  @include media-breakpoint-down(lg){
    align-items: flex-end;
  }
  &--select-number-item{
    margin-left: 40px;
    @include media-breakpoint-down(lg){
      margin-left: 20px;
      margin-top: 10px;
    }
  }

  &--fixed{
    position: fixed;
    bottom: 0;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.09);
    right: 24px;
    width: calc(100% - 106.5px);
    z-index: 1010;
    @include media-breakpoint-down(md) {
      width: calc(100% - 46px);
    }

    &.upload-csv{
      right: 401px;
      width:calc(100% - 400.5px - 84px);
      @include media-breakpoint-down(lg){
        width:calc(100% - 325px - 84px);
        right: 325px;
      }
    }
    &__is-sub-sidebar {
      width: calc(100% - 321px);
      right: 16px;
      @include media-breakpoint-down(xl) {
        width: calc(100% - 316px);
      }
    }
  }

    .ant-pagination-item-active {
        border-color: $color--primary;

        a {
            color: $color--primary;
        }
    }

    .ant-pagination-item:focus, .ant-pagination-item:hover {
        border-color: $color--primary;

        a {
            color: $color--primary;
        }
    }
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
        color: $color--primary;
    }
}
/* ==========================================================================
   # END COMPONENTS PAGINATION
   ==========================================================================
*/
