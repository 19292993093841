body {
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
  font-family: 'Roboto' !important;
  font-size: 14px;
  background-color: #f2f3f8;
  &.c_sidebar__collapse {
    overflow: hidden;
  }
}

button, input, select, div, a {
  &:focus {
    outline: none !important;
  }
}
a {
  color: $txt-color-primary;
  &:visited {
    color: $txt-color-primary;
  }
  &:focus {
    color: $txt-color-primary;
  }
}

.opacity_50 {
  opacity: .5;
}

.opacity_60 {
  opacity: .6;
}