@import "../settings/setting.mixin";
@import "../settings/setting.color";

/* ==========================================================================
   # START UTILITIES GIRD
   ==========================================================================
*/
.flex {
  &-start {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
  }
  &-end {
    @extend .flex-start;
    justify-content: flex-end !important;
  }

  &-end-column {
    display: flex;
    justify-content: flex-end !important;
    flex-direction: column !important;
  }
  &--column {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    &--reverse-md {
      @include a-respond-down(md) {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
  &--center {
    @extend .flex-start;
  }
  &-end-md {
    @include a-respond-down(md) {
      justify-content: flex-end !important;
    }
  }
}

.a-group {
  width: 100%;
  margin-bottom: 1rem;
  &--inline {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    &-end {
      justify-content: flex-end;
    }
    &-start-xs {
      @include a-respond-down(xs) {
        justify-content: flex-start;
      }
    }
  }
  &--column {
    &-xs {
      @include a-respond-down(xs) {
        display: flex;
        justify-content: flex-start;
        flex-direction: column !important;
      }
    }
  }
}
.a-flex{
  display: flex;
}
.ml-auto{
  margin-left: auto;
}
.border-right{
  border-right: .1rem solid rgba(0, 0, 0, .16);
}
.border-top-dashed{
  border-top: 1px dashed rgba(0, 0, 0, .25);
}
.aphz-50{
  padding-left: 50px !important;
  padding-right: 50px !important;
}
.clear-both {
  clear: both;
}
.a-border {
  &--dash-bottom {
    border-bottom: 1px dashed rgba(0,0,0,.25);
  }
  &--dash-top {
    border-top: 1px dashed rgba(0,0,0,.25);
  }
}
/* ==========================================================================
   # END UTILITIES GIRD
   ==========================================================================
*/